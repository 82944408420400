import { CpDataApi } from 'cp-xhr';
import { AxiosRequestConfig } from 'axios';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { AuthorityAddress, getLicensingAuthority } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';

const { reducer, fetchData } = getSimpleDataFetchSlice<AuthorityAddress[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'customLicensingAuthorities',
    fetchCallback(requestConfig?: AxiosRequestConfig) {
        return CpDataApi.get(getLicensingAuthority(requestConfig?.data.zipCode)).then(response => response.data);
    },
});

export default reducer;
export const fetchLicensingAuthorities = fetchData;
