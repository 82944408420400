import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerReservationAddress } from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';

export const AddressesSection: React.FC<{ addresses?: CustomerReservationAddress[] }> = ({ addresses }) => {
    const { t, ready } = useTranslation('aod-contract-parties', { useSuspense: false });

    const getItemList = ({ street = '', houseNumber = '', zipCode = '', city = '' }): DefinitionListItem[] => [
        { label: t('addresses.street'), value: street ? `${street} ${houseNumber}` : '-', testId: 'street' },
        { label: t('addresses.city'), value: zipCode && city ? `${zipCode} ${city}` : '-', testId: 'city' },
    ];

    return (
        <>
            {ready && (
                <>
                    <b className="u-text-brand">{t('addresses.header')}</b>
                    {!!addresses?.length ? (
                        addresses.map((address, index) => (
                            <DefinitionListHorizontal
                                list={getItemList(address)}
                                key={`${address.street}${address.houseNumber}${index}`}
                                className={'u-mb u-mt-small'}
                            />
                        ))
                    ) : (
                        <DefinitionListHorizontal list={getItemList({})} className={'u-mb u-mt-small'} />
                    )}
                </>
            )}
        </>
    );
};
