import { InboxDocument, getInboxDocumentsEndpoint } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<InboxDocument[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'InboxDocuments',
    fetchCallback() {
        return CpDataApi.get(getInboxDocumentsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchInbox = fetchData;
