import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { preventSubmit } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ExpertReportFormValues, ExpertReportStep2FormValues } from '../../types';
import {
    areFilesValid,
    AttachmentsUpload,
    CommonInsuranceForm,
    resetField,
    resetFileUpload,
    YesNoButtons,
    YesNoRadioGroup,
} from '../../../common';
import { showAttachmentsUpload, showRepairByPrepayment } from './helpers';
import { getStep2InitialValues } from '../initialValues';
import { getStep2ValidationSchema } from '../validationSchema';
import { FormNavigationButtons } from '../../../../form-navigation-buttons';

type ExpertReportFormViewProps = {
    handleNext: (values: ExpertReportStep2FormValues) => void;
    handlePrev: (values: ExpertReportStep2FormValues) => void;
    expertReportValues?: ExpertReportFormValues;
    isAccipiens: boolean;
};

export const Step2FormView: React.FC<ExpertReportFormViewProps> = ({
    handleNext,
    handlePrev,
    expertReportValues,
    isAccipiens,
}) => {
    const { t } = useTranslation('repair-approval');

    const handleSubmit = (values: ExpertReportStep2FormValues): void => {
        const { attachments } = values;
        if (!isAccipiens && (!attachments || !areFilesValid(attachments))) return;
        handleNext(values);
    };

    const onBackClick = (values: ExpertReportStep2FormValues): void => {
        handlePrev(values);
    };

    const onPaymentToLoanContractChange = (formik: FormikProps<ExpertReportStep2FormValues>): void => {
        resetField(formik, 'repairByPrepayment');
        resetFileUpload(formik, 'attachments');
    };

    return (
        <Formik
            initialValues={getStep2InitialValues(isAccipiens, expertReportValues)}
            validationSchema={getStep2ValidationSchema(isAccipiens, t)}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<ExpertReportStep2FormValues>) => (
                <Form onSubmit={e => preventSubmit(e)}>
                    <Layout center>
                        <Layout.Item default="2/3" m={'1/1'} className={'u-text-center'}>
                            <Heading className="u-mt-large" level={4}>
                                {t('expert-report.step2.title')}
                            </Heading>
                        </Layout.Item>
                        <Layout.Item default="2/3" m={'1/1'}>
                            <CommonInsuranceForm isAccipiens={isAccipiens} name={'insurance'} />
                        </Layout.Item>
                        {!isAccipiens && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    name={'paymentToLoanContract'}
                                    label={t('expert-report.payment-to-loan-contract.label')}
                                    tooltip={t('expert-report.payment-to-loan-contract.tooltip')}
                                    onChange={(): void => onPaymentToLoanContractChange(formik)}
                                />
                            </Layout.Item>
                        )}
                        {!isAccipiens && showRepairByPrepayment(formik.values.paymentToLoanContract || '') && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    name={'repairByPrepayment'}
                                    label={t('expert-report.repair-by-prepayment.label')}
                                    tooltip={t('expert-report.repair-by-prepayment.tooltip')}
                                />
                            </Layout.Item>
                        )}
                        {!isAccipiens && showAttachmentsUpload(formik.values.repairByPrepayment || '') && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <p>
                                    {formik.values.repairByPrepayment === YesNoButtons.yes
                                        ? t('expert-report.attachments.repair-by-prepayment-yes')
                                        : t('expert-report.attachments.repair-by-prepayment-no')}
                                </p>
                                <AttachmentsUpload name={'attachments'} />
                            </Layout.Item>
                        )}
                        <Layout.Item>
                            <FormNavigationButtons
                                onPrimaryButtonClick={formik.submitForm}
                                onSecondaryButtonClick={(): void => onBackClick(formik.values)}
                                primaryButtonType={'continue'}
                            />
                        </Layout.Item>
                    </Layout>
                </Form>
            )}
        </Formik>
    );
};
