import React from 'react';
import { useTranslation } from 'react-i18next';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RequestDocumentComponent } from './request-document-component';
import { useContractAndRequestCategories } from './useContractAndRequestCategories';
import { withContractExtensionEndForLeasingContracts } from './withContractExtensionEndForLeasingContracts';
import { useAnalyticsPageViewTracker, RequestLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import { RequestDocumentFormCategory } from './request-document-component/request-document-form/utils/RequestDocumentFormFields';
import { ReasonForShipment } from '@cp-de/common';

// ContractExtensionEnd is required from financial details if the contract type is leasing to determine if copy of contract extension category should be shown.
const RequestDocumentWithHandlers = withLoadingAndNoConnectionHandler(
    withContractExtensionEndForLeasingContracts(RequestDocumentComponent),
);

type RequestDocumentProps = {
    contractId: string;
    documentType?: RequestDocumentFormCategory;
    shippingReason?: ReasonForShipment;
};

export const RequestDocument: React.FC<RequestDocumentProps> = ({ contractId, documentType, shippingReason }) => {
    const { data, isLoading, loadingError } = useContractAndRequestCategories(contractId);
    const { t } = useTranslation('request-document');
    useAnalyticsPageViewTracker('requestCertificate', !!data);

    return (
        <RequestDocumentWithHandlers
            isLoading={isLoading}
            errorText={t('error-message')}
            requestCategories={data?.requestCategories}
            contract={data?.contract}
            documentType={documentType}
            shippingReason={shippingReason}
            hasError={!!loadingError}
            loadingPlaceholder={<RequestLoadingPlaceholder numberOfButtons={0} />}
        />
    );
};
