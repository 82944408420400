import React, { FC } from 'react';
import { CenteredSpinner } from '../../components/centered-spinner';
import { useAuthentication } from '@cp-shared-9/frontend-ui';
import { loginInProgressPagePath } from '../../components/navigation/paths';
import { useTranslation } from 'react-i18next';

export const VerimiActivationPage: FC = () => {
    const { i18n } = useTranslation();
    const { login } = useAuthentication();
    const language = i18n.languages[0];

    login({
        idpHint: 'VERIMIIdentityProvider',
        redirectUri: window.location.origin + loginInProgressPagePath(),
        locale: language,
        prompt: 'login',
    });

    return <CenteredSpinner />;
};
