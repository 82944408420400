import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSaveInboxConsentsEndpoint, PostboxConsents, PostBoxConsentType } from '@cp-de/common';
import { Layout, UnorderedList, Button, ButtonContainer, Fieldset, Heading } from '@vwfs-bronson/bronson-react';
import { getAbsoluteUrlWithParams } from '../../../utils/urlSearchParams';
import { dashboardPagePath } from '../../navigation/paths';
import { PostboxConsentsError } from './consent-error/PostboxConsentsError';
import { PostboxConsentsFormFields } from './PostboxGivenConsents';
import { Form, Formik } from 'formik';
import { InfoCheckbox, preventSubmit } from '@cp-shared-9/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import { consentUiTestIds } from './consentUiTestIds';
import { useHistory } from 'react-router-dom';
import { postboxConsentsValidationSchema } from './validations';
import { skipPostboxConsentsCheckStorageKey } from '../../../config';

export const PostboxConsentUi: React.FC<{ postboxConsents?: PostboxConsents }> = ({ postboxConsents }) => {
    type RequestProps = {
        isLoading: boolean;
        error?: boolean;
    };
    const [request, setRequest] = useState<RequestProps>({ isLoading: false, error: false });
    const history = useHistory();

    const { t } = useTranslation('postbox-consents');
    const dashboardUrl = getAbsoluteUrlWithParams(dashboardPagePath());

    const [initialValues, setInitialValues] = useState<PostboxConsentsFormFields>({});
    const [validationSchema, setValidationSchema] = useState<ReturnType<typeof postboxConsentsValidationSchema> | null>(
        null,
    );

    React.useEffect(() => {
        if (postboxConsents) {
            const initialConsentValues: PostboxConsentsFormFields = {};

            if (postboxConsents[PostBoxConsentType.InboxConsentNecessaryFinancing]) {
                initialConsentValues.inboxConsentGivenFinancing = false;
            }

            if (postboxConsents[PostBoxConsentType.InboxConsentNecessaryLeasing]) {
                initialConsentValues.inboxConsentGivenLeasing = false;
            }

            setInitialValues(initialConsentValues);
            setValidationSchema(
                postboxConsentsValidationSchema(
                    t,
                    Object.keys(initialConsentValues) as (keyof PostboxConsentsFormFields)[],
                ),
            );
        }
    }, [postboxConsents]);

    if (!postboxConsents) {
        return null;
    }
    if (
        !postboxConsents[PostBoxConsentType.InboxConsentNecessaryFinancing] &&
        !postboxConsents[PostBoxConsentType.InboxConsentNecessaryLeasing]
    ) {
        history.push(dashboardPagePath());
        return null;
    }

    return (
        <Layout>
            <PostboxConsentsError error={request.error} />
            <Layout.Item className={'u-text-center'}>
                <Heading level={1}>{t('headline')}</Heading>
            </Layout.Item>
            <Layout.Item className={'u-text-left'}>
                <div dangerouslySetInnerHTML={{ __html: t('description') }} />
            </Layout.Item>
            <Layout.Item>
                <Heading level={6}>{t('heading-2')}</Heading>
                <div className="u-ml">
                    <UnorderedList className="u-ml-large">
                        {(t('description-points', { returnObjects: true }) as string[])?.map((point: string) => (
                            <UnorderedList.Item key={point}>{point}</UnorderedList.Item>
                        ))}
                    </UnorderedList>
                </div>
            </Layout.Item>
            <Layout.Item>
                <Heading level={6}>{t('heading-3')}</Heading>
            </Layout.Item>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values: PostboxConsentsFormFields): void => {
                    setRequest({ isLoading: true, error: request.error });

                    const consentRequest = {
                        inboxConsentGivenFinancing: values.inboxConsentGivenFinancing || undefined,
                        inboxConsentGivenLeasing: values.inboxConsentGivenLeasing || undefined,
                    };

                    CpDataApi.post(getSaveInboxConsentsEndpoint(), consentRequest)
                        .then(() => {
                            setRequest({ isLoading: false, error: false });
                            window.localStorage.setItem(skipPostboxConsentsCheckStorageKey, 'true');
                            window.location.assign(dashboardUrl);
                        })
                        .catch(() => {
                            setRequest({ isLoading: false, error: true });
                        });
                }}
            >
                {formik => {
                    return (
                        <Form onSubmit={e => preventSubmit(e)}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout.Item>
                                        {formik.values.hasOwnProperty('inboxConsentGivenFinancing') && (
                                            <InfoCheckbox
                                                isLinkButton={false}
                                                preLinkLabel={t('form.fields.inboxConsentGivenFinancing.checkbox')}
                                                linkLabel={t(
                                                    'form.fields.inboxConsentGivenFinancing.checkboxLinkLabel',
                                                )}
                                                infoLinkHref={'/documents/26180_Bedingungen_Postfach_Finanzierung.pdf'}
                                                afterLinkLabel={'.'}
                                                fieldName={'inboxConsentGivenFinancing'}
                                                testId={'inboxConsentGivenFinancing'}
                                            />
                                        )}
                                        {formik.values.hasOwnProperty('inboxConsentGivenLeasing') && (
                                            <InfoCheckbox
                                                preLinkLabel={t('form.fields.inboxConsentGivenLeasing.checkbox')}
                                                infoLinkHref={'/documents/26181_Bedingungen_Postfach_Leasing.pdf'}
                                                linkLabel={t('form.fields.inboxConsentGivenLeasing.checkboxLinkLabel')}
                                                afterLinkLabel={'.'}
                                                fieldName={'inboxConsentGivenLeasing'}
                                                testId={'inboxConsentGivenLeasing'}
                                            />
                                        )}
                                    </Layout.Item>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout.Item>
                                        <h6>{t('form.info-heading')}</h6>
                                    </Layout.Item>
                                    <Layout.Item>
                                        <div dangerouslySetInnerHTML={{ __html: t('form.info') }} />
                                        <ButtonContainer center>
                                            <Button
                                                full={true}
                                                onClick={formik.submitForm}
                                                testId={consentUiTestIds.submitButton}
                                                disabled={request.isLoading || !formik.isValid}
                                            >
                                                {t('form.submit')}
                                            </Button>
                                            <Button
                                                full={true}
                                                secondary={true}
                                                disabled={Object.values(formik.values).every(value => Boolean(value))}
                                                onClick={() => {
                                                    window.localStorage.setItem(
                                                        skipPostboxConsentsCheckStorageKey,
                                                        'true',
                                                    );
                                                    window.location.assign(dashboardUrl);
                                                }}
                                                testId={consentUiTestIds.notNow}
                                            >
                                                {t('form.notNow')}
                                            </Button>
                                        </ButtonContainer>
                                    </Layout.Item>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    );
                }}
            </Formik>
        </Layout>
    );
};
