import React, { useState } from 'react';
import base64ToBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Button, ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { Spinner, useAnalyticsActionTracker, ActionType } from '@cp-shared-9/frontend-ui';
import { getClaimsTransferEndpoint, DocumentBase64 } from '@cp-de/common';
import { CpDataApi } from 'cp-xhr';
import { ConfirmationModal } from '../confirmation-modal';
import { SubmittingStatus } from '../types';

type ClaimsTransferDownloadProps = {
    contractId: string;
    formVariant: 'ExpertReport' | 'TotalLoss' | 'Theft';
};

export const ClaimsTransferDownload: React.FC<ClaimsTransferDownloadProps> = ({ contractId, formVariant }) => {
    const { t } = useTranslation('repair-approval');
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const { onAction } = useAnalyticsActionTracker(`on${formVariant}InsuranceClaimDownload` as ActionType);

    const downloadDocument = () => {
        setIsDownloading(true);
        onAction();
        CpDataApi.get(getClaimsTransferEndpoint(contractId))
            .then(response => {
                const { data } = response.data as DocumentBase64;
                if (!data) throw Error;
                const pdfBlob = base64ToBlob(data, 'application/pdf');
                const filename = 'Abtretung.pdf';
                saveAs(pdfBlob, filename);
            })
            .catch(() => {
                setDownloadError(true);
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    return (
        <ContentSection altBackground pageWrap>
            <Heading level={5}>{t(`claims-transfer-download.title`)}</Heading>
            <p>{t(`claims-transfer-download.description`)}</p>
            {isDownloading ? (
                <Spinner small center={false} />
            ) : (
                <Button onClick={downloadDocument} testId="downloadButton" type="button" icon="semantic-download">
                    {t(`claims-transfer-download.button`)}
                </Button>
            )}
            <ConfirmationModal
                status={downloadError ? SubmittingStatus.ERROR : SubmittingStatus.NOT_PERFORMED}
                onClose={() => {
                    setDownloadError(false);
                }}
            />
        </ContentSection>
    );
};
