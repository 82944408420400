import React, { useState } from 'react';
import { ExpertReportFormView } from './form-view';
import { ExpertReportConfirmationView } from './confirmation-view';
import { ExpertReportFormValues, ExpertReportStep1FormValues, ExpertReportStep2FormValues } from './types';
import { getInitialValues } from './form-view/initialValues';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import moment from 'moment-timezone';
import {
    getReportDamageEndpoint,
    ReportDamageClaimType,
    ReportDamageRequest,
    ReportDamageResponsibleInsurance,
    getValue,
    longDateFormat,
    marketApiDateFormat,
} from '@cp-de/common';
import { CpDataApi } from '../../../../cp-xhr';
import { buttonsToBoolean, ConfirmationModal, SubmittingStatus } from '../common';
import { uploadFilesToRequestedUrls } from '@cp-shared-9/frontend-integration';

type ExpertReportProps = {
    step: number;
    handleNext: () => void;
    handlePrev: () => void;
    contractId: string;
    isAccipiens: boolean;
};

export const ExpertReport: React.FC<ExpertReportProps> = ({
    step,
    handleNext,
    handlePrev,
    contractId,
    isAccipiens,
}) => {
    const [innerStep, setInnerStep] = useState(1);
    const [expertReportValues, setExpertReportValues] = useState<ExpertReportFormValues>(getInitialValues(isAccipiens));
    const [submittingStatus, setSubmittingStatus] = useState<SubmittingStatus>(SubmittingStatus.NOT_PERFORMED);

    const { onAction: onSuccess } = useAnalyticsActionTracker('onExpertReportSuccess');
    const { onAction: onFail } = useAnalyticsActionTracker('onExpertReportFailure');

    const saveFormValues = (values: ExpertReportStep1FormValues | ExpertReportStep2FormValues): void => {
        setExpertReportValues({
            ...expertReportValues,
            ...values,
        });
    };

    const handleInnerNext = (): void => {
        setInnerStep(innerStep + 1);
    };

    const handleInnerPrev = (): void => {
        setInnerStep(innerStep - 1);
    };

    const handleConfirmationPrev = (step?: 1 | 2): void => {
        handlePrev();
        setInnerStep(step || 1);
    };

    const mapExpertReportForm = (insuranceClaimFileId: string, attachmentsFilesIds: string[]): ReportDamageRequest => {
        const {
            responsibleInsurance,
            paymentToLoanContract,
            repairByPrepayment,
            insurance,
            damageDate,
        } = expertReportValues;
        const formattedDamageDate = moment(damageDate, longDateFormat).format(marketApiDateFormat);
        const modifiedInsurance = isAccipiens
            ? {
                  ...insurance,
                  claimAmount: getValue(insurance?.damageAmount),
              }
            : insurance;

        return {
            claimType: ReportDamageClaimType.APPRAISAL,
            claimDate: formattedDamageDate,
            responsibleInsurance: responsibleInsurance as ReportDamageResponsibleInsurance,
            paymentToLoanContract: buttonsToBoolean(paymentToLoanContract || ''),
            ...(repairByPrepayment ? { repairByPrepayment: buttonsToBoolean(repairByPrepayment) } : {}),
            ...(insuranceClaimFileId ? { claimsTransfer: insuranceClaimFileId } : {}),
            insurance: modifiedInsurance,
            ...(attachmentsFilesIds.length ? { attachments: attachmentsFilesIds } : {}),
        };
    };

    const onSubmit = (): void => {
        setSubmittingStatus(SubmittingStatus.IN_PROGRESS);

        const { insuranceClaim, attachments } = expertReportValues;
        const insuranceClaimFile = uploadFilesToRequestedUrls(insuranceClaim, CpDataApi);
        const attachmentsFiles = attachments ? uploadFilesToRequestedUrls(attachments, CpDataApi) : [];

        const doAfterSuccessfulUpload = (insuranceClaimFileId: string[], attachmentsFilesIds: string[]): void => {
            const body = mapExpertReportForm(insuranceClaimFileId[0], attachmentsFilesIds);
            CpDataApi.post(getReportDamageEndpoint(contractId) + `${isAccipiens ? '?isAccipiens=true' : ''}`, body)
                .then(() => {
                    setSubmittingStatus(SubmittingStatus.SUCCESS);
                    onSuccess();
                })
                .catch(error => {
                    setSubmittingStatus(SubmittingStatus.ERROR);
                    onFail(error);
                });
        };

        Promise.all([insuranceClaimFile, attachmentsFiles])
            .then(([insuranceClaimFileId, attachmentsFilesIds]) =>
                doAfterSuccessfulUpload(insuranceClaimFileId, attachmentsFilesIds),
            )
            .catch(error => {
                setSubmittingStatus(SubmittingStatus.ERROR);
                onFail(error);
            });
    };

    const onModalClose = (): void => {
        setSubmittingStatus(SubmittingStatus.NOT_PERFORMED);
    };

    return step === 2 ? (
        <ExpertReportFormView
            innerStep={innerStep}
            handleNext={handleNext}
            handlePrev={handlePrev}
            handleInnerNext={handleInnerNext}
            handleInnerPrev={handleInnerPrev}
            expertReportValues={expertReportValues}
            saveFormValues={saveFormValues}
            contractId={contractId}
            isAccipiens={isAccipiens}
        />
    ) : step === 3 ? (
        <>
            {submittingStatus === SubmittingStatus.IN_PROGRESS && <Spinner fullPage={true} />}
            <ConfirmationModal
                status={submittingStatus}
                claimType={ReportDamageClaimType.APPRAISAL}
                onClose={onModalClose}
            />
            <ExpertReportConfirmationView
                onSubmit={onSubmit}
                handlePrev={handleConfirmationPrev}
                expertReportValues={expertReportValues}
            />
        </>
    ) : null;
};
