import { CPDate, formatCpDate } from '@cp-shared-9/common-utilities';

export const getDate30DaysBefore = (date?: CPDate) =>
    formatCpDate(date)
        .subtract(30, 'days')
        .toCpDate();

export const getDate2MonthsAfter = (date?: CPDate) =>
    formatCpDate(date)
        .add(2, 'months')
        .toCpDate();
