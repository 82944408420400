import React, { useState } from 'react';
import { TheftFormView } from './form-view';
import { TheftConfirmationView } from './confirmation-view';
import { TheftFormValues, TheftStep1FormValues, TheftStep2FormValues } from './types';
import { getInitialValues } from './form-view/initialValues';
import { buttonsToBoolean, ConfirmationModal, SubmittingStatus, YesNoButtons } from '../common';
import { uploadFilesToRequestedUrls } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../../../cp-xhr';
import moment from 'moment-timezone';
import {
    getReportDamageEndpoint,
    getValue,
    longDateFormat,
    marketApiDateFormat,
    ReportDamageClaimType,
    ReportDamageRequest,
    ReportDamageResponsibleInsurance,
} from '@cp-de/common';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { requestDocumentWithDocumentTypeAndShippingReasonPagePath } from '../../../navigation/paths';

type TheftProps = {
    step: number;
    handleNext: () => void;
    handlePrev: () => void;
    contractId: string;
    isAccipiens: boolean;
};

export const Theft: React.FC<TheftProps> = ({ step, handleNext, handlePrev, contractId, isAccipiens }) => {
    const [innerStep, setInnerStep] = useState(1);
    const [theftValues, setTheftValues] = useState<TheftFormValues>(getInitialValues(isAccipiens));
    const [submittingStatus, setSubmittingStatus] = useState<SubmittingStatus>(SubmittingStatus.NOT_PERFORMED);
    const requestDocumentPath = requestDocumentWithDocumentTypeAndShippingReasonPagePath(
        contractId,
        'RequestForZBT2Finance',
        'DeregisterVehicle',
    );

    const { onAction: onSuccess } = useAnalyticsActionTracker('onTheftSuccess');
    const { onAction: onFail } = useAnalyticsActionTracker('onTheftFailure');
    const { onAction: onRequestRegistractionRedirect } = useAnalyticsActionTracker(
        'onTheftRequestRegistrationCertRedirect',
    );

    const saveFormValues = (values: TheftStep1FormValues | TheftStep2FormValues): void => {
        setTheftValues({ ...theftValues, ...values });
    };

    const handleInnerNext = (): void => {
        setInnerStep(innerStep + 1);
    };

    const handleInnerPrev = (): void => {
        setInnerStep(innerStep - 1);
    };

    const handleConfirmationPrev = (step?: 1 | 2): void => {
        handlePrev();
        setInnerStep(step || 1);
    };

    const mapTheftForm = (insuranceClaimFileId: string): ReportDamageRequest => {
        const { responsibleInsurance, insurance, replacementValue, deregistration, damageDate } = theftValues;
        const formattedClaimDate = moment(damageDate, longDateFormat).format(marketApiDateFormat);
        const modifiedInsurance = isAccipiens
            ? {
                  ...insurance,
                  claimAmount: getValue(insurance?.damageAmount),
              }
            : insurance;

        return {
            claimType: ReportDamageClaimType.THEFT,
            claimDate: formattedClaimDate,
            responsibleInsurance: responsibleInsurance as ReportDamageResponsibleInsurance,
            ...(insuranceClaimFileId ? { claimsTransfer: insuranceClaimFileId } : {}),
            ...(responsibleInsurance === ReportDamageResponsibleInsurance.OWN ? { insurance: modifiedInsurance } : {}),
            ...(replacementValue ? { replacementValue: getValue(replacementValue) } : {}),
            vehicleDeregistered: buttonsToBoolean(deregistration || ''),
        };
    };

    const onSubmit = (): void => {
        setSubmittingStatus(SubmittingStatus.IN_PROGRESS);

        const { insuranceClaim } = theftValues;
        const insuranceClaimFile = uploadFilesToRequestedUrls(insuranceClaim, CpDataApi);

        const doAfterSuccessfulUpload = (insuranceClaimFileId: string[]): void => {
            const body = mapTheftForm(insuranceClaimFileId[0]);
            CpDataApi.post(getReportDamageEndpoint(contractId) + `${isAccipiens ? '?isAccipiens=true' : ''}`, body)
                .then(() => {
                    setSubmittingStatus(SubmittingStatus.SUCCESS);
                    onSuccess();
                })
                .catch(error => {
                    setSubmittingStatus(SubmittingStatus.ERROR);
                    onFail({ errorMessage: error.message });
                });
        };

        Promise.all([insuranceClaimFile])
            .then(([insuranceClaimFileId]) => doAfterSuccessfulUpload(insuranceClaimFileId))
            .catch(error => {
                setSubmittingStatus(SubmittingStatus.ERROR);
                onFail({ errorMessage: error.message });
            });
    };

    const onModalClose = (): void => {
        setSubmittingStatus(SubmittingStatus.NOT_PERFORMED);
    };

    return step === 2 ? (
        <TheftFormView
            innerStep={innerStep}
            handleNext={handleNext}
            handlePrev={handlePrev}
            saveFormValues={saveFormValues}
            handleInnerNext={handleInnerNext}
            handleInnerPrev={handleInnerPrev}
            theftValues={theftValues}
            contractId={contractId}
            isAccipiens={isAccipiens}
        />
    ) : step === 3 ? (
        <>
            {submittingStatus === SubmittingStatus.IN_PROGRESS && <Spinner fullPage={true} />}
            <ConfirmationModal
                status={submittingStatus}
                claimType={ReportDamageClaimType.THEFT}
                onClose={onModalClose}
                onConfirm={
                    !isAccipiens && theftValues.deregistration === YesNoButtons.no
                        ? () => {
                              onRequestRegistractionRedirect();
                          }
                        : undefined
                }
                redirectPath={
                    !isAccipiens && theftValues.deregistration === YesNoButtons.no ? requestDocumentPath : undefined
                }
            />
            <TheftConfirmationView onSubmit={onSubmit} handlePrev={handleConfirmationPrev} theftValues={theftValues} />
        </>
    ) : null;
};
