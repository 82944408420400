import React, { useState } from 'react';
import { Button, ButtonContainer, Form, Heading, Layout, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-9/common-utilities';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../localization/useTranslationWithFormatting';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    ValidatedInput,
    ValidatedSelect,
    useAnalyticsActionTracker,
} from '@cp-shared-9/frontend-ui';
import { CpDataApi } from '../../../../../cp-xhr';
import { getSilentExtensionEndpoint, SilentExtensionRequest, silentExtensionValidationSchema } from '@cp-de/common';
import { Formik } from 'formik';
import { dashboardPagePath } from '../../../../navigation/paths';
import { useHistory } from 'react-router-dom';
import { getInitialValues, SilentExtensionValues } from './initialValues';
import { ValidatedSelectItem } from '@cp-shared-9/frontend-ui/build/components/validation-inputs/validated-select/ValidatedSelect';
// eslint-disable-next-line import/named
import { sortBy } from 'lodash';

type SilentExtensionViewProps = {
    contractId: string;
    backToDashboard: () => void;
    dateValues: CPDate[];
    contractEndDate?: CPDate;
    contractExtensionEnd?: CPDate;
    email?: string;
};

export const SilentExtensionView: React.FC<SilentExtensionViewProps> = ({
    contractId,
    backToDashboard,
    dateValues,
    contractEndDate,
    contractExtensionEnd,
    email,
}) => {
    const { t, f } = useTranslationWithFormatting('contract-silent-extension');
    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('silentContractExtensionSubmit');

    const [modalStatus, setModalStatus] = useState<'error' | 'success'>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (values: SilentExtensionValues) => {
        setIsSubmitting(true);
        const request: SilentExtensionRequest = values;
        CpDataApi.post(getSilentExtensionEndpoint(contractId), request)
            .then(() => {
                setModalStatus('success');
            })
            .catch(() => {
                setModalStatus('error');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const getDateValuesSelectItems = (): ValidatedSelectItem[] =>
        sortBy(
            dateValues.map(date => ({ label: f(date, TranslationFormat.DATE), value: date })),
            'value',
        );

    const contractDate = contractExtensionEnd ? contractExtensionEnd : contractEndDate;

    return (
        <>
            {isSubmitting && <Spinner center fullPage />}
            <Layout.Item>
                <p
                    style={{ textAlign: 'justify' }}
                    dangerouslySetInnerHTML={{
                        __html: t('silent-extension-view.notification-info.first', {
                            date: f(contractDate, TranslationFormat.DATE),
                        }),
                    }}
                />
            </Layout.Item>
            <Formik
                initialValues={getInitialValues(email)}
                validationSchema={silentExtensionValidationSchema({
                    emailRequired: t('silent-extension-view.validation.email'),
                    invalidEmail: t('silent-extension-view.validation.email'),
                    endDateOfExceedingRequired: t('silent-extension-view.validation.end-date-of-exceeding'),
                })}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, values }) => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <Layout.Item className={'u-mt'}>
                            <Heading level={4}>{t('silent-extension-view.heading')}</Heading>
                            <ValidatedSelect
                                name="endDateOfExceeding"
                                testId="endDateOfExceeding"
                                emptyByDefault
                                selectItems={getDateValuesSelectItems()}
                            />
                            {values.endDateOfExceeding && (
                                <Notification
                                    className={'u-mt c-notification--context'}
                                    status={NotificationStatus.info}
                                >
                                    <span
                                        style={{ display: 'block', textAlign: 'justify' }}
                                        dangerouslySetInnerHTML={{
                                            __html: t('silent-extension-view.notification-info.second', {
                                                date: f(contractDate, TranslationFormat.DATE),
                                            }),
                                        }}
                                    />
                                </Notification>
                            )}
                            <Heading className={'u-mt'} level={4}>
                                {t('silent-extension-view.email.heading')}
                            </Heading>
                            <Paragraph>{t('silent-extension-view.email.description')}</Paragraph>
                            <ValidatedInput name="email" label={''} inputMode={'email'} testId={'email'} />
                        </Layout.Item>
                        <Layout.Item className="u-mt">
                            <ButtonContainer center>
                                <Button secondary onClick={backToDashboard} testId="backButton">
                                    {t('back-button')}
                                </Button>
                                <Button
                                    testId="submitButton"
                                    type="button"
                                    onClick={() => {
                                        onAction();
                                        handleSubmit();
                                    }}
                                >
                                    {t('confirm-button')}
                                </Button>
                            </ButtonContainer>
                        </Layout.Item>
                    </Form>
                )}
            </Formik>
            <Layout.Item>
                <Modal
                    shown={!!modalStatus}
                    buttonCancelText={t('silent-extension-view.modal.ok-button')}
                    onCancel={(): void => {
                        history.push(dashboardPagePath());
                    }}
                    hideCloseButton
                    status={modalStatus}
                    testId={`modal-${modalStatus}`}
                >
                    {t(`silent-extension-view.modal.${modalStatus}`)}
                </Modal>
            </Layout.Item>
        </>
    );
};
