import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    BadRequestError,
    CustomerRegistrationError,
    ForbiddenError,
    MultipleRegistrationError,
    TooManyRequestsError,
} from '@cp-de/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { ScrollToTopOnNavigation, Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';

export const testId = 'registrationErrorNotification';

export const RegistrationError: React.FC<{
    errorCode?: WithDefaultCpIntegrationErrors<
        CustomerRegistrationError | TooManyRequestsError | MultipleRegistrationError | BadRequestError | ForbiddenError
    >;
}> = ({ errorCode }) => {
    const { t } = useTranslation('registration');

    if (!errorCode) {
        return null;
    }

    let errorText = t('error');
    let errorStatus = NotificationStatus.error;

    if (errorCode === 'NOT_A_CUSTOMER') {
        errorText = t('error-not-a-customer');
        errorStatus = NotificationStatus.warning;
    }

    if (errorCode === 'TOO_MANY_REQUESTS') {
        errorText = t('error-too-many-requests');
        errorStatus = NotificationStatus.error;
    }

    if (errorCode === 'MULTIPLE_REGISTRATION_NOT_ALLOWED') {
        errorText = t('error-already-registered');
        errorStatus = NotificationStatus.error;
    }

    if (errorCode === 'BAD_REQUEST') {
        errorText = t('error-bad-request');
        errorStatus = NotificationStatus.error;
    }

    if (errorCode === 'FORBIDDEN') {
        errorText = t('error-forbidden');
        errorStatus = NotificationStatus.error;
    }

    return (
        <ScrollToTopOnNavigation>
            <Notification className="u-mb c-notification--context" testId={testId} status={errorStatus}>
                <span dangerouslySetInnerHTML={{ __html: errorText }} />
            </Notification>
        </ScrollToTopOnNavigation>
    );
};
