import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { RegistrationHeader, RegistrationForm } from '../../components/registration';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

export const RegistrationPage: React.FC = () => {
    useAnalyticsPageViewTracker('confirmIdentity');
    return (
        <ContentSection pageWrapSize="xxsmall">
            <RegistrationHeader />
            <RegistrationForm />
        </ContentSection>
    );
};
