import React from 'react';
import {
    HeroImage,
    openInSameWindow,
    useAnalyticsErrorPageTracker,
    StaticPageLoadingPlaceholder,
} from '@cp-shared-9/frontend-ui';

import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';

import { useNotFoundErrorPage } from './useNotFoundErrorPage';

export const NotFoundErrorPage: React.FC = () => {
    const { cmsContent: notFoundErrorPage, isLoading, loadingError } = useNotFoundErrorPage();
    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('notFoundError', !!notFoundErrorPage);
    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={notFoundErrorPage?.pretitle}
            title={notFoundErrorPage?.title || ''}
            subTitle={notFoundErrorPage?.subTitle}
            buttonText={notFoundErrorPage?.buttonText}
            clickHandler={openInSameWindow(notFoundErrorPage?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
