import { Contract, ContractType, FinancialDetails, ProductType } from '@cp-de/common';
import { changeIbanPath } from '../../../navigation/paths';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { EditStatus } from '../base-section/EditStatus';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export type IbanSectionProps = {
    financialDetails: FinancialDetails;
    contract: Contract;
    isInactive?: boolean;
};

export const IbanSection: React.FC<IbanSectionProps> = ({ financialDetails, contract, isInactive }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const isChangeEnabled = financialDetails.eligibleForIbanChange && financialDetails._links?.changeIban;
    const { contractId: encryptedContractId, contractNumber } = contract;

    const forLeasingContract =
        contract.contractType === ContractType.LEASING || contract.contractType === ContractType.SERVICE_MANAGEMENT;

    const isAccipiens =
        (contract.productType === ProductType.CLASSIC_CREDIT ||
            contract.productType === ProductType.INDIVIDUAL_CREDIT) &&
        ['DirectCredit', 'DirectCreditCar'].includes(contract.productGroup || '');

    const { onAction } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onSuccess } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSuccess');

    const startEditing = (): void => {
        if (isChangeEnabled) {
            setLastEditStatus(EditStatus.NOT_PERFORMED);
            onAction();
            history.push(changeIbanPath(encryptedContractId));
        }
    };
    const finishEditing = (lastEditStatus: EditStatus): void => {
        if (lastEditStatus === EditStatus.SUCCESS) {
            onSuccess();
        }
        setLastEditStatus(lastEditStatus);
        history.push(path);
    };

    return (
        <Switch>
            {isChangeEnabled && (
                <Route exact path={changeIbanPath(encryptedContractId)}>
                    <EditView
                        financialDetails={financialDetails}
                        contractNumber={contractNumber}
                        forLeasingContract={forLeasingContract}
                        isAccipiens={isAccipiens}
                        finishEditing={finishEditing}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    financialDetails={financialDetails}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                    isInactive={isInactive}
                />
            </Route>
        </Switch>
    );
};
