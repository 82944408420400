import {
    Contract,
    ContractType,
    FinancialDetails,
    formatAsCurrency,
    formatAsDate,
    formatAsPercentage,
    ContractStatus,
} from '@cp-de/common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import React from 'react';

import { TranslationFormat, useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';

interface BaseSectionProps {
    financialDetails?: FinancialDetails;
    contract?: Contract;
    isInactive?: boolean;
}

export const BaseSection: React.FC<BaseSectionProps> = ({ financialDetails, contract, isInactive }) => {
    const { t, f } = useTranslationWithFormatting('contracts');

    if (!financialDetails || !contract) {
        return null;
    }
    const status =
        contract.contractStatus === ContractStatus.TERMINATED
            ? t('status.terminated')
            : contract.contractStatus === ContractStatus.EXPIRED
            ? t('status.expired')
            : '';

    const inactiveDetailsItems = isInactive
        ? [
              {
                  label: t('status.label'),
                  value: status,
              },
              {
                  label: t('leasing-customer-number'),
                  value: contract.customerNumber,
              },
          ]
        : [];

    if (isInactive && contract.nextDueAmount) {
        inactiveDetailsItems.push({
            label: t('leasing-rate.label'),
            value: formatAsCurrency(contract.nextDueAmount),
        });
    }

    const financialDetailsItems: DefinitionListItem[] = [
        ...inactiveDetailsItems,
        {
            label: t('financial-details.start-date.label'),
            tooltip: t('financial-details.start-date.tooltip'),
            value: formatAsDate(financialDetails.startDate),
        },
        {
            label: t('financial-details.invoice-begin.label'),
            tooltip: t('financial-details.invoice-begin.tooltip'),
            value: formatAsDate(financialDetails.invoiceBegin),
        },
        {
            testId: 'end-date',
            label: financialDetails.contractExtensionEnd
                ? t('financial-details.original-contract-end-date.label')
                : t('financial-details.end-date.label'),
            tooltip: financialDetails.contractExtensionEnd
                ? t('financial-details.original-contract-end-date.tooltip')
                : t('financial-details.end-date.tooltip'),
            value: formatAsDate(financialDetails.endDate),
        },
        {
            label: t('financial-details.contract-extension-date.label'),
            tooltip: t('financial-details.contract-extension-date.tooltip'),
            value: formatAsDate(financialDetails.contractExtensionEnd),
        },

        {
            label: t('financial-details.contract-duration.label'),
            tooltip: t('financial-details.contract-duration.tooltip'),
            value: f(financialDetails.contractDuration, TranslationFormat.MONTHS),
        },
        {
            label: t('financial-details.contract-extension-duration.label'),
            tooltip: t('financial-details.contract-extension-duration.tooltip'),
            value: f(financialDetails.contractExtensionDuration, TranslationFormat.MONTHS),
        },
        {
            testId: 'pending-capital',
            label: t('financial-details.pending-capital.label'),
            tooltip: contract.overpayment
                ? t('financial-details.pending-capital.in-case-of-overpayment-tooltip')
                : t('financial-details.pending-capital.tooltip'),
            value: formatAsCurrency(financialDetails.pendingCapital),
        },
        {
            label: t('financial-details.nominal-amount.label'),
            tooltip: t('financial-details.nominal-amount.tooltip'),
            value: formatAsCurrency(financialDetails.nominalAmount),
        },
        {
            label: t('financial-details.annual-percentage-rage.label'),
            tooltip: t('financial-details.annual-percentage-rage.tooltip'),
            value: formatAsPercentage(financialDetails.annualPercentageRate),
        },
        {
            testId: 'down-payment',
            label:
                contract.contractType === ContractType.FINANCING
                    ? t('financial-details.down-payment.financing.label')
                    : t('financial-details.down-payment.leasing-and-smv.label'),
            tooltip:
                contract.contractType === ContractType.FINANCING
                    ? t('financial-details.down-payment.financing.tooltip')
                    : t('financial-details.down-payment.leasing-and-smv.tooltip'),
            value: formatAsCurrency(financialDetails.downPayment),
        },
        {
            label: t('financial-details.balloon-installment.label'),
            tooltip: t('financial-details.balloon-installment.tooltip'),
            value: formatAsCurrency(financialDetails.balloonInstalment),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview title={isInactive ? t('financial-details.headline') : undefined} withoutCardEffect={true}>
            <DefinitionListHorizontal list={financialDetailsItems} />
        </DataOverview>
    );
};
