import React from 'react';
import { CustomerReservationContactDetails } from '@cp-de/common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type ConsultViewProps = {
    contactDetails: CustomerReservationContactDetails;
    successMessage?: string;
    startEditing: () => void;
};

// export const ConsultView: React.FC<ConsultViewProps> = ({ contactDetails, successMessage, startEditing }) => {
export const ConsultView: React.FC<ConsultViewProps> = ({ contactDetails, successMessage }) => {
    const { t } = useTranslation();

    if (!Object.keys(contactDetails).length) {
        return null;
    }

    const { email = '', mobilePhoneNumber = '', mobilePhoneCountryCode = '' } = contactDetails || {};

    const itemList: DefinitionListItem[] = [
        { label: t('auto-abo-customer-details:contact.e-mail'), value: email ? email : '-', testId: 'mail' },
        {
            label: t('auto-abo-customer-details:contact.phone-number'),
            value: mobilePhoneNumber ? `${mobilePhoneCountryCode} ${mobilePhoneNumber}` : '-',
            testId: 'phone',
        },
    ];

    return (
        <>
            <Layout>
                <Layout.Item default="1/2">
                    <Heading level="6" className="u-pt">
                        {t('auto-abo-customer-details:contact.headline')}
                    </Heading>
                </Layout.Item>
                {/* TODO return code below after BE is fixed */}
                {/* <Layout.Item default="1/2" className={'u-text-right'}>
                    <Button
                        icon={'semantic-edit'}
                        type="button"
                        link
                        simple
                        onClick={startEditing}
                        testId="editContact"
                    />
                </Layout.Item> */}
            </Layout>
            <DefinitionListHorizontal list={itemList} className={'u-mb u-mt-small'} />
            {successMessage && (
                <Notification
                    className={'u-mt'}
                    testId={'success-notification'}
                    status={NotificationStatus.success}
                    text={successMessage}
                />
            )}
        </>
    );
};
