import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-9/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

import { getInboxDocumentsEndpoint } from '@cp-de/common';

import { InboxWithAllValues } from './ExampleData';
import { ContractsMock } from '../contracts';
import { MockResponses as DownloadMockResponses } from './ui/InboxDownloadResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: InboxWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const InboxMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getInboxDocumentsEndpoint(),
};

export const InboxResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [InboxMock, ContractsMock, ...DownloadMockResponses]);

    return <div>{storyFn()}</div>;
};
