import { Contract, ReferralOption } from '@cp-de/common';
import React, { useState } from 'react';
import { Card, Button, Layout, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    DownloadLink,
    NotificationStatus,
    Notification,
    Spinner,
    useAnalyticsPageViewTracker,
} from '@cp-shared-9/frontend-ui';
import { saveAs } from 'file-saver';
import { ContractHeader } from '../../../contract-header';
import { dashboardPagePath } from '../../../navigation/paths';
import { useHistory } from 'react-router-dom';

type ReturnOfCarProps = {
    contract: Contract;
};

export const ReturnOfCar: React.FC<ReturnOfCarProps> = ({ contract }) => {
    const [downloadError, setDownloadError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { t } = useTranslation('return-of-car');
    const history = useHistory();
    useAnalyticsPageViewTracker('endOfContractPcpHandBack');

    const { vehicleReturnInfo, dealer } = contract;

    const { vehicleReturnAllowed, referralToDealerAllowed, referralOption } = vehicleReturnInfo || {};

    if (!vehicleReturnAllowed || !referralToDealerAllowed) {
        history.push(dashboardPagePath());
        return null;
    }

    const { name: dealerName, contact, address } = dealer || {};

    const getReturnOfCarContent = () => {
        if (referralOption === ReferralOption.REFERRAL_TO_DEALER_OF_CHOICE) {
            return t('referral-to-dealer-of-choice');
        } else if (referralOption === ReferralOption.REFERRAL_TO_DEALER && dealer) {
            return t('referral-to-dealer-with-dealer', {
                dealerName,
                street: `${address?.street} ${address?.houseNumber}`,
                location: address?.city,
                phoneNumber: contact?.phone,
                email: contact?.email,
            });
        } else {
            return t('referral-to-dealer-without-dealer');
        }
    };

    const fileName = 'return-of-car-checklist.pdf';
    const downloadLink = '/documents/return-of-car-checklist.pdf';

    const downloadFile = (): void => {
        setIsDownloading(true);
        setDownloadError(false);
        try {
            saveAs(downloadLink, fileName);
        } catch {
            setDownloadError(true);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <>
            <Heading level={1}>{t('heading')}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader isDeferralLinkShown={false} contract={contract} />
                    </Card>
                </Layout.Item>

                <Layout.Item>
                    <div dangerouslySetInnerHTML={{ __html: getReturnOfCarContent() }} />
                </Layout.Item>

                <Layout.Item>
                    {downloadError && (
                        <Notification
                            testId={'download-error-notification'}
                            status={NotificationStatus.error}
                            className={`u-mb`}
                        >
                            {t('download-error')}
                        </Notification>
                    )}
                    {isDownloading ? (
                        <Spinner small />
                    ) : (
                        <DownloadLink label={t('label')} onClick={downloadFile} testId={'download-link'} />
                    )}
                </Layout.Item>

                <Layout.Item className={'u-text-center'}>
                    <Button secondary onClick={() => history.push(dashboardPagePath())} testId="returnOfCarBackButton">
                        {t('back-button')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
