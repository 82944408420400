import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { ConsentFormFields } from './ConsentFormFields';
import { AnyObject } from 'yup/lib/types';

export const errorMessageIds = {
    usageAgreement: 'validation.usage-agreement',
};

export const consentValidationSchema = (t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const validations: Record<keyof ConsentFormFields, Yup.StringSchema | Yup.BooleanSchema<boolean | undefined>> = {
        confirmUsageAgreement: Yup.bool().oneOf([true], t(errorMessageIds.usageAgreement)),
        confirmMarketing: Yup.bool().notRequired(),
        confirmDataStorage: Yup.bool().notRequired(),
    };
    return Yup.object().shape(validations);
};
