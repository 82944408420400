/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Yup from 'yup';
import {
    ReportDamageRequest,
    ReportDamageClaimType,
    ReportDamageResponsibleInsurance,
    DocumentBase64,
} from '../../apis';
import { defaultInsuranceError, defaultReportDamageValidationError } from './errors';
import { InsuranceValidationError, ReportDamageValidationError } from './types';

export const insuranceValidationSchema = (
    isAccipiens: boolean,
    errors: InsuranceValidationError = defaultInsuranceError,
    // @ts-ignore
): Yup.ObjectSchema => {
    const contact = {};
    if (isAccipiens) {
        // @ts-ignore
        contact['email'] = Yup.string()
            .trim()
            .required(errors.contact.email?.required)
            .email(errors.contact.email?.email);
    } else {
        // @ts-ignore
        contact['faxNumber'] = Yup.string().trim();
    }
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .required(errors.name.required),
        address: Yup.object().shape(
            {
                streetName: Yup.string()
                    .trim()
                    .when('postOfficeBox', {
                        is: (postOfficeBox: string) => !postOfficeBox,
                        then: Yup.string()
                            .trim()
                            .required(errors.address.streetName.required),
                    }),
                houseNumber: Yup.string()
                    .trim()
                    .when('postOfficeBox', {
                        is: (postOfficeBox: string) => !postOfficeBox,
                        then: Yup.string()
                            .trim()
                            .required(errors.address.houseNumber.required),
                    }),
                postOfficeBox: Yup.string()
                    .trim()
                    .when(['streetName', 'houseNumber'], {
                        is: (streetName: string, houseNumber: string) => !streetName || !houseNumber,
                        then: Yup.string()
                            .trim()
                            .required(errors.address.postOfficeBox.required),
                    }),
                zipCode: Yup.string()
                    .trim()
                    .required(errors.address.zipCode.required),
                locality: Yup.string()
                    .trim()
                    .required(errors.address.locality.required),
            },
            [
                ['streetName', 'postOfficeBox'],
                ['houseNumber', 'postOfficeBox'],
            ],
        ),
        contact: Yup.object().shape(contact),
        claimId: Yup.string().required(errors.claimId.required),
        damageAmount: Yup.string().trim(),
    });
};

export const getDocumentBase64Validation = (): Yup.SchemaOf<DocumentBase64> =>
    Yup.object().shape({
        data: Yup.string().trim(),
        mediaType: Yup.string().trim(),
    });

export const reportDamageValidationSchema = (
    isAccipiens: boolean,
    errors: ReportDamageValidationError = defaultReportDamageValidationError,
): Yup.SchemaOf<ReportDamageRequest> => {
    return Yup.object().shape({
        claimType: Yup.mixed<ReportDamageClaimType>()
            .oneOf(Object.values(ReportDamageClaimType), errors.claimType.oneOf)
            .required(errors.claimType.required),
        claimDate: Yup.string(),
        responsibleInsurance: Yup.mixed<ReportDamageResponsibleInsurance>()
            .oneOf(Object.values(ReportDamageResponsibleInsurance), errors.responsibleInsurance.oneOf)
            .required(errors.responsibleInsurance.required),
        insurance: Yup.mixed().when(['claimType', 'responsibleInsurance'], {
            is: (claimType: ReportDamageClaimType, responsibleInsurance: ReportDamageResponsibleInsurance) =>
                (claimType === ReportDamageClaimType.THEFT ||
                    claimType === ReportDamageClaimType.TOTAL_LOSS ||
                    claimType === ReportDamageClaimType.TOTAL_LOSS_NO_REDEMPTION ||
                    claimType === ReportDamageClaimType.TOTAL_LOSS_REDEMPTION) &&
                responsibleInsurance === ReportDamageResponsibleInsurance.NONE,
            then: Yup.mixed().test('empty', errors.insurance.invalid, insurance => !insurance),
            otherwise: insuranceValidationSchema(isAccipiens, errors.insurance),
        }),
        paymentToLoanContract: Yup.boolean(),
        repairByPrepayment: Yup.boolean(),
        vehicleSold: Yup.boolean(),
        vehicleDeregistered: Yup.boolean(),
        replacementValue: Yup.number(),
        claimsTransfer: Yup.string(),
        attachments: Yup.array()
            .of(Yup.string())
            .optional(),
    });
};
