import { getChangeContactDetailsEndpoint, getProfileEndpoint, UserType } from '@cp-de/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-9/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractsMock, AutoAboContractsMock } from '../../contracts';
import { AddressMock, AutoAboAddressMock } from '../address-section/AddressResponseMocks';
import { AutoAboIdentityMock, IdentityMock } from '../identity-section/IdentityResponseMocks';
import { AutoAboMyProfileWithAllValues, MyProfileWithAllValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const autoAboMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: AutoAboMyProfileWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const changeContactDetailsMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Profile data',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getProfileEndpoint(),
};

export const AutoAboMyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'AutoAbo Profile data',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: autoAboMockOptions,
    url: getProfileEndpoint(UserType.AUTO_ABO),
};

export const ChangeContactDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'AutoAbo Profile data',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: changeContactDetailsMockOptions,
    url: getChangeContactDetailsEndpoint(),
};

export const MyProfileResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        MyProfileMock,
        AutoAboMyProfileMock,
        IdentityMock,
        AutoAboIdentityMock,
        AddressMock,
        AutoAboAddressMock,
        ContractsMock,
        AutoAboContractsMock,
        ChangeContactDetailsMock,
    ]);

    return <div>{storyFn()}</div>;
};
