import { ScrollToTopOnNavigation } from '@cp-shared-9/frontend-ui';
import {
    addContractPath,
    amortizationTablePagePath,
    arrearPaymentPagePath,
    autoLogoutPath,
    cancelContractPagePath,
    cancelReservationPagePath,
    changeAddressPath,
    changeIbanPath,
    changeAutoAboIdentityPath,
    changeAutoAboAddressPath,
    changeAutoAboContactDetailsPath,
    changeMileagePath,
    connectionProblemPagePath,
    consentPagePath,
    contractEndOptionsPagePath,
    dashboardPagePath,
    earlyTerminationPagePath,
    faqPagePath,
    featureAppLicensesPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalTermsPagePath,
    loginChoicePagePath,
    loginChoiceOfferPagePath,
    loginInProgressPagePath,
    loginInProgressDigitalOfferPagePath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    postboxPagePath,
    postboxPagePathWithContractId,
    privacyPolicyPagePath,
    registrationPagePath,
    reportDamagePagePath,
    requestDocumentPagePath,
    requestDocumentWithDocumentTypeAndShippingReasonPagePath,
    requestDocumentWithDocumentTypePagePath,
    revenuesOpenPositionsPagePath,
    thirdPartyLicensesPagePath,
    totalEarlySettlementPagePath,
    usageAgreementPagePath,
    verimiActivationPagePath,
    changePaymentDatePagePath,
    errorPagePath,
    digitalOfferPagePath,
    postboxConsentPath,
} from './components/navigation/paths';
import { ArrearPaymentPage } from './pages/arrear-payment-page';
import { MyRequestPage } from './pages/my-request-page/MyRequestPage';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    AuthenticatedRoute,
    AuthorizedRoute,
    AuthorizedithoutConsentRoute,
    LoginOfferAuthenticatedRoute,
} from './auth';
import {
    AmortizationTablePage,
    AutoLogoutSuccessPage,
    CancelContractPage,
    CancelReservationPage,
    ConnectionProblemPage,
    ConsentPage,
    ContractEndOptionsPage,
    DashboardPage,
    EarlyTerminationPage,
    FaqPage,
    FeatureAppLicensesPage,
    ForbiddenPage,
    LegalTermsPage,
    LoginChoicePage,
    LoginChoiceOfferPage,
    LoginInProgressPage,
    LoginInProgressDigitalOfferPage,
    MasterPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    PostboxConsentPage,
    PostboxPage,
    PrivacyPolicyPage,
    RegistrationPage,
    ReportDamagePage,
    RequestDocumentPage,
    RevenuesOpenPositionsPage,
    ThirdPartyLicensesPage,
    TotalEarlySettlementPage,
    UsageAgreementPage,
    VerimiActivationPage,
    ChangePaymentDatePage,
    ErrorPage,
    DigitalOfferPage,
} from './pages';
import { Providers } from './Providers';

const App: React.FC = () => {
    const isDigitalOfferEnabled = process.env.REACT_APP_SHOW_DIGITAL_OFFER === 'true';

    return (
        <div className="App">
            <Providers>
                <MasterPage>
                    <Switch>
                        <Redirect exact from="/customer-portal/p/de/public/login-choice" to={loginChoicePagePath()} />
                        <Redirect exact from="/customer-portal/de/private/my-profile" to={myProfilePagePath()} />
                        <Redirect exact from="/customer-portal/de/private/postbox" to={postboxPagePath()} />
                        <Redirect from="/customer-portal/de" to={dashboardPagePath()} />
                        <Redirect exact from={landingPagePath()} to={loginInProgressPagePath()} />
                        <Route path={faqPagePath()}>
                            <FaqPage />
                        </Route>
                        <Route path={autoLogoutPath()} component={AutoLogoutSuccessPage} />
                        <Route path={verimiActivationPagePath()} component={VerimiActivationPage} />
                        <AuthenticatedRoute path={loginInProgressPagePath()} component={LoginInProgressPage} />
                        <AuthenticatedRoute path={consentPagePath()} component={ConsentPage} />
                        <AuthenticatedRoute path={postboxConsentPath()} component={PostboxConsentPage} />
                        <AuthorizedRoute path={dashboardPagePath()}>
                            <DashboardPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={amortizationTablePagePath()}>
                            <AmortizationTablePage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={revenuesOpenPositionsPagePath()}>
                            <RevenuesOpenPositionsPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={myProfilePagePath()}>
                            <MyProfilePage />
                        </AuthorizedRoute>
                        <Route path={loginChoicePagePath()}>
                            <LoginChoicePage />
                        </Route>
                        {isDigitalOfferEnabled && (
                            <Route path={loginChoiceOfferPagePath()}>
                                <LoginChoiceOfferPage />
                            </Route>
                        )}
                        {isDigitalOfferEnabled && (
                            <LoginOfferAuthenticatedRoute
                                path={loginInProgressDigitalOfferPagePath()}
                                component={LoginInProgressDigitalOfferPage}
                            />
                        )}
                        {isDigitalOfferEnabled && (
                            <AuthorizedithoutConsentRoute path={digitalOfferPagePath()}>
                                <DigitalOfferPage />
                            </AuthorizedithoutConsentRoute>
                        )}
                        <AuthorizedRoute path={postboxPagePath()}>
                            <PostboxPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={postboxPagePathWithContractId()}>
                            <PostboxPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={arrearPaymentPagePath()}>
                            <ArrearPaymentPage />
                        </AuthorizedRoute>
                        {/* <AuthorizedRoute path={whatsNewPagePath()}>
                            <WhatsNewPage /> // uncomment this to enable redirecting to whats new page
                        </AuthorizedRoute> */}
                        <AuthorizedRoute
                            path={[
                                requestDocumentPagePath(),
                                requestDocumentWithDocumentTypeAndShippingReasonPagePath(),
                                requestDocumentWithDocumentTypePagePath(),
                            ]}
                        >
                            <RequestDocumentPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={reportDamagePagePath()}>
                            <ReportDamagePage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={changePaymentDatePagePath()}>
                            <ChangePaymentDatePage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={contractEndOptionsPagePath()}>
                            <ContractEndOptionsPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={totalEarlySettlementPagePath()}>
                            <TotalEarlySettlementPage />
                        </AuthorizedRoute>
                        <AuthenticatedRoute path={registrationPagePath()}>
                            <RegistrationPage />
                        </AuthenticatedRoute>
                        <AuthorizedRoute path={earlyTerminationPagePath()}>
                            <EarlyTerminationPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={cancelContractPagePath()}>
                            <CancelContractPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={cancelReservationPagePath()}>
                            <CancelReservationPage />
                        </AuthorizedRoute>
                        <AuthorizedRoute path={myRequestPagePath()}>
                            <MyRequestPage />
                        </AuthorizedRoute>
                        <Route path={forbiddenPagePath()}>
                            <ForbiddenPage />
                        </Route>
                        <Route path={notAuthorizedPagePath()}>
                            <NotAuthorizedPage />
                        </Route>
                        <Route path={notAuthorizedPagePath()}>
                            <NotAuthorizedPage />
                        </Route>
                        <Route path={notFoundPagePath()}>
                            <NotFoundPage />
                        </Route>
                        <Route path={connectionProblemPagePath()}>
                            <ConnectionProblemPage />
                        </Route>
                        <Route path={legalTermsPagePath()}>
                            <LegalTermsPage />
                        </Route>
                        <Route path={usageAgreementPagePath()}>
                            <UsageAgreementPage />
                        </Route>
                        <Route path={privacyPolicyPagePath()}>
                            <PrivacyPolicyPage />
                        </Route>
                        <Route path={thirdPartyLicensesPagePath()}>
                            <ThirdPartyLicensesPage />
                        </Route>
                        <Route path={featureAppLicensesPagePath()}>
                            <FeatureAppLicensesPage />
                        </Route>
                        <Route path={errorPagePath()}>
                            <ErrorPage />
                        </Route>
                        <Route path="*">
                            <Redirect to={notFoundPagePath()} />
                        </Route>
                    </Switch>
                </MasterPage>
                <ScrollToTopOnNavigation
                    exceptions={[
                        { from: /\S*/, to: new RegExp(changeIbanPath('[\\w-]+')) },
                        { from: new RegExp(changeIbanPath('[\\w-]+')), to: dashboardPagePath() },
                        { from: /\S*/, to: new RegExp(changeAutoAboIdentityPath()) },
                        { from: new RegExp(changeAutoAboIdentityPath()), to: dashboardPagePath() },
                        { from: /\S*/, to: new RegExp(changeAutoAboAddressPath()) },
                        { from: new RegExp(changeAutoAboAddressPath()), to: dashboardPagePath() },
                        { from: /\S*/, to: new RegExp(changeAutoAboContactDetailsPath()) },
                        { from: new RegExp(changeAutoAboContactDetailsPath()), to: dashboardPagePath() },
                        { from: /\S*/, to: new RegExp(addContractPath()) },
                        { from: new RegExp(addContractPath()), to: dashboardPagePath() },
                        { from: /\S*/, to: new RegExp(changeMileagePath('[\\w-]+')) },
                        { from: new RegExp(changeMileagePath('[\\w-]+')), to: dashboardPagePath() },
                        { from: new RegExp(changeAddressPath()), to: myProfilePagePath() },
                    ]}
                />
            </Providers>
        </div>
    );
};

export default App;
