import { SepaMandateDocument } from '@cp-de/common';
import { DataOverview } from '@cp-shared-9/frontend-ui';
import base64ToBlob from 'b64-to-blob';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { saveAs as downloadFileAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadLink } from './download-link/DownloadLink';

export type SepaDownloadViewProps = {
    contractNumber: string;
    sepaDocument: SepaMandateDocument;
    toNextView: () => void;
};

export const SepaDownloadView: React.FC<SepaDownloadViewProps> = ({ contractNumber, sepaDocument, toNextView }) => {
    const { t } = useTranslation('contracts');
    const [downloadError, setDownloadError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const fileContent = sepaDocument.sepaMandateDocument;
    const fileName = `SEPA-Mandat ${contractNumber}.pdf`;

    const ibanSectionTranslationKeyPrefix = 'financial-details.iban-section';
    const sepaDownloadDisplayTranslationKeyPrefix = `${ibanSectionTranslationKeyPrefix}.sepa-mandate-download-display`;

    const onSubmit = (): void => {
        toNextView();
    };

    const downloadFile = (): void => {
        setIsDownloading(true);
        setDownloadError(false);
        try {
            const pdfContentType = 'application/pdf';
            const pdfBlob = base64ToBlob(fileContent, pdfContentType);
            downloadFileAs(pdfBlob, fileName);
        } catch {
            setDownloadError(true);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <DataOverview title={t(`${ibanSectionTranslationKeyPrefix}.headline.edit-mode`)} withoutCardEffect={true}>
            <Layout>
                <Layout.Item className={'u-zeta'}>
                    {t(`${sepaDownloadDisplayTranslationKeyPrefix}.sub-headline`)}
                </Layout.Item>
                <Layout.Item>{t(`${sepaDownloadDisplayTranslationKeyPrefix}.information-text`)}</Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    <DownloadLink showError={downloadError} isDownloading={isDownloading} downloadFile={downloadFile} />
                </Layout.Item>
            </Layout>
            <ButtonContainer className={'u-mt'} center>
                <Button onClick={onSubmit} testId="closeButton">
                    {t(`${sepaDownloadDisplayTranslationKeyPrefix}.close-button`)}
                </Button>
            </ButtonContainer>
        </DataOverview>
    );
};
