import { Contract, ContractEndOptionsParameters } from '@cp-de/common';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { PurchasePriceInquiryView } from './view/PurchasePriceInquiryView';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

type PurchasePriceInquiryProps = {
    contract: Contract;
    email?: string;
};

export const PurchasePriceInquiry: React.FC<PurchasePriceInquiryProps> = ({ contract, email }) => {
    const locationState = useLocation().state as { data: ContractEndOptionsParameters[] };
    useAnalyticsPageViewTracker('requestPrice', true);
    return (
        <>
            <PurchasePriceInquiryView
                contract={contract}
                email={email}
                contractEndOptionsParameter={locationState?.data}
            ></PurchasePriceInquiryView>
        </>
    );
};
