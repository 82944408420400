import React from 'react';
import { LoginChoiceOffer, LoginOptions, DigitalOfferIntegrationErrors } from '@cp-de/common';
import { Hr, Layout, Logo, ContentSection, IconList } from '@vwfs-bronson/bronson-react';
import { Spinner, useAuthentication, Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { loginInProgressDigitalOfferPagePath } from 'components/navigation/paths';
import { ExternalLoginButton } from 'components/login-choice/ui/external-login-button';
import { getAbsoluteUrlWithParams, getUrlSearchParams } from '../../../utils/urlSearchParams';
import { LoginChoiceForm } from 'components/login-choice/ui/LoginChoiceForm';
import { idpHints } from 'components/login-choice/ui/idpHints';
import { useLocation } from 'react-router-dom';

interface LocationState {
    error?: string;
}

export const LoginChoiceOfferUi: React.FC<{ loginChoiceOffer?: LoginChoiceOffer }> = ({ loginChoiceOffer }) => {
    const { i18n } = useTranslation('digital-offer');
    const { login } = useAuthentication();
    const language = i18n.languages[0];

    const preferredIdp = getUrlSearchParams().get('idp') as LoginOptions | null;
    const email = getUrlSearchParams().get('email');

    const commonLoginOptions = {
        redirectUri: getAbsoluteUrlWithParams(loginInProgressDigitalOfferPagePath()),
        locale: language,
    };

    const redirectToIdentityKit = (email: string) => {
        setTimeout(() => {
            login({
                ...commonLoginOptions,
                loginHint: email,
                idpHint: 'identitykit',
                prompt: 'login',
            });
        }, 200);
    };

    const redirectToExternalLogin = (loginOption: LoginOptions, loginHint = '') => {
        setTimeout(() => {
            login({
                ...commonLoginOptions,
                loginHint,
                idpHint: idpHints[loginOption],
            });
        }, 200);
    };

    const location = useLocation<LocationState>();
    const errorMessage = location.state?.error;

    function mapErrorMessage(errorCode: DigitalOfferIntegrationErrors): string {
        switch (errorCode) {
            case DigitalOfferIntegrationErrors.DataStorageNotFound:
                return loginChoiceOffer?.errors?.dataStorageNotFound as string;
            case DigitalOfferIntegrationErrors.DataStorageUnknownError:
                return loginChoiceOffer?.errors?.dataStorageUnknownError as string;
            case DigitalOfferIntegrationErrors.CustomerIndexDuplicateTransactionID:
                return loginChoiceOffer?.errors?.customerIndexDuplicateTransactionID as string;
            case DigitalOfferIntegrationErrors.CustomerIndexUnknownError:
                return loginChoiceOffer?.errors?.customerIndexUnknownError as string;
            default:
                return loginChoiceOffer?.errors?.general as string;
        }
    }

    if (!loginChoiceOffer) {
        return null;
    }

    if (preferredIdp) {
        redirectToExternalLogin(preferredIdp, email || undefined);
        return <Spinner fullPage center />;
    }

    return (
        <>
            {errorMessage && (
                <Notification className="u-mb" status={NotificationStatus.error}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: mapErrorMessage(errorMessage as DigitalOfferIntegrationErrors),
                        }}
                    />
                </Notification>
            )}
            <Logo className={'u-block-center'} />
            <ContentSection>
                <Layout>
                    <Layout.Item>
                        <div className={'u-text-center'}>
                            <h2>{loginChoiceOffer.headline}</h2>
                        </div>
                        <IconList>
                            {loginChoiceOffer.descriptionList.map((item: string, i: number) => (
                                <IconList.Item icon="checkmark" key={`descriptin${i}`}>
                                    {item}
                                </IconList.Item>
                            ))}
                        </IconList>
                        <p dangerouslySetInnerHTML={{ __html: loginChoiceOffer.description }} />
                    </Layout.Item>
                    <Layout.Item>
                        <LoginChoiceForm loginChoice={loginChoiceOffer} redirectToIdentityKit={redirectToIdentityKit} />
                    </Layout.Item>
                    <Layout.Item>
                        <Hr />
                        <div className={'u-text-center'}>{loginChoiceOffer.separator}</div>
                    </Layout.Item>
                    <Layout.Item>
                        <Layout center>
                            {Object.values(LoginOptions).map((loginOption: LoginOptions) => (
                                <ExternalLoginButton
                                    imgSrc={loginChoiceOffer.loginSelectorImages[loginOption]}
                                    label={loginChoiceOffer.loginSelectorLabels[loginOption]}
                                    onClick={() => {
                                        redirectToExternalLogin(loginOption);
                                    }}
                                    loginOption={loginOption}
                                    key={loginOption}
                                />
                            ))}
                        </Layout>
                    </Layout.Item>
                </Layout>
            </ContentSection>
        </>
    );
};
