import React from 'react';

import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';

export type ConsentNotificationProps = {
    testId?: string;
    className?: string;
};

export const ConsentNotification: React.FC<ConsentNotificationProps> = ({ testId, className }) => {
    const { t } = useTranslation('consent');

    return (
        <Notification
            status={NotificationStatus.warning}
            testId={testId}
            className={`${className} c-notification--context`}
        >
            <span dangerouslySetInnerHTML={{ __html: t('notification') }} />
        </Notification>
    );
};
