import { NameAndMail } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { fetchCustomerHappinessIndex } from './CustomerHappinessIndexSlice';
import { CustomerHappinessIndexDataSelector } from './CustomerHappinessIndexDataSelector';

export function useCustomerHappinessIndex(
    isFetchingAllowed = true,
): AbstractDataState<NameAndMail, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchCustomerHappinessIndex,
        CustomerHappinessIndexDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
