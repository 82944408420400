import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyProfileLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { MyProfileUi } from './ui';
import { useMyProfileData } from '../useMyProfileData';
import { useUserContractsDataAndFlags } from '../../../Providers';

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { t } = useTranslation('my-profile');

    const { data: myProfile, isLoading, loadingError, isAod, isRac } = useMyProfileData();

    const {
        aboContracts: { data: aboContracts },
    } = useUserContractsDataAndFlags();

    useAnalyticsPageViewTracker('profile', !!myProfile);

    return (
        <MyProfileWithHandlers
            errorTitle=""
            errorText={t('error')}
            isLoading={isLoading}
            myProfile={myProfile}
            hasError={loadingError}
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
            isAod={isAod}
            isRac={isRac}
            aboContracts={aboContracts}
        />
    );
};
