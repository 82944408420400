import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { IconFooter as IconFooterUi, IconFooterLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import { useIconFooter } from './useIconFooter';
import { featureAppLicensesPagePath, loginChoiceOfferPagePath, loginChoicePagePath } from '../navigation/paths';
import { useLocation } from 'react-router-dom';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

const pagesWithoutIconFooter = [loginChoicePagePath(), loginChoiceOfferPagePath(), featureAppLicensesPagePath()];

export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();
    const { items, title } = iconFooter || {};
    const itemsText = (items || []).map(item => ({
        ...item,
        text: <span key={item.text as string} dangerouslySetInnerHTML={{ __html: (item.text as string) || '' }} />,
    }));

    const currentPathName = useLocation().pathname;

    const hidden = pagesWithoutIconFooter.includes(currentPathName);

    if (hidden) {
        return null;
    }

    return (
        <IconFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={title}
            items={itemsText}
            loadingPlaceholder={<IconFooterLoadingPlaceholder />}
        />
    );
};
