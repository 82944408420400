import React, { useState } from 'react';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { uploadFilesToRequestedUrls } from '@cp-shared-9/frontend-integration';
import moment from 'moment-timezone';
import { TotalLossConfirmationView } from './confirmation-view';
import { TotalLossFormValues, TotalLossStep1FormValues, TotalLossStep2FormValues } from './types';
import { TotalLossFormView } from './form-view/TotalLossFormView';
import { getInitialValues } from './form-view/initialValues';
import { buttonsToBoolean, ConfirmationModal, SubmittingStatus } from '../common';
import { CpDataApi } from '../../../../cp-xhr';
import {
    getReportDamageEndpoint,
    getValue,
    longDateFormat,
    marketApiDateFormat,
    ReportDamageClaimType,
    ReportDamageRequest,
    ReportDamageResponsibleInsurance,
} from '@cp-de/common';

type TotalLossProps = {
    step: number;
    handleNext: () => void;
    handlePrev: () => void;
    contractId: string;
    isAccipiens: boolean;
};

export const TotalLoss: React.FC<TotalLossProps> = ({ step, handleNext, handlePrev, contractId, isAccipiens }) => {
    const [innerStep, setInnerStep] = useState(1);
    const [totalLossValues, setTotalLossValues] = useState<TotalLossFormValues>(getInitialValues(isAccipiens));
    const [submittingStatus, setSubmittingStatus] = useState<SubmittingStatus>(SubmittingStatus.NOT_PERFORMED);

    const { onAction: onSuccess } = useAnalyticsActionTracker('onTotalLossSuccess');
    const { onAction: onFail } = useAnalyticsActionTracker('onTotalLossFailure');

    const saveFormValues = (values: TotalLossStep1FormValues | TotalLossStep2FormValues): void => {
        setTotalLossValues({
            ...totalLossValues,
            ...values,
        });
    };

    const handleInnerNext = (): void => {
        setInnerStep(innerStep + 1);
    };

    const handleInnerPrev = (): void => {
        setInnerStep(innerStep - 1);
    };

    const handleConfirmationPrev = (step?: 1 | 2): void => {
        handlePrev();
        setInnerStep(step || 1);
    };

    const mapTotalLossForm = (insuranceClaimFileId: string, attachmentsFilesIds: string[]): ReportDamageRequest => {
        const { responsibleInsurance, insurance, vehicleSold, replacementValue, damageDate } = totalLossValues;
        const modifiedInsurance = isAccipiens
            ? {
                  ...insurance,
                  claimAmount: getValue(insurance?.damageAmount),
              }
            : insurance;
        const formattedDamageDate = moment(damageDate, longDateFormat).format(marketApiDateFormat);
        const claimDate = damageDate ? { claimDate: formattedDamageDate } : {};

        // Contracts which are not Accipiens still need the "old" TOTAL_LOSS flag without any Redemption
        // Since With Redepmtion is handeled by /total-early-settlement page it is always
        // TOTAL_LOSS_NO_REDEMPTION on accipiens contracts
        const claimType = isAccipiens
            ? ReportDamageClaimType.TOTAL_LOSS_NO_REDEMPTION
            : ReportDamageClaimType.TOTAL_LOSS;

        return {
            claimType,
            ...claimDate,
            responsibleInsurance: responsibleInsurance as ReportDamageResponsibleInsurance,
            vehicleSold: vehicleSold ? buttonsToBoolean(vehicleSold) : false,
            ...(replacementValue ? { replacementValue: getValue(replacementValue) } : {}),
            ...(insuranceClaimFileId ? { claimsTransfer: insuranceClaimFileId } : {}),
            ...(responsibleInsurance === ReportDamageResponsibleInsurance.NONE ? {} : { insurance: modifiedInsurance }),
            ...(attachmentsFilesIds.length ? { attachments: attachmentsFilesIds } : {}),
        };
    };

    const onSubmit = (): void => {
        setSubmittingStatus(SubmittingStatus.IN_PROGRESS);

        const { insuranceClaim, attachments } = totalLossValues;
        const insuranceClaimFile = uploadFilesToRequestedUrls(insuranceClaim, CpDataApi);
        const attachmentsFiles = attachments ? uploadFilesToRequestedUrls(attachments, CpDataApi) : [];

        const doAfterSuccessfulUpload = (insuranceClaimFileId: string[], attachmentsFilesIds: string[]): void => {
            const body = mapTotalLossForm(insuranceClaimFileId[0], attachmentsFilesIds);
            CpDataApi.post(getReportDamageEndpoint(contractId) + `${isAccipiens ? '?isAccipiens=true' : ''}`, body)
                .then(() => {
                    setSubmittingStatus(SubmittingStatus.SUCCESS);
                    onSuccess();
                })
                .catch(error => {
                    setSubmittingStatus(SubmittingStatus.ERROR);
                    onFail({ errorMessage: error.message });
                });
        };

        Promise.all([insuranceClaimFile, attachmentsFiles])
            .then(([insuranceClaimFileId, attachmentsFilesIds]) =>
                doAfterSuccessfulUpload(insuranceClaimFileId, attachmentsFilesIds),
            )
            .catch(error => {
                setSubmittingStatus(SubmittingStatus.ERROR);
                onFail({ errorMessage: error.message });
            });
    };

    const onModalClose = (): void => {
        setSubmittingStatus(SubmittingStatus.NOT_PERFORMED);
    };

    return step === 2 ? (
        <TotalLossFormView
            innerStep={innerStep}
            handleNext={handleNext}
            handlePrev={handlePrev}
            handleInnerNext={handleInnerNext}
            handleInnerPrev={handleInnerPrev}
            totalLossValues={totalLossValues}
            saveFormValues={saveFormValues}
            contractId={contractId}
            isAccipiens={isAccipiens}
        />
    ) : step === 3 ? (
        <>
            {submittingStatus === SubmittingStatus.IN_PROGRESS && <Spinner fullPage={true} />}
            <ConfirmationModal
                status={submittingStatus}
                claimType={ReportDamageClaimType.TOTAL_LOSS}
                onClose={onModalClose}
            />
            <TotalLossConfirmationView
                onSubmit={onSubmit}
                handlePrev={handleConfirmationPrev}
                totalLossValues={totalLossValues}
            />
        </>
    ) : null;
};
