import moment, { Moment } from 'moment-timezone';
import numeral from 'numeral';

import 'moment/locale/de';
import 'numeral/locales/de';
import { CPDate, formatCpDate } from '@cp-shared-9/common-utilities';

// Check out https://momentjs.com/timezone/
export const DE_TIMEZONE = 'Europe/Berlin';

moment.tz.setDefault(DE_TIMEZONE);

// TODO Check the format definitions
const locale = 'de';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0[.]00';
export const longDateFormat = 'DD.MM.YYYY';
export const marketApiDateFormat = 'YYYY-MM-DD';
export const fastTrackDateFormat = 'DD.MM.YYYY';

export function changeLocale(locale: 'de'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number, format?: string, currency?: string): string {
    return typeof value === 'number'
        ? `${numeral(value)
              .format(format ? format : currencyFormat)
              .replace(/ /g, '.')} ${currency ? currency : '€'}`
        : '';
}

export function formatAsAmount(value?: number): string {
    return typeof value === 'number'
        ? `${numeral(value)
              .format(currencyFormat)
              .replace(/ /g, '.')}`
        : '';
}

export function formatAsDate(date?: CPDate | Moment): string {
    return date ? formatCpDate(date).format(longDateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number'
        ? `${numeral(value)
              .format(numberFormat)
              .replace(/ /g, '.')} km`
        : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentage(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormatRoundedTwo)} %` : '';
}

export function formatAsPricePerKilometer(value?: number): string {
    return typeof value === 'number' ? `${formatAsCurrency(value)}/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFuelConsumptionWLTP(value: number, highNumber = '¹'): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} l/100km (komb)${highNumber}` : '';
}

export function formatAsEmissionWLTP(value: number, highNumber = '¹'): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g CO2/km (komb)${highNumber}` : '';
}

export function formatAsFromPrice(value: number): string {
    return typeof value === 'number' ? `ab ${formatAsCurrency(value)}` : '';
}

export function formatAsDuration(value: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} Monate` : '';
}

export function formatAsDownPayment(value: number, forAutoCredit = false): string {
    const prefix = forAutoCredit ? 'Anzahlung' : 'Sonderzahlung';
    return typeof value === 'number' ? `${prefix}:  ${formatAsCurrency(value)}` : '';
}

export function formatAsBaloonPayment(value: number): string {
    return typeof value === 'number' ? `Schlussrate:  ${formatAsCurrency(value)}` : '';
}

export function getValue(formattedValue?: string): number | undefined {
    return formattedValue ? numeral(formattedValue).value() || undefined : undefined;
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}

export const isISO = (input?: string) => moment(input, moment.ISO_8601, true).isValid();
