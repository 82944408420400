import { ContactDetails, getChangeContactDetailsEndpoint, Phone } from '@cp-de/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedInput,
    DataOverview,
} from '@cp-shared-9/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../cp-xhr';
import { EditStatus } from '../EditStatus';
import { germanCountryCode } from './phone-input/country-code-selection/CountryCodeSelection';
import { PhoneInput } from './phone-input/PhoneInput';
import { validationSchema } from './validationSchema';

export type ContactDetailsFormValues = {
    email?: string;
    mobilePhoneCountryCode?: string;
    mobilePhoneNumber?: string;
};

export type ChangeContactDetailsProps = {
    contact?: ContactDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, isError?: boolean) => void;
    isContractView?: boolean;
};

export const ChangeContactDetails: React.FC<ChangeContactDetailsProps> = ({
    contact,
    cancelEditing,
    finishEditing,
    isContractView,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isErrorNotification, setIsErrorNotification] = useState(false);
    const { t } = useTranslation('my-profile');

    const emptyPhoneField: Phone = { number: '', countryCode: germanCountryCode };

    const { email, mobile } = contact || {
        email: '',
        mobile: emptyPhoneField,
    };

    const getPhoneInitialValues = (phone?: Phone): Phone => {
        const { number, countryCode } = phone || emptyPhoneField;
        return { number, countryCode };
    };

    const initialValues: ContactDetails = {
        email,
        mobile: getPhoneInitialValues(mobile),
    };

    const onSubmit = (values: ContactDetails): void => {
        setIsSubmitting(true);

        CpDataApi.put(getChangeContactDetailsEndpoint(), values)
            .then(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS);
            })
            .catch(() => {
                setIsErrorNotification(true);
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR, true);
            });
    };

    return (
        <DataOverview title={t('contact.change-contact.headline')} withoutCardEffect={isContractView}>
            {!contact ? (
                <NoConnectionNotification />
            ) : (
                <>
                    {isErrorNotification && (
                        <Notification status={NotificationStatus.error} className={`u-mb`}>
                            {t('contact.change-contact.notifications.error')}
                        </Notification>
                    )}
                    <UiBlockingSpinner isBlocking={isSubmitting}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema(t)}
                            onSubmit={onSubmit}
                        >
                            {({ submitForm }): JSX.Element => (
                                <Form onSubmit={e => preventSubmit(e)} data-testid="edit-form">
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item>
                                                    <ValidatedInput
                                                        label={t('contact.e-mail')}
                                                        name="email"
                                                        testId="email"
                                                        type="email"
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        <PhoneInput
                                            inputName={'mobile.number'}
                                            selectName={'mobile.countryCode'}
                                            testId={'mobile-phone'}
                                            label={t('contact.phone-number')}
                                        />
                                    </Fieldset>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={cancelEditing}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t('translation:editableSectionNav.cancel')}
                                                </Button>
                                                <Button onClick={submitForm} testId="submit-button" type="submit">
                                                    {t('translation:editableSectionNav.send')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                            )}
                        </Formik>
                    </UiBlockingSpinner>
                </>
            )}
        </DataOverview>
    );
};
