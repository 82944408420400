import React from 'react';
import { FourSalesCustomerDetails } from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import { useTranslationWithFormatting } from '../../../../../localization/useTranslationWithFormatting';
import { Heading, Hr } from '@vwfs-bronson/bronson-react';

export const FourSalesCustomerData: React.FC<{ customerDetails?: FourSalesCustomerDetails }> = ({
    customerDetails,
}) => {
    const { t, ready } = useTranslationWithFormatting('four-sales-customer-details', { useSuspense: false });

    if (!customerDetails) {
        return null;
    }

    const { firstName, lastName, addresses = [], contactData = {} } = customerDetails;

    const nameList: DefinitionListItem[] = [
        {
            label: t('name'),
            value: firstName && lastName ? `${firstName} ${lastName}` : '-',
        },
    ].filter(item => !!item.value);

    const addressList: DefinitionListItem[] = [
        {
            label: t('address.street'),
            value:
                addresses[0]?.street && addresses[0]?.houseNumber
                    ? `${addresses[0]?.street} ${addresses[0]?.houseNumber}`
                    : '-',
        },
        {
            label: t('address.location'),
            value: addresses[0]?.zipCode && addresses[0]?.city ? `${addresses[0]?.zipCode} ${addresses[0]?.city}` : '-',
        },
    ].filter(item => !!item.value);

    const contactList: DefinitionListItem[] = [
        {
            label: t('contact.e-mail'),
            value: contactData?.email ? contactData.email : '-',
        },
        {
            label: t('contact.phone-number'),
            value: contactData?.mobilePhoneNumber ? contactData.mobilePhoneNumber : '-',
        },
    ].filter(item => !!item.value);

    return (
        <>
            {ready && (
                <>
                    <DefinitionListHorizontal list={nameList} />
                    <Hr className={'u-mb-none'} />
                    <>
                        <Heading level="6" className="u-pt">
                            {t('address.title')}
                        </Heading>
                        <DefinitionListHorizontal list={addressList} />
                    </>
                    <Hr className={'u-mb-none'} />
                    <>
                        <Heading level="6" className="u-pt">
                            {t('contact.title')}
                        </Heading>
                        <DefinitionListHorizontal list={contactList} />
                    </>
                </>
            )}
        </>
    );
};
