import React from 'react';
import { ContentSection, Heading, Tabs } from '@vwfs-bronson/bronson-react';

import { Postbox } from '../../components/postbox';
import { Inbox } from '../../components/inbox';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUserContractsDataAndFlags } from '../../Providers';
import { CombinedContractType } from '../../utils';
import { Spinner } from '@cp-shared-9/frontend-ui';

enum Tab {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export const PostboxPage: React.FC = () => {
    const { t } = useTranslation('postbox');
    const { contractId } = useParams<{ contractId: string }>();

    const {
        contracts: {
            data: contracts,
            isLoading: isLoadingContracts,
            loadingError: contractsLoadingError,
            hasReceivedResponse: hasReceivedContracts,
        },
        aboContracts: { data: autoAboContracts },
        fourSalesContracts: {
            data: fourSalesContracts,
            isLoading: isFourSalesContractsLoading,
            loadingError: fourSalesContractsLoadingError,
            hasReceivedResponse: hasReceivedFourSalesContracts,
        },
        isFetchingStandardAllowed,
        isFetchingFourSalesAllowed,
    } = useUserContractsDataAndFlags();

    const isInsuranceOnlyContract = !!fourSalesContracts && !contracts && !autoAboContracts;

    const isContentReady =
        !isLoadingContracts && !isFourSalesContractsLoading && (hasReceivedContracts || hasReceivedFourSalesContracts);
    const allContracts = [...(contracts || []), ...(fourSalesContracts || [])] as CombinedContractType[];

    const postboxComponentProps = {
        contractId,
        allContracts,
        autoAboContracts,
        isLoadingContracts,
        contractsLoadingError,
        isFourSalesContractsLoading,
        fourSalesContractsLoadingError,
        isFetchingStandardAllowed,
        isFetchingFourSalesAllowed,
    };

    const inboxComponentProps = {
        contractId,
        allContracts,
        isLoadingContracts,
        isFourSalesContractsLoading,
        isFetchingInboxAllowed: !isInsuranceOnlyContract,
    };

    const PostboxTabHeaders = isInsuranceOnlyContract ? (
        <Tabs.HeaderItem tabId={Tab.ACTIVE}>{t('postboxTabLabel')}</Tabs.HeaderItem>
    ) : (
        <>
            <Tabs.HeaderItem tabId={Tab.ACTIVE}>{t('postboxTabLabel')}</Tabs.HeaderItem>
            <Tabs.HeaderItem tabId={Tab.INACTIVE}>{t('inboxTabLabel')}</Tabs.HeaderItem>
        </>
    );

    const PostboxTabContent = isInsuranceOnlyContract ? (
        <Tabs.ContentItem tabId={Tab.ACTIVE} testId="tab-active">
            <Postbox {...postboxComponentProps} />
        </Tabs.ContentItem>
    ) : (
        <>
            <Tabs.ContentItem tabId={Tab.ACTIVE} testId="tab-active">
                <Postbox {...postboxComponentProps} />
            </Tabs.ContentItem>
            <Tabs.ContentItem tabId={Tab.INACTIVE} testId="tab-inactive">
                <Inbox {...inboxComponentProps} />
            </Tabs.ContentItem>
        </>
    );

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <h3>{t('sub-title')}</h3>
            <p>{t('description')}</p>
            {isContentReady ? (
                <Tabs defaultSelected={Tab.ACTIVE}>
                    <Tabs.Header>{PostboxTabHeaders}</Tabs.Header>
                    <Tabs.Content>{PostboxTabContent}</Tabs.Content>
                </Tabs>
            ) : (
                <Spinner />
            )}
        </ContentSection>
    );
};
