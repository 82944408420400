import { getNameAndMailEndpoint, NameAndMail } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<NameAndMail, DefaultBusinessMarketApiErrorCode>({
    dataName: 'customerHappinessIndex',
    fetchCallback() {
        return CpDataApi.get(getNameAndMailEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchCustomerHappinessIndex = fetchData;
