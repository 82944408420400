import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form, Heading, Layout, Fieldset } from '@vwfs-bronson/bronson-react';
import { CleaveInput, preventSubmit } from '@cp-shared-9/frontend-ui';
import { TheftFormValues, TheftStep2FormValues } from '../../types';
import { getStep2InitialValues } from '../initialValues';
import { CommonInsuranceForm, YesNoRadioGroup } from '../../../common';
import { ReportDamageResponsibleInsurance } from '@cp-de/common';
import { getStep2ValidationSchema } from '../validationSchema';
import { FormNavigationButtons } from '../../../../form-navigation-buttons';

type Step2FormViewProps = {
    handleNext: (theftValues: TheftStep2FormValues) => void;
    handlePrev: (theftValues: TheftStep2FormValues) => void;
    theftValues?: TheftFormValues;
    isAccipiens: boolean;
};

export const Step2FormView: React.FC<Step2FormViewProps> = ({ handleNext, handlePrev, theftValues, isAccipiens }) => {
    const { t } = useTranslation('repair-approval');
    const translationPrefix = 'theft.step2';
    const showInsuranceForm = theftValues?.responsibleInsurance === ReportDamageResponsibleInsurance.OWN;

    const handleSubmit = (values: TheftStep2FormValues) => {
        handleNext(values);
    };

    const cleaveOptions = {
        numeral: true,
        delimiter: '.',
        numeralDecimalMark: ',',
    };

    return (
        <Formik
            initialValues={getStep2InitialValues(isAccipiens, theftValues, showInsuranceForm)}
            validationSchema={getStep2ValidationSchema(isAccipiens, t, showInsuranceForm)}
            onSubmit={handleSubmit}
        >
            {(formik: FormikProps<TheftStep2FormValues>) => (
                <Form onSubmit={e => preventSubmit(e)}>
                    <Layout center>
                        <Layout.Item default="2/3" m={'1/1'} className={'u-text-center'}>
                            <Heading className="u-mt-large" level={4}>
                                {t(`${translationPrefix}.title`)}
                            </Heading>
                        </Layout.Item>
                        {showInsuranceForm && (
                            <>
                                <Layout.Item default="2/3" m={'1/1'}>
                                    <CommonInsuranceForm isAccipiens={isAccipiens} name={'insurance'} />
                                </Layout.Item>
                                {!isAccipiens && (
                                    <Layout.Item default="2/3" m={'1/1'}>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <CleaveInput
                                                        cleaveOptions={cleaveOptions}
                                                        label={t(`${translationPrefix}.replacement-value.label`)}
                                                        name={`replacementValue`}
                                                        testId={`replacement-value`}
                                                        addonText={t('translation:currency.euro')}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    </Layout.Item>
                                )}
                            </>
                        )}
                        {(!isAccipiens || !showInsuranceForm) && (
                            <Layout.Item default="2/3" m={'1/1'}>
                                <YesNoRadioGroup
                                    name={'deregistration'}
                                    label={t(`${translationPrefix}.deregistration.label`)}
                                />
                            </Layout.Item>
                        )}
                        <Layout.Item>
                            <FormNavigationButtons
                                onSecondaryButtonClick={() => handlePrev(formik.values)}
                                onPrimaryButtonClick={formik.submitForm}
                                primaryButtonType="continue"
                            />
                        </Layout.Item>
                    </Layout>
                </Form>
            )}
        </Formik>
    );
};
