import { countryCodes } from '@cp-de/common';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { ComboboxOption } from 'types/bronson-react';

export const germanCountryCode = '+49';
export const CountryCodeSelection: React.FC<{ name: string; testId: string }> = ({ name, testId }) => {
    const [field, , helpers] = useField(name);

    const setValueChange = useCallback((selected: ComboboxOption[]) => {
        if (Array.isArray(selected) && selected.length) {
            return helpers.setValue(selected[0].value);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const options = countryCodes.map(({ dialCode, name }: { dialCode: string; name: string }, index: number) => {
        return (
            <Combobox.Item
                testId={`contract-selection-item-${dialCode}`}
                optionValue={`+${dialCode}`}
                optionKey={dialCode}
                key={`${dialCode}${index}`}
            >
                <span>
                    {name} <b>+{dialCode}</b>
                </span>
            </Combobox.Item>
        );
    });

    return (
        <FormField type="select" id={'country-code-selection'}>
            <Combobox
                testId={`${testId}country-code-selection`}
                defaultState={{ value: [{ value: field.value || `${germanCountryCode}`, key: 'defaultState' }] }}
                onChange={setValueChange}
            >
                {options}
            </Combobox>
        </FormField>
    );
};
