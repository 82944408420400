import { Contract, ContractType, marketApiDateFormat } from '@cp-de/common';
import { formatCpDate } from '@cp-shared-9/common-utilities';
// eslint-disable-next-line import/named
import { head } from 'lodash';

const sortContractsByStartDate = (contracts: Contract[]): Contract[] => {
    if (!contracts?.length) return [];
    return contracts.sort((a, b) => ((a.startDate || '') > (b.startDate || '') ? -1 : 1));
};

export const filterContracts = (contracts: Contract[], type: ContractType): Contract[] => {
    return contracts.filter(contract => contract.contractType === type);
};

export const getMostRecentFinancingContract = (contracts: Contract[]): Contract | undefined => {
    const filtered = filterContracts(contracts, ContractType.FINANCING);
    return head(sortContractsByStartDate(filtered));
};

export const getMostRecentFinancingContractWithAllDealerDetails = (contracts: Contract[]): Contract | undefined => {
    const filtered = [
        ...filterContracts(contracts, ContractType.FINANCING),
        ...filterContracts(contracts, ContractType.SERVICE_MANAGEMENT),
        ...filterContracts(contracts, ContractType.LEASING),
    ];
    let result: Contract | undefined;
    for (const contract of sortContractsByStartDate(filtered)) {
        const dealer = contract.dealer;
        if (
            dealer &&
            dealer.name &&
            dealer.address &&
            dealer.address.street &&
            dealer.address.houseNumber &&
            dealer.address.zipCode &&
            dealer.address.city &&
            dealer.contact &&
            dealer.contact.phone
        ) {
            result = contract;
            break;
        }
    }
    return result;
};

export const getMostRecentLeasingContract = (contracts: Contract[]): Contract | undefined => {
    const filtered = filterContracts(contracts, ContractType.LEASING);
    return head(sortContractsByStartDate(filtered));
};

export const isDateInPeriod = (
    value: number,
    unit: moment.unitOfTime.DurationConstructor,
    startDate?: string,
): boolean => {
    if (!startDate) return false;
    const dateObject = formatCpDate(startDate?.trim(), marketApiDateFormat).toMoment();
    const isValidDate = dateObject.isSameOrAfter(
        formatCpDate()
            .toMoment()
            .startOf(unit)
            .subtract(value, unit),
    );
    return isValidDate;
};
