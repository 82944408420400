import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './localization/i18n';

import React from 'react';
import { CpContentApi, CpDataApi } from 'cp-xhr';
import { render as ReactDOMRender } from 'react-dom';
import store from 'store';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import {
    DE_TIMEZONE,
    getChangeAddressEndpoint,
    getConsentEndpoint,
    getPostboxDocumentsEndpoint,
    getProfileEndpoint,
    getContractsEndpoint,
    getUserRegistrationStatusEndpoint,
    getPendingContractsEndpoint,
    UserType,
} from '@cp-de/common';
import { getIbanValidationEndpoint } from '@cp-shared-9/apis';
import MockAdapter from 'axios-mock-adapter';
import { IbanValidationSuccess } from './components/contracts/financial-details/iban-section/edit-view/iban-validation-view/ExampleData';
import { MyProfileWithChangePermission, MyProfileWithAllValues } from './components/my-profile/overview/ExampleData';
import { ContractsWithAllValues, pendingContracts } from './components/contracts/examples/ExampleData';
import { FinancialDetailsWithAllValues } from './components/contracts/financial-details/ExampleData';
import { ResponseWithAddressChange } from './components/my-profile/address-section/change-address';
import { PostboxWithAllValues } from './components/postbox/ExampleData';
import { AuthorizedUser } from './auth/example/AuthorizedUserExampleData';
import { ConsentWithAllValues } from './components/consent/ExampleData';

const render = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const App = require('./App').default;
    ReactDOMRender(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

if (process.env.NODE_ENV === 'development') {
    // Swap the value to enable local mocks
    const setupMockResponse = false;
    const cpDataApiMock = new MockAdapter(CpDataApi);
    const cpContentApiMock = new MockAdapter(CpContentApi);
    if (setupMockResponse) {
        const contracts = ContractsWithAllValues;
        const financialDetails = FinancialDetailsWithAllValues;

        cpDataApiMock.onGet(getUserRegistrationStatusEndpoint()).reply(200, AuthorizedUser);
        cpDataApiMock.onGet(getProfileEndpoint()).reply(200, MyProfileWithAllValues);
        cpDataApiMock.onPut(getChangeAddressEndpoint()).reply(200, ResponseWithAddressChange);
        cpDataApiMock.onGet(getProfileEndpoint()).reply(200, MyProfileWithChangePermission);
        cpDataApiMock.onPut(getChangeAddressEndpoint()).reply(200, ResponseWithAddressChange);
        cpDataApiMock.onGet(getContractsEndpoint()).reply(200, ContractsWithAllValues);
        cpDataApiMock.onGet(getPendingContractsEndpoint()).reply(200, pendingContracts);
        cpDataApiMock.onGet(getConsentEndpoint()).reply(200, ConsentWithAllValues);
        cpDataApiMock.onGet(getPostboxDocumentsEndpoint(UserType.STANDARD)).reply(200, PostboxWithAllValues);
        cpDataApiMock.onPost(getIbanValidationEndpoint()).reply(200, IbanValidationSuccess);

        if (financialDetails._links) {
            cpDataApiMock.onPut(FinancialDetailsWithAllValues._links?.changeIban).reply(200, 'document');
        }

        contracts.forEach(contract => {
            if (contract._links) cpDataApiMock.onGet(contract._links.financialDetails).reply(200, financialDetails);
        });
    }
    cpContentApiMock.onAny().passThrough();
    cpDataApiMock.onAny().passThrough();
}

moment.tz.setDefault(DE_TIMEZONE);
render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
