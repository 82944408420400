import { useAuthentication } from '@cp-shared-9/frontend-ui';
import { useMyProfile } from '../my-profile/overview';
import { KeycloakTokenParsed } from 'keycloak-js';
import React, { useCallback, useEffect } from 'react';
import { getActiveContractTypes, getChatbotContractType, getLeasingCustomerNumber } from './chatbotContractType';
import { useUserContractsDataAndFlags } from '../../Providers';
import { ContractType } from '@cp-de/common';
import { useLocation } from 'react-router-dom';
import { registrationPagePath } from '../navigation/paths';

export const Chatbot: React.FC = () => {
    const location = useLocation();
    const auth = useAuthentication() as {
        isAuthenticated: true;
        token: string;
        tokenParsed: KeycloakTokenParsed;
    };

    const isRegistrationPage = location.pathname === registrationPagePath();

    const {
        contracts: { data: contracts, hasReceivedResponse: responseContracts },
        aodContracts: { data: aodContracts, hasReceivedResponse: responseAoDContracts },
        racContracts: { data: racContracts, hasReceivedResponse: responseRacContracts },
        aboContracts: { data: aboContracts, hasReceivedResponse: responseAboContracts },
        fourSalesContracts: { data: fourSalesContracts, hasReceivedResponse: responseFourSalesContracts },
        isFetchingRacAllowed,
        isFetchingAboAllowed,
        isFetchingFourSalesAllowed,
        isFetchingAodAllowed,
        isFetchingStandardAllowed,
    } = useUserContractsDataAndFlags();

    const scriptAlreadyExists = useCallback(() => document.querySelector('script#mercury-widget-snippet') !== null, []);

    const { data: myProfile, hasReceivedResponse: responseProfile } = useMyProfile(isFetchingStandardAllowed);

    const allContractsReceived = (): boolean => {
        const allowedTypes = [
            { response: responseProfile && responseContracts, isAllowed: isFetchingStandardAllowed },
            { response: responseAboContracts, isAllowed: isFetchingAboAllowed },
            { response: responseFourSalesContracts, isAllowed: isFetchingFourSalesAllowed },
            { response: responseAoDContracts, isAllowed: isFetchingAodAllowed },
            { response: responseRacContracts, isAllowed: isFetchingRacAllowed },
        ].filter(({ isAllowed }) => isAllowed);

        return !!allowedTypes.length && allowedTypes.every(({ response }) => response);
    };
    const hasReceivedContracts = allContractsReceived();

    const contractType: string | undefined = hasReceivedContracts
        ? getChatbotContractType(
              getActiveContractTypes([
                  ...(contracts || []),
                  ...(aboContracts || []),
                  ...(aodContracts || []),
                  ...(racContracts || []),
                  ...(fourSalesContracts || []),
              ]),
          )
        : 'None';

    const leasingCustomerNumber = contracts ? getLeasingCustomerNumber(contracts) : undefined;
    const { idpSub: customerID } = auth.tokenParsed as { idpSub: string };

    const freezeProp = useCallback((target: object, propertyName: string) => {
        if (!process.env.JEST_WORKER_ID) {
            const descriptor = Object.getOwnPropertyDescriptor(target, propertyName);
            if (descriptor !== undefined) {
                const set = (): void => void 0;
                const get = (): ReturnType<typeof descriptor['value']> => descriptor.value;
                Object.defineProperty(target, propertyName, { enumerable: true, set, get, configurable: false });
            }
        }
    }, []);

    const productFlag: Record<string, boolean> = {};

    const setProductFlag = (): void => {
        const standardContracts: (ContractType | undefined)[] | undefined = contracts?.map(
            contract => contract.contractType,
        );

        const uniqueStandardContracts: (ContractType | undefined)[] | undefined = standardContracts?.reduce(
            (accumulator: (ContractType | undefined)[], value: ContractType | undefined) => {
                if (!accumulator.includes(value)) {
                    accumulator.push(value);
                }
                return accumulator;
            },
            [],
        );

        if (uniqueStandardContracts?.some(contractType => contractType === ContractType.FINANCING)) {
            productFlag['global.financing_flag'] = true;
        }

        if (uniqueStandardContracts?.some(contractType => contractType === ContractType.LEASING)) {
            productFlag['global.leasing_flag'] = true;
        }

        if (uniqueStandardContracts?.some(contractType => contractType === ContractType.SERVICE_MANAGEMENT)) {
            productFlag['global.smv_flag'] = true;
        }

        if (aodContracts || racContracts) {
            productFlag['global.Rent_flag'] = true;
        }

        if (aboContracts) {
            productFlag['global.Autoabo_flag'] = true;
        }

        if (fourSalesContracts) {
            productFlag['global.Motorinsurance_flag'] = true;
        }
    };

    setProductFlag();

    const appendScript = useCallback(() => {
        const UserID =
            Math.random()
                .toString(36)
                .substring(2, 15) +
            Math.random()
                .toString(36)
                .substring(2, 15);
        const userFirstName = (): string | undefined => {
            if (aodContracts?.length) {
                return aodContracts[0]?.details?.customerDetails?.identity?.firstName;
            }
            if (racContracts?.length) {
                return racContracts[0]?.details?.customerDetails?.identity?.firstName;
            }
            if (aboContracts?.length) {
                return aboContracts[0]?.details?.customerDetails?.identity?.firstName;
            }
            if (fourSalesContracts?.length) {
                return fourSalesContracts[0]?.details?.customerDetails?.firstName;
            }
        };
        const userLastName = (): string | undefined => {
            if (aodContracts?.length) {
                return aodContracts[0]?.details?.customerDetails?.identity?.lastName;
            }
            if (racContracts?.length) {
                return racContracts[0]?.details?.customerDetails?.identity?.lastName;
            }
            if (aboContracts?.length) {
                return aboContracts[0]?.details?.customerDetails?.identity?.lastName;
            }
            if (fourSalesContracts?.length) {
                return fourSalesContracts[0]?.details?.customerDetails?.lastName;
            }
        };
        const config = {
            userId: UserID,
            context: {
                'global.brand': 'Volkswagen FS',
                'global.team': '',
                'global.customer_type': isRegistrationPage ? false : contractType,
                'user.firstName': isFetchingStandardAllowed ? myProfile?.identity?.firstName : userFirstName(),
                'user.lastName': isFetchingStandardAllowed ? myProfile?.identity?.lastName : userLastName(),
                'global.leasing_customer_ID': leasingCustomerNumber,
                'global.customer_id': customerID,
                ...productFlag,
            },
        };

        const scriptWithGlobalData = document.createElement('script');
        scriptWithGlobalData.type = 'text/javascript';
        scriptWithGlobalData.text = `window.mercuryUser = ${JSON.stringify(config)};`;

        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = 'https://cdn.mercury.ai/widget/v4/widget.js';
        script.async = true;
        script.id = 'mercury-widget-snippet';
        script.setAttribute(
            'data-key',
            process.env.REACT_APP_CHATBOT_APY_KEY ? process.env.REACT_APP_CHATBOT_APY_KEY : '',
        );
        document.body.appendChild(script);
        document.body.appendChild(scriptWithGlobalData);
    }, [
        customerID,
        leasingCustomerNumber,
        myProfile,
        contractType,
        productFlag,
        isRegistrationPage,
        isFetchingStandardAllowed,
        aodContracts,
        racContracts,
        aboContracts,
        fourSalesContracts,
    ]);

    useEffect(() => {
        if ((hasReceivedContracts && !scriptAlreadyExists()) || (isRegistrationPage && !scriptAlreadyExists())) {
            appendScript();
            freezeProp(window, 'mercuryUser');
        }
    }, [hasReceivedContracts, contractType, scriptAlreadyExists, appendScript, freezeProp, isRegistrationPage]);

    return (
        <div data-testid="mercury-widget-snippet">
            <link rel="stylesheet" type="text/css" href={process.env.REACT_APP_CHATBOT_CSS_URL} />
        </div>
    );
};
