import React from 'react';
import { useContract } from '../useContract';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { ContractEndOptionsUi } from './ui';
import { useMyProfile } from '../../my-profile';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

const ContractEndOptionsWithHandlers = withLoadingAndNoConnectionHandler(ContractEndOptionsUi);

export const ContractEndOptions: React.FC<{
    contractId: string;
}> = ({ contractId }) => {
    const { t } = useTranslation('contract-end-options');
    const { data: contract, isLoading: isContractLoading, loadingError: contractLoadingError } = useContract(
        contractId,
    );
    useAnalyticsPageViewTracker('endOfContractPcpStart');

    const { data: myProfileData, isLoading: isMyProfileLoading } = useMyProfile();

    return (
        <ContractEndOptionsWithHandlers
            isLoading={isContractLoading || isMyProfileLoading}
            contract={contract}
            email={myProfileData?.contactDetails?.email}
            hasError={!!contractLoadingError}
            errorText={t('no-connection-error')}
        />
    );
};
