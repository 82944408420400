import React from 'react';
import {
    AdditionalProduct,
    AoDContract,
    DealerOpeningHours,
    RaCContract,
    formatAsCurrency,
    formatAsDistance,
    formatAsPricePerKilometer,
} from '@cp-de/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../localization/useTranslationWithFormatting';
import { Heading } from '@vwfs-bronson/bronson-react';
import { getVehicleClassByAcrissCode } from '../vehicle-details/helpers';

export const ReservationContractDetails: React.FC<{ contract?: AoDContract | RaCContract; isInactive?: boolean }> = ({
    contract,
    isInactive,
}) => {
    const { t, f, ready } = useTranslationWithFormatting('reservation-contract', { useSuspense: false });

    if (!contract) {
        return null;
    }

    const allowedProductCodes = [
        'PAI',
        'CDW_LOW',
        'CDW_NORMAL',
        'CDW_1',
        'CDW_2',
        'CDW_3',
        'CDW_4',
        '6',
        '7',
        '9',
        '10',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '22',
        '26',
        '27',
        '29',
        '45',
    ];

    const getProductNameTranslation = (product: AdditionalProduct): { label: string; value: string } => {
        if (!product.code) {
            return { label: '', value: '' };
        }
        switch (product.code) {
            case 'PAI':
                return {
                    label: t('product-codes.pai.name'),
                    value: t('product-codes.pai.value'),
                };
            case 'CDW_LOW':
            case 'CDW_NORMAL':
            case 'CDW_1':
            case 'CDW_2':
            case 'CDW_3':
            case 'CDW_4':
                const cdwValue = product.qualifiers?.find(el => el.id === 'insurance_text');
                return {
                    label: t('product-codes.cdw.name'),
                    value: t('product-codes.cdw.value', cdwValue && { amount: cdwValue.stringValue }),
                };
            case '19':
            case '26':
                return {
                    label: t('product-codes.19-26'),
                    value: '',
                };
            case '22':
            case '27':
                return {
                    label: t('product-codes.22-27'),
                    value: '',
                };
            default:
                return {
                    label: t(`product-codes.${product.code.toLowerCase()}`),
                    value: '',
                };
        }
    };
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        additionalProducts,
        details,
        excessMileagePriceAmount,
        contractMileage,
        price,
    } = contract;
    const { contactData, companyName, address, openingHours } = details?.dealerDetails || {};
    const { street = '', zipCode = '', houseNumber = '', city = '' } = address || {};
    const { telephoneCountryCode = '', telephoneNumber = '', email = '', website = '' } = contactData || {};

    const additionalProductsNames: DefinitionListItem[] =
        additionalProducts
            ?.filter((product: AdditionalProduct) => {
                return product?.code && allowedProductCodes.includes(product.code);
            })
            .map((product: AdditionalProduct) => getProductNameTranslation(product)) || [];

    let itemList: DefinitionListItem[] = [
        {
            label: t('start-date'),
            value: startDate
                ? f(startDate, TranslationFormat.DATE) + `${startTime ? ', ' + startTime?.slice(0, -3) : ''}`
                : '',
        },
        {
            label: t('end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) + `${endTime ? ', ' + endTime?.slice(0, -3) : ''}` : '',
        },
        {
            label: t('vehicle-class'),
            value: getVehicleClassByAcrissCode(t, contract.details.vehicleDetails?.acrissCode),
        },
        {
            label: t('excess-mileage-price-ammount'),
            value: formatAsPricePerKilometer(excessMileagePriceAmount),
        },
    ];

    if (isInactive) {
        if (contractMileage) {
            itemList.push({
                label: t('contract-mileage'),
                value: formatAsDistance(contractMileage),
            });
        }
        if (price) {
            itemList.push({
                label: t('price'),
                value: formatAsCurrency(price),
            });
        }
    }

    itemList = itemList.filter(item => !!item.value);

    const rentalLocationOpenHours: DefinitionListItem[] =
        openingHours
            ?.filter(({ dayOfWeek }) => dayOfWeek)
            .map(({ opens, closes, dayOfWeek }: DealerOpeningHours) => {
                const hours = opens && closes ? ` ${opens} - ${closes}` : '';
                return {
                    label: t(`days.${dayOfWeek?.toLowerCase()}`),
                    value: <span className="u-m-none">{hours}</span>,
                };
            }) || [];

    const showRentalLocation = companyName || street || city || zipCode || telephoneNumber || email;

    const rentalLocationCompanyData = (
        <div className={'u-mb'}>
            {companyName && <p>{companyName}</p>}
            {street && <p>{`${street} ${houseNumber}`}</p>}
            {(city || zipCode) && <p>{`${zipCode} ${city}`}</p>}
            {telephoneNumber && (
                <p>
                    <b>{t('rental-location.telephone')}</b>
                    {`${telephoneCountryCode} ${telephoneNumber}`}
                </p>
            )}
            {email && (
                <p>
                    <b>{t('rental-location.email')}</b>
                    {email}
                </p>
            )}
            {website && (
                <p>
                    <b>{t('rental-location.website')}</b>
                    <a href={website} rel="noreferrer noopener" target="_blank">
                        {website}
                    </a>
                </p>
            )}
        </div>
    );

    return (
        <>
            {ready && (
                <>
                    <DefinitionListHorizontal list={itemList} />
                    {!!additionalProductsNames.length && (
                        <>
                            <Heading level="6" className="u-pt">
                                {t('booked-extras')}
                            </Heading>
                            <DefinitionListHorizontal list={additionalProductsNames} />
                        </>
                    )}
                    {showRentalLocation && (
                        <>
                            <Heading level="6" className="u-pt">
                                {t('rental-location.title')}
                            </Heading>
                            {rentalLocationCompanyData}
                        </>
                    )}
                    {!!rentalLocationOpenHours.length && (
                        <>
                            <b>{t('rental-location.openingHours')}</b>
                            <DefinitionListHorizontal className="u-mt-small" list={rentalLocationOpenHours} />
                        </>
                    )}
                </>
            )}
        </>
    );
};
