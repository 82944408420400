import { ConsentStatus, getConsentEndpoint } from '@cp-de/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<ConsentStatus, DefaultBusinessMarketApiErrorCode>({
    dataName: 'consent',
    fetchCallback() {
        return CpDataApi.get(getConsentEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchConsent = fetchData;
