import React, { Fragment } from 'react';
import { Layout, DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { RepairApprovalRequest } from '@cp-de/common';
import { DefinitionListItem, DefinitionListHorizontal } from '@cp-shared-9/frontend-ui';
import { FormNavigationButtons } from '../../form-navigation-buttons';

type ConfirmationViewProps = {
    repairApproval: RepairApprovalRequest;
    handleNext: () => void;
    handlePrev: () => void;
};

export const testIds = {
    editDataButton: 'editData',
};

export const ConfirmationView: React.FC<ConfirmationViewProps> = ({ repairApproval, handleNext, handlePrev }) => {
    const { t } = useTranslation('repair-approval');

    const identityItems: DefinitionListItem[] = [
        {
            label: t('form.name'),
            value: repairApproval.recipient?.name,
        },
        {
            label: t('form.post-office-box.label'),
            value: repairApproval.recipient?.postOfficeBox,
        },
        {
            label: t('form.street'),
            value: repairApproval.recipient?.streetName,
        },
        {
            label: t('form.house-number'),
            value: repairApproval.recipient?.houseNumber,
        },
        {
            label: t('form.ZIP'),
            value: repairApproval.recipient?.zipCode,
        },
        {
            label: t('form.city'),
            value: repairApproval.recipient?.locality,
        },
        {
            label: t('form.damage-number'),
            value: repairApproval.claimId,
        },
        {
            label: t('form.license-plate'),
            value: repairApproval.claimType === 'LiabilityClaim' ? repairApproval.licensePlate : null,
        },
    ].filter(({ value }) => !!value);

    return (
        <Fragment>
            <Layout center>
                <Layout.Item default="1/1" m={'1/1'}>
                    <p className="u-mv">{t('step3.hint.content')}</p>
                    <DataOverview
                        title={`${t('step3.headline.content')} ${
                            repairApproval.claimType === 'LiabilityClaim'
                                ? t('form.insurance-radiobutton.liability-full-name')
                                : t('form.insurance-radiobutton.hull-full-name')
                        }`}
                        buttonLabel={t('step3.button.edit')}
                        buttonProps={{ onClick: handlePrev, testId: testIds.editDataButton }}
                    >
                        <DefinitionListHorizontal list={identityItems} />
                    </DataOverview>
                </Layout.Item>
                <Layout.Item>
                    <FormNavigationButtons
                        onPrimaryButtonClick={handleNext}
                        onSecondaryButtonClick={handlePrev}
                        primaryButtonType="confirm"
                    />
                </Layout.Item>
            </Layout>
        </Fragment>
    );
};
