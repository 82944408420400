import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { AmortizationTableUi } from './ui';
import { useContract } from '../contracts/useContract';
import { missingFields } from '../contracts/missingFields';
import { useFinancialDetails } from '../contracts/financial-details';
import { useTranslation } from 'react-i18next';
import { AmortizationTableLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

const AmortizationTableWithHandlers = withLoadingAndNoConnectionHandler(AmortizationTableUi);

type AmortizationTableProps = {
    contractId: string;
};

export const AmortizationTable: React.FC<AmortizationTableProps> = ({ contractId }) => {
    const { t } = useTranslation('amortization-table');
    const { data: contractState, isLoading: isContractLoading, loadingError: contractLoadingError } = useContract(
        contractId,
    );

    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contractState?.contractId || '');

    const missingFieldsError = missingFields(contractState);
    const loadingError = contractLoadingError || financialDetailsLoadingError || missingFieldsError;
    const isLoading = isContractLoading || isFinancialDetailsLoading;

    useAnalyticsPageViewTracker('amortizationTable', !!contractState && !!financialDetails);

    return (
        <div>
            <AmortizationTableWithHandlers
                isLoading={isLoading}
                contract={contractState}
                financialDetails={financialDetails}
                hasError={!!loadingError}
                errorText={t('error.no-connection')}
                loadingPlaceholder={<AmortizationTableLoadingPlaceholder tileElements={3} />}
            />
        </div>
    );
};
