import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getRepairApprovalEndpoint, RepairApprovalRequest } from '@cp-de/common';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from '../../navigation/paths';
import { FormView } from './form-view';
import { ConfirmationView } from './confirmation-view';
import { ClaimType } from './form-view/enum';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { RepairApprovalModal } from './modal';
import { saveAs as downloadFileAs } from 'file-saver';
import base64ToBlob from 'b64-to-blob';

export type RepairApprovalProps = {
    contractId: string;
    step: number;
    handleNext: () => void;
    handlePrev: () => void;
};

const emptyRepairApproval: RepairApprovalRequest = {
    claimType: undefined,
    recipient: {
        streetName: '',
        houseNumber: '',
        zipCode: '',
        locality: '',
        district: '',
        country: '',
        name: '',
        postOfficeBox: '',
    },
    claimId: '',
    licensePlate: '',
};

const emptyInvalidFields: { [k: string]: boolean } = {
    name: false,
    postOfficeBox: false,
    streetName: false,
    houseNumber: false,
    zipCode: false,
    locality: false,
    claimType: false,
    claimId: false,
    licensePlate: false,
};

const mandatoryContactfields = {
    withPostBox: ['name', 'postOfficeBox', 'zipCode', 'locality'],
    withAddress: ['name', 'streetName', 'houseNumber', 'zipCode', 'locality'],
};

const mandatoryInsuranceFields = {
    liability: ['claimType', 'claimId', 'licensePlate'],
    nonLiability: ['claimType', 'claimId'],
};

export const RepairApproval: React.FC<RepairApprovalProps> = ({ contractId, step, handlePrev, handleNext }) => {
    const { t } = useTranslation('repair-approval');
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [repairApproval, setRepairApproval] = useState<RepairApprovalRequest>(emptyRepairApproval);
    const [invalidFields, setInvalidFields] = useState<{ [k: string]: boolean }>(emptyInvalidFields);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [fileB64, setFileB64] = useState<string>('');

    const { onAction: onSuccess } = useAnalyticsActionTracker('onRepairApprovalSuccess');
    const { onAction: onFail } = useAnalyticsActionTracker('onRepairApprovalFailure');

    const goBackToDashboard = (): void => history.push(dashboardPagePath());

    const addInvalidRecepientField = (field: string) => {
        setInvalidFields(prevFields => {
            return { ...prevFields, [field]: true };
        });
    };

    const addInvalidInsuranceField = (field: string) => {
        setInvalidFields(prevFields => {
            return { ...prevFields, [field]: true };
        });
    };

    const checkStep2Validity = (): boolean => {
        setInvalidFields(() => emptyInvalidFields);
        const recepientFieldsToCheck =
            repairApproval.recipient?.postOfficeBox === ''
                ? mandatoryContactfields.withAddress
                : mandatoryContactfields.withPostBox;
        let formValid = true;
        recepientFieldsToCheck.forEach((field: string) => {
            if (repairApproval.recipient) {
                if (repairApproval.recipient[field] === '') {
                    formValid = formValid && false;
                    addInvalidRecepientField(field);
                }
                if (field === 'zipCode' && typeof repairApproval.recipient[field]?.length === 'number') {
                    if (parseInt(repairApproval.recipient[field]?.length + '') < 5) {
                        formValid = formValid && false;
                        addInvalidRecepientField(field);
                    }
                }
            }
        });
        const insuranceFieldsToCheck =
            repairApproval.claimType !== 'OwnDamageClaim'
                ? mandatoryInsuranceFields.liability
                : mandatoryInsuranceFields.nonLiability;
        insuranceFieldsToCheck.forEach((field: string) => {
            if (repairApproval[field] === '' || repairApproval[field] === undefined) {
                formValid = formValid && false;
                addInvalidInsuranceField(field);
            }
        });
        return formValid;
    };

    const handleSubmit = async (): Promise<void> => {
        setLoading(() => true);
        setIsError(() => false);

        CpDataApi.post(getRepairApprovalEndpoint(contractId), repairApproval)
            .catch(error => {
                setIsError(() => !!error);
                setLoading(() => false);
                setShowModal(() => true);
                onFail({ errorMessage: error.message });
            })
            .then(res => {
                if (res) {
                    setFileB64(() => res.data.content);
                    setLoading(() => false);
                    setShowModal(() => true);
                    onSuccess();
                }
            });
    };

    const updateRecipient = (value: string, fieldName: string) => {
        const updatedData = { ...repairApproval, recipient: { ...repairApproval.recipient, [fieldName]: value } };
        if (fieldName === 'postOfficeBox') {
            setInvalidFields(prevFields => {
                return { ...prevFields, postOfficeBox: false, houseNumber: false, streetName: false };
            });
        } else {
            setInvalidFields(prevFields => {
                return { ...prevFields, [fieldName]: false };
            });
        }
        setRepairApproval(() => updatedData);
    };

    const updateClaimType = (value: string) => {
        const updatedData = { ...repairApproval, claimType: value as ClaimType };
        setInvalidFields(prevFields => {
            return { ...prevFields, claimType: false };
        });
        setRepairApproval(() => updatedData);
    };

    const updateClaimId = (value: string) => {
        const updatedData = { ...repairApproval, claimId: value };
        setInvalidFields(prevFields => {
            return { ...prevFields, claimId: false };
        });
        setRepairApproval(() => updatedData);
    };

    const updateLicensePlate = (value: string) => {
        const updatedData = { ...repairApproval, licensePlate: value };
        setInvalidFields(prevFields => {
            return { ...prevFields, licensePlate: false };
        });
        setRepairApproval(() => updatedData);
    };

    const updateZIPCode = (value: string) => {
        let newZipCode: string = value.slice(0, 5);
        newZipCode = newZipCode.replace(/\D/g, '');
        const updatedData = {
            ...repairApproval,
            recipient: { ...repairApproval.recipient, zipCode: newZipCode },
        };
        setInvalidFields(prevFields => {
            return { ...prevFields, zipCode: false };
        });
        setRepairApproval(() => updatedData);
    };

    const downloadRepairApprovalPDF = () => {
        const fileContent = fileB64;
        const pdfContentType = 'application/pdf';
        const pdfBlob = base64ToBlob(fileContent, pdfContentType);
        const filename = t('file-name');
        downloadFileAs(pdfBlob, filename);
    };

    const handleStep2Next = () => {
        if (checkStep2Validity()) handleNext();
    };

    return (
        <>
            {loading && <Spinner fullPage={true} />}
            <>
                {step === 2 ? (
                    <FormView
                        updateRecipient={updateRecipient}
                        updateClaimType={updateClaimType}
                        updateZIPCode={updateZIPCode}
                        updateClaimId={updateClaimId}
                        updateLicensePlate={updateLicensePlate}
                        invalidFields={invalidFields}
                        repairApproval={repairApproval}
                        handlePrev={handlePrev}
                        handleNext={handleStep2Next}
                    ></FormView>
                ) : step === 3 ? (
                    <ConfirmationView
                        handlePrev={handlePrev}
                        handleNext={handleSubmit}
                        repairApproval={repairApproval}
                    ></ConfirmationView>
                ) : null}
                <RepairApprovalModal
                    shown={showModal}
                    error={isError}
                    onDownload={downloadRepairApprovalPDF}
                    onClose={(): void => setShowModal(false)}
                    onConfirm={!isError ? goBackToDashboard : (): void => setShowModal(false)}
                />
            </>
        </>
    );
};
