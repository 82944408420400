import { InboxDocument } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { fetchInbox } from './InboxSlice';
import { InboxDataSelector } from './InboxDataSelector';

export function useInbox(
    isFetchingAllowed = false,
): AbstractDataState<InboxDocument[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchInbox, InboxDataSelector, false, undefined, undefined, isFetchingAllowed);
}
