import { ProductRoutingResponse, getProductRoutingEndpoint } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { AxiosRequestConfig } from 'axios';
import { CpDataApi } from '../../cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode>({
    dataName: 'buttonUrl',
    fetchCallback(config?: AxiosRequestConfig) {
        return CpDataApi.post<ProductRoutingResponse>(getProductRoutingEndpoint(), config?.data).then(
            result => result.data,
        );
    },
});

export default reducer;
export const fetchButtonUrl = fetchData;
