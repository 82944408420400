import { Profile } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { MyProfileAutoAboDataSelector } from './MyProfileAutoAboDataSelector';
import { fetchMyProfileAutoAbo } from './MyProfileAutoAboSlice';

export function useMyProfileAutoAbo(
    isFetchingAllowed = true,
): AbstractDataState<Profile, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchMyProfileAutoAbo,
        MyProfileAutoAboDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
