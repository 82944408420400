import { useMemo } from 'react';

import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
} from '@cp-shared-9/frontend-integration';
import { ServiceRequestCategory, Contract } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

import { useContract } from '../contracts/useContract';
import { fetchRequestCategories } from './RequestCategoriesSlice';
import { RequestCategoriesDataSelector } from './RequestCategoriesDataSelector';

type RequestCategoriesAndContractData = {
    requestCategories: ServiceRequestCategory[];
    contract: Contract;
};

export function useContractAndRequestCategories(
    contractId: string,
): AbstractDataState<RequestCategoriesAndContractData, DefaultBusinessMarketApiErrorCode> {
    const contractState = useContract(contractId);
    const requestCategoriesState = useGetContractBasedApiData(
        contractId,
        fetchRequestCategories,
        RequestCategoriesDataSelector,
        contractState.data?._links?.getServiceRequest,
    );
    return useMemo(() => {
        const data =
            contractState.data && requestCategoriesState.data
                ? { requestCategories: requestCategoriesState.data, contract: contractState.data }
                : undefined;
        return {
            isLoading: contractState.isLoading || requestCategoriesState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && requestCategoriesState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? requestCategoriesState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? requestCategoriesState.loadingError
                : contractState.loadingError,
        };
    }, [contractState, requestCategoriesState]);
}
