import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { DigitalOfferPageUi } from './ui';
import { useDigitalOffer } from './useDigitalOfferPage';
import { NotificationStatus, Notification } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { DigitalOfferIntegrationErrors } from '@cp-de/common';

const DigitalOfferPagerWithHandlers = withLoadingAndNoConnectionHandler(DigitalOfferPageUi);

export const DigitalOfferPage: React.FC = () => {
    const { data, isLoading, loadingError } = useDigitalOffer();
    const { t } = useTranslation('digital-offer');

    let errorMessage = t('errors.general');
    switch (loadingError?.code as DigitalOfferIntegrationErrors) {
        case DigitalOfferIntegrationErrors.DataStorageUnknownError:
            errorMessage = t('errors.dataStorageUnknownError');
            break;
        case DigitalOfferIntegrationErrors.AcsGraphQlUnknownError:
            errorMessage = t('errors.acsGraphQlUnknownError');
            break;
        case DigitalOfferIntegrationErrors.DealerInformationsUnknownError:
            errorMessage = t('errors.dealerInformationsUnknownError');
            break;
    }

    return (
        <>
            {loadingError && errorMessage && (
                <Notification status={NotificationStatus.error} className={'u-mb'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: errorMessage,
                        }}
                    />
                </Notification>
            )}

            <DigitalOfferPagerWithHandlers isLoading={isLoading} hasError={false} digitalOfferData={data} />
        </>
    );
};
