import * as Yup from 'yup';

export type SilentExtensionValidationMessages = {
    endDateOfExceedingRequired: string;
    emailRequired: string;
    invalidEmail: string;
};

const defaultValidationErrorMessages: SilentExtensionValidationMessages = {
    endDateOfExceedingRequired: 'End Date of exceeding is required',
    emailRequired: 'Email is required',
    invalidEmail: 'Invalid email',
};

export const silentExtensionValidationSchema = (
    errorMessages: SilentExtensionValidationMessages | undefined = defaultValidationErrorMessages,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
): Yup.ObjectSchema =>
    Yup.object().shape({
        endDateOfExceeding: Yup.string().required(errorMessages.endDateOfExceedingRequired),
        email: Yup.string()
            .required(errorMessages.emailRequired)
            .email(errorMessages.invalidEmail),
    });
