import * as Yup from 'yup';
import { TFunction } from 'i18next';

import { PostboxConsentsFormFields } from './PostboxGivenConsents';
import { AnyObject } from 'yup/lib/types';

export const postboxConsentsValidationSchema = (
    t: TFunction,
    necessaryConsents: (keyof PostboxConsentsFormFields)[],
): Yup.ObjectSchema<AnyObject> => {
    const validations: Record<keyof PostboxConsentsFormFields, Yup.AnySchema> = {
        inboxConsentGivenFinancing: necessaryConsents.includes('inboxConsentGivenFinancing')
            ? Yup.bool().oneOf([true], t(`validation.inboxConsentGivenFinancing`))
            : Yup.mixed().notRequired(),
        inboxConsentGivenLeasing: necessaryConsents.includes('inboxConsentGivenLeasing')
            ? Yup.bool().oneOf([true], t(`validation.inboxConsentGivenLeasing`))
            : Yup.mixed().notRequired(),
    };
    return Yup.object().shape(validations);
};
