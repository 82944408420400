import React from 'react';
import { Button, ButtonContainer, Fieldset, Layout, Tabs } from '@vwfs-bronson/bronson-react';
import { FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Step, Tab } from '../enums';
import { PrivateAddressForm } from './private-address-form/PrivateAddressForm';
import { BusinessAddressForm } from './business-address-form/BusinessAddressForm';
import { ValidatedInput, ValidatedCheckbox } from '@cp-shared-9/frontend-ui';
import { IbanValidationView } from './iban-validation-view/IbanValidationView';
import { IbanStateHandler } from '../../types';
import { VehicleDocumentReceiverForm } from './vehicle-document-receiver-form/VehicleDocumentReceiverForm';
import { TerminatePpiForm } from './terminate-ppi-form/TerminatePpiForm';
import { InitialValues } from '../initialValues';
import { Contract, ContractType, ProductType } from '@cp-de/common';
import { isAccipiensContract, isDirectCredit } from '../../../../utils';

type ThirdPartyFormProps = {
    contract: Contract;
    setStep: (step: Step) => void;
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
    withKbs: boolean;
};

export const testIds = {
    tabs: 'address-information-tabs',
    tabPerson: 'tab-person',
    tabBusiness: 'tab-business',
    tabContentPerson: 'tab-content-person',
    tabContentBusiness: 'tab-content-business',
    accountHolder: 'account-holder',
    back: 'back',
    submit: 'third-party-form-submit',
};

export const ThirdPartyForm: React.FC<ThirdPartyFormProps> = ({
    contract,
    setStep,
    isValidating,
    ibanStateHandler,
    withKbs,
}) => {
    const { t } = useTranslation('total-early-settlement');
    const formik: FormikProps<InitialValues> = useFormikContext();
    const isFinancing = contract.contractType === ContractType.FINANCING;
    const isClassicCredit = contract.productType === ProductType.CLASSIC_CREDIT;
    const isIndividualCredit = contract.productType === ProductType.INDIVIDUAL_CREDIT;
    const isDirectCreditGroup = isDirectCredit(contract);
    const isDirectCreditCarGroup = 'DirectCreditCar' === contract.productGroup;
    const isAccipiens = isAccipiensContract(contract);

    const checkboxLabel = isAccipiens && isDirectCreditGroup ? t('checkbox.label-for-accipiens') : t('checkbox.label');

    const handleTabChange = (tab: Tab): void => {
        formik.setFieldValue('selectedTab', tab);
    };

    const handleCancel = (): void => {
        setStep(Step.FIRST);
        const currentValues = formik.values;
        formik.resetForm({ values: currentValues });
    };

    return (
        <>
            <h4 className={'u-mt u-mb'}>{t('address-information.headline')}</h4>
            {isFinancing && <p>{t('info-financing')}</p>}
            <Fieldset>
                <Fieldset.Row>
                    <Tabs defaultSelected={Tab.PERSON} testId={testIds.tabs}>
                        <Tabs.Header>
                            <Tabs.HeaderItem
                                tabId={Tab.PERSON}
                                className={`cp-tab-button-${Tab.PERSON}`}
                                onClick={(): void => handleTabChange(Tab.PERSON)}
                            >
                                {t('address-information.person.tab-label')}
                            </Tabs.HeaderItem>
                            <Tabs.HeaderItem
                                tabId={Tab.BUSINESS}
                                className={`cp-tab-button-${Tab.BUSINESS}`}
                                onClick={(): void => handleTabChange(Tab.BUSINESS)}
                            >
                                {t('address-information.business.tab-label')}
                            </Tabs.HeaderItem>
                        </Tabs.Header>
                        <Tabs.Content>
                            <Tabs.ContentItem tabId={Tab.PERSON} testId={testIds.tabContentPerson}>
                                <PrivateAddressForm />
                            </Tabs.ContentItem>
                            <Tabs.ContentItem tabId={Tab.BUSINESS} testId={testIds.tabContentBusiness}>
                                <BusinessAddressForm />
                            </Tabs.ContentItem>
                        </Tabs.Content>
                    </Tabs>
                </Fieldset.Row>
                {(!(isClassicCredit && isDirectCreditGroup) ||
                    (isIndividualCredit && !(isDirectCreditCarGroup || isDirectCreditGroup))) && (
                    <Fieldset.Row>
                        <h4 className={'u-mb'}>{t('vehicle-document-receiver.headline')}</h4>
                        <VehicleDocumentReceiverForm contract={contract} />
                    </Fieldset.Row>
                )}
                <h4 className={'u-mt'}>{withKbs ? t('iban-section.headline-kbis') : t('iban-section.headline')}</h4>
                <p className={'u-mb'}>{withKbs ? t('iban-section.text-kbis') : t('iban-section.text')}</p>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('iban-section.account-holder-input.label')}
                                name="accountHolder"
                                testId={testIds.accountHolder}
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <IbanValidationView isValidating={isValidating} ibanStateHandler={ibanStateHandler} />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                {withKbs && (
                    <Fieldset.Row>
                        <h4 className={'u-mb'}>{t('terminate-ppi.headline')}</h4>
                        <TerminatePpiForm />
                    </Fieldset.Row>
                )}
                {isFinancing && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/1">
                                <ValidatedCheckbox label={checkboxLabel} name={'consent'} testId={'consent'} />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
            </Fieldset>
            <ButtonContainer nav className="u-mt">
                <Button type="reset" secondary onClick={(): void => handleCancel()} testId={testIds.back}>
                    {t('translation:editableSectionNav.back')}
                </Button>
                <Button type="submit" onClick={formik.submitForm} testId={testIds.submit}>
                    {t('translation:editableSectionNav.send')}
                </Button>
            </ButtonContainer>
        </>
    );
};
