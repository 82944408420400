import { AuthorityAddress } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { fetchLicensingAuthorities } from './LicensingAuthoritiesSlice';
import { LicensingAuthoritiesDataSelector } from './LicensingAuthoritiesDataSelector';

export function useLicensingAuthorities(
    zipCode: string,
    alwaysRefetch: boolean,
): AbstractDataState<AuthorityAddress[], DefaultBusinessMarketApiErrorCode> {
    const data = { zipCode };
    return useGetSimpleApiData(fetchLicensingAuthorities, LicensingAuthoritiesDataSelector, alwaysRefetch, { data });
}
