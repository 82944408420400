import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ReportDamageResponsibleInsurance, longDateFormat } from '@cp-de/common';
import { YesNoButtons, insuranceFormValidationSchema, getYesNoValidation } from '../../common';
import { AnyObject } from 'yup/lib/types';
import { formatCpDate } from '@cp-shared-9/common-utilities';

export const getStep1ValidationSchema = (isAccipiens: boolean, t: TFunction): Yup.ObjectSchema<AnyObject> => {
    const shape: AnyObject = {
        responsibleInsurance: Yup.string()
            .required(t('form.error.empty-radio-buttons'))
            .oneOf(Object.values(ReportDamageResponsibleInsurance)),
        policyHolder: Yup.mixed<YesNoButtons>().when('responsibleInsurance', {
            is: (responsibleInsurance: string) => responsibleInsurance === ReportDamageResponsibleInsurance.OWN,
            then: getYesNoValidation(t),
        }),
        transferInsurance: Yup.mixed<YesNoButtons>().when('policyHolder', {
            is: (policyHolder: string) => policyHolder === YesNoButtons.no,
            then: getYesNoValidation(t),
        }),
        insuranceClaim: Yup.array().when('transferInsurance', {
            is: (transferInsurance: string) => transferInsurance === YesNoButtons.yes,
            then: Yup.array()
                .required(t('insurance-claim-upload.required-validation-error'))
                .max(1, t('file-upload.errors.multiple-files-error')),
        }),
    };

    if (isAccipiens) {
        shape['damageDate'] = Yup.string()
            .required(t('form.error.date.not-empty'))
            .test('format', t('form.error.date.incorrect-format'), date => formatCpDate(date, longDateFormat).isValid())
            .test('no future date', t('form.error.date.not-in-future'), date =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(formatCpDate().toMoment()),
            );
    }
    return Yup.object().shape(shape);
};

export const getStep2ValidationSchema = (
    isAccipiens: boolean,
    t: TFunction,
    withInsuranceForm: boolean,
): Yup.ObjectSchema<AnyObject> => {
    const insuranceFormFields = {
        insurance: insuranceFormValidationSchema(t, isAccipiens),
    };

    const shape: AnyObject = {
        ...(withInsuranceForm ? insuranceFormFields : {}),
    };

    if (!isAccipiens || !withInsuranceForm) {
        shape['deregistration'] = getYesNoValidation(t);
    }

    return Yup.object().shape(shape);
};
