import { FourSalesContract } from '@cp-de/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';

import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { fetchFourSalesContracts } from './FourSalesContractsSlice';
import { FourSalesContractsDataSelector } from './FourSalesContractsDataSelector';

export function useFourSalesContracts(
    isFetchingAllowed = true,
): AbstractDataState<FourSalesContract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchFourSalesContracts,
        FourSalesContractsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
