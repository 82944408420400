import { BankData, SignedData } from '@cp-shared-9/apis';
import { CPDate } from '@cp-shared-9/common-utilities';

export type FinancialDetails = {
    additionalProducts?: BundledProductsDetails[];
    annualPercentageRate?: number;
    balloonInstalment?: number;
    contractDuration?: number;
    contractExceedanceIndicator?: boolean;
    contractExtensionEnd?: CPDate;
    contractExtensionDuration?: number;
    downPayment?: number;
    endDate?: CPDate;
    iban?: string;
    invoiceBegin?: CPDate;
    nominalAmount?: number;
    pendingCapital?: number;
    startDate?: CPDate;
    overpayment?: boolean;
    yearlyMileage?: number;
    eligibleForIbanChange: boolean;
    eligibleForMileageChange?: EligibleForMileageChangeType;
    changeMileageOptions?: number[];
    _links?: FinancialDetailsLinks;
};

export type FinancialDetailsLinks = {
    changeIban?: string;
    changeMileage?: string;
};

export type BundledProductsDetails = {
    extendedProductId?: string;
    productId?: string;
    productGroup?: string;
    productName?: string;
};

export type SepaMandateDocument = {
    sepaMandateDocument: string;
};

export type IbanError = 'SAME_BANK_ACCOUNT' | 'BFF_API_ERROR';

export interface IbanChange {
    signedBankData: SignedData<BankData>;
}

export interface MileageChange {
    mileage: number;
    email: string;
}

export enum EligibleForMileageChangeType {
    YES = 'Yes',
    FORWARD_TO_DEALER = 'ForwardToDealer',
    NO = 'No',
    ERROR = 'Error',
}
