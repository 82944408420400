import { withLoadingHandler } from 'components/integration-wrapper';
import { GreetingLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import React from 'react';
import { GreetingUi } from './ui';
import { useGreeting } from './useGreeting';

const GreetingWithHandlers = withLoadingHandler(GreetingUi);

export const Greeting: React.FC = () => {
    const { data: greeting, isLoading } = useGreeting();

    return (
        <GreetingWithHandlers
            isLoading={isLoading}
            greeting={greeting}
            loadingPlaceholder={<GreetingLoadingPlaceholder />}
        />
    );
};
