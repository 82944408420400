import React, { useState } from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from 'cp-xhr';
import moment from 'moment-timezone';

import {
    ServiceRequestCategory,
    Contract,
    ServiceRequest,
    longDateFormat,
    marketApiDateFormat,
    ReasonForShipment,
    ContractType,
} from '@cp-de/common';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

import { dashboardPagePath } from '../../navigation/paths';
import { ContractHeader } from '../../contract-header';
import { RequestDocumentForm } from './request-document-form';
import { RequestDocumentModal } from './request-document-modal';
import {
    RequestDocumentFormFields,
    RequestDocumentFormCategory,
} from './request-document-form/utils/RequestDocumentFormFields';

export type RequestDocumentComponentProps = {
    contract?: Contract;
    requestCategories?: ServiceRequestCategory[];
    documentType?: RequestDocumentFormCategory;
    shippingReason?: ReasonForShipment;
};

type RequestProps = {
    status?: 'IS_LOADING' | 'HAS_ERROR' | 'SUCCESS';
    category?: RequestDocumentFormCategory;
};

const withAdditionalFields = (body: ServiceRequest, formValues: RequestDocumentFormFields): ServiceRequest => {
    const { year, from, to, category, name, streetName, houseNumber, zipCode, locality, shippingReason } = formValues;
    const licensingAuthorityCategories = [
        'RequestForZBT2FinanceAllContracts',
        'RequestForZBT2LeasingAllContracts',
        'RequestForZBT2Finance',
        'RequestForZBT2Leasing',
    ];
    const categoriesWithoutAdditionalValues = [
        'InterestCertificate',
        'StatementOfAccount',
        ...licensingAuthorityCategories,
    ];
    if (categoriesWithoutAdditionalValues.indexOf(category || '') === -1) {
        return body;
    }
    body.additionalProperties = {};
    if (category === 'InterestCertificate' && year) {
        body.additionalProperties.year = year;
    }
    if (category === 'StatementOfAccount' && from) {
        body.additionalProperties.beginDate = moment(from, longDateFormat).format(marketApiDateFormat);
    }
    if (category === 'StatementOfAccount' && to) {
        body.additionalProperties.endDate = moment(to, longDateFormat).format(marketApiDateFormat);
    }
    if (licensingAuthorityCategories.indexOf(category || '') !== -1) {
        body.additionalProperties.name = name || '';
        body.additionalProperties.streetName = streetName || '';
        body.additionalProperties.houseNumber = houseNumber || '';
        body.additionalProperties.zipCode = zipCode || '';
        body.additionalProperties.locality = locality || '';
    }
    if (!!shippingReason) {
        body.reasonForShipment = shippingReason;
    }
    return body;
};

export const RequestDocumentComponent: React.FC<RequestDocumentComponentProps> = ({
    requestCategories,
    contract,
    documentType,
    shippingReason,
}) => {
    const [request, setRequest] = useState<RequestProps>({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestCertificateSuccess');

    const history = useHistory();
    if (!requestCategories || !contract) {
        return null;
    }

    const onSubmit = (formValues: RequestDocumentFormFields): void => {
        if (contract._links?.postServiceRequest && formValues.category) {
            setRequest({ status: 'IS_LOADING' });
            const body: ServiceRequest = {
                category:
                    contract.contractType === ContractType.SERVICE_MANAGEMENT &&
                    formValues.category === 'LeasingConfirmation'
                        ? 'ServiceManagementConfirmation'
                        : contract.contractType === ContractType.SERVICE_MANAGEMENT &&
                          formValues.category === 'LeasingOrder'
                        ? 'ServiceManagementOrder'
                        : formValues.category,
            };
            CpDataApi.post(contract._links?.postServiceRequest, withAdditionalFields(body, formValues))
                .then(() => {
                    setRequest({ status: 'SUCCESS', category: formValues.category });
                    onSuccess(formValues.category);
                    setShowModal(true);
                })
                .catch(() => {
                    setRequest({ status: 'HAS_ERROR' });
                    setShowModal(true);
                });
        }
    };

    const goBackToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const onConfirmModal = (): void => {
        if (request.status === 'HAS_ERROR') {
            setShowModal(false);
            return;
        }
        goBackToDashboard();
    };

    return (
        <>
            {request.status === 'IS_LOADING' && <Spinner fullPage={true} />}
            <Card element="article">
                <ContractHeader isDeferralLinkShown={false} contract={contract} />
            </Card>
            <RequestDocumentForm
                contract={contract}
                requestCategories={requestCategories}
                defaultDocumentCategory={documentType}
                defaultShippingReason={shippingReason}
                onCancel={goBackToDashboard}
                onSubmit={onSubmit}
            />
            <RequestDocumentModal
                shown={showModal}
                error={request.status === 'HAS_ERROR'}
                product={contract.contractType}
                category={request.category}
                onClose={() => setShowModal(false)}
                onConfirm={onConfirmModal}
            />
        </>
    );
};
