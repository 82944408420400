import { PostboxConsents } from '@cp-de/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { PostboxConsentsDataSelector } from './PostboxConsentsDataSelector';
import { fetchPostboxConsents } from './PostboxConsentsSlice';

export function usePostboxConsents(
    isFetchingAllowed = true,
): AbstractDataState<PostboxConsents, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchPostboxConsents,
        PostboxConsentsDataSelector,
        false,
        undefined,
        undefined,
        isFetchingAllowed,
    );
}
