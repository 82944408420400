import React from 'react';
import {
    HeroImage,
    openInSameWindow,
    useAnalyticsErrorPageTracker,
    StaticPageLoadingPlaceholder,
} from '@cp-shared-9/frontend-ui';

import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorPage, isLoading, loadingError } = useUnauthorizedErrorPage();

    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorPage);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={unauthorizedErrorPage?.pretitle}
            title={unauthorizedErrorPage?.title || ''}
            subTitle={unauthorizedErrorPage?.subTitle}
            buttonText={unauthorizedErrorPage?.buttonText}
            clickHandler={openInSameWindow(unauthorizedErrorPage?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
