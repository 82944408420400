import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { longDateFormat, createContractNumberValidation } from '@cp-de/common';
import { RegistrationFormFields } from './RegistrationFormFields';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import { AnyObject } from 'yup/lib/types';
import { Moment } from 'moment';

export const errorMessageIds = {
    product: {
        empty: 'validation.product.empty',
    },
    contractNumber: {
        empty: 'validation.contract-number.empty',
        format: 'validation.contract-number.format',
        format4Sales: 'validation.contract-number.format4Sales',
    },
    zipCode: {
        empty: 'validation.zip-code.empty',
        format: 'validation.zip-code.format',
    },
    lastName: 'validation.last-name',
    birthDate: {
        empty: 'validation.birth-date.empty',
        format: 'validation.birth-date.format',
        futureDate: 'validation.birth-date.future-date',
        underage: 'validation.birth-date.underage',
    },
    privacyPolicy: 'validation.privacy-policy',
    usageAgreement: 'validation.usage-agreement',
};

export const validationSchema = (t: TFunction, is4Sales: boolean): Yup.ObjectSchema<AnyObject> => {
    const contractNumberErrorMessages = {
        empty: t(errorMessageIds.contractNumber.empty),
        min: t(errorMessageIds.contractNumber.format),
        max: t(errorMessageIds.contractNumber.format),
        format: t(errorMessageIds.contractNumber.format),
        format4Sales: t(errorMessageIds.contractNumber.format4Sales),
    };

    const validations: Record<
        keyof RegistrationFormFields,
        Yup.StringSchema | Yup.BooleanSchema<boolean | undefined>
    > = {
        product: Yup.string().required(t(errorMessageIds.product.empty)),
        contractNumber: createContractNumberValidation(is4Sales, contractNumberErrorMessages),
        zipCode: Yup.string()
            .trim()
            .required(t(errorMessageIds.zipCode.empty))
            .min(5, t(errorMessageIds.zipCode.format)),
        lastName: Yup.string()
            .trim()
            .required(t(errorMessageIds.lastName)),
        birthDate: Yup.string()
            .required(t('validation.birth-date.empty'))
            .test('format', t(errorMessageIds.birthDate.format), (date: string | Moment | null | undefined) =>
                formatCpDate(date, longDateFormat).isValid(),
            )
            .test(
                'no future date',
                t(errorMessageIds.birthDate.futureDate),
                (date: string | Moment | null | undefined) =>
                    formatCpDate(date, longDateFormat)
                        .toMoment()
                        .isSameOrBefore(formatCpDate().toMoment()),
            )
            .test('too old', t(errorMessageIds.birthDate.format), (date: string | Moment | null | undefined) =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrAfter(
                        formatCpDate()
                            .subtract(100, 'years')
                            .toMoment(),
                    ),
            )
            .test('underage', t(errorMessageIds.birthDate.underage), (date: string | Moment | null | undefined) =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(
                        formatCpDate()
                            .subtract(18, 'years')
                            .toMoment(),
                    ),
            ),
        confirmUsageAgreement: Yup.bool().oneOf([true], t(errorMessageIds.usageAgreement)),
        confirmMarketing: Yup.bool().notRequired(),
        confirmDataStorage: Yup.bool().notRequired(),
    };
    return Yup.object().shape(validations);
};
