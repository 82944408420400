import { Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    Button,
    ButtonGroup,
    Fieldset,
    Layout,
    InteractionLayer,
    ProductSearchFilter,
    FormHeading,
} from '@vwfs-bronson/bronson-react';
import { ValidatedDate, ValidatedSelect, ValidatedSelectItem } from '@cp-shared-9/frontend-ui';
import { filterContractValidationSchema } from '@cp-de/common';
import { initialValues, InitialValues } from './initialValues';
import { FilterBrand } from './FilterBrand';

type ContractsFilterProps = {
    isFilterVisible: boolean;
    brandList: (string | undefined)[];
    contractList: ValidatedSelectItem[];
    setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
    handleFilters: (filterValues: InitialValues) => void;
};

export const filterTestIds = {
    testId: 'filter-layer',
    fields: {
        startDateTestId: 'filterStartDate',
        endDateTestId: 'filterEndDate',
        brandsTestId: 'filterBrands',
        contractsTestId: 'filterContract',
    },
};

export const ContractFilter: React.FC<ContractsFilterProps> = ({
    isFilterVisible,
    brandList,
    contractList,
    setIsFilterVisible,
    handleFilters,
}) => {
    const { t, i18n } = useTranslation();
    const language = i18n.languages[0];

    const getValidationSchema = () => {
        return filterContractValidationSchema();
    };

    const applyFilters = (filterValues: InitialValues): void => {
        handleFilters(filterValues);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={getValidationSchema()}
                onSubmit={(values): void => applyFilters(values)}
            >
                {({ submitForm, resetForm, values }) => (
                    <InteractionLayer
                        isHidden={!isFilterVisible}
                        onClose={(): void => setIsFilterVisible(!isFilterVisible)}
                        testId={filterTestIds.testId}
                        buttonBarButtons={[
                            <Button testId="submitButton" onClick={submitForm} key="submit" type="button">
                                {t('contracts:form-labels.results')}
                            </Button>,
                            <Button
                                type="button"
                                onClick={() => resetForm()}
                                testId="resetButton"
                                key="reset"
                                iconReversed
                                icon="reload"
                                simple
                                link
                            >
                                {t('contracts:form-labels.reset')}
                            </Button>,
                        ]}
                    >
                        <ProductSearchFilter>
                            <FormHeading>{t('contracts:form-labels.filter-by')}</FormHeading>
                            <Fieldset className={'u-mt'}>
                                <Accordion multipleOpen>
                                    <Accordion.Item title={t('contracts:form-labels.contract')}>
                                        <Fieldset.Row>
                                            <Layout.Item default="1/1">
                                                <ValidatedSelect
                                                    name={filterTestIds.fields.contractsTestId}
                                                    testId={filterTestIds.fields.contractsTestId}
                                                    selectItems={contractList}
                                                />
                                            </Layout.Item>
                                        </Fieldset.Row>
                                    </Accordion.Item>
                                    <Accordion.Item title={t('contracts:form-labels.brand')}>
                                        <Fieldset.Row>
                                            <Layout.Item default="1/1">
                                                <ButtonGroup buttonGroupType="checkbox" buttonGroupName="brandsGroup">
                                                    {brandList.map((brand, index) => (
                                                        <Field
                                                            name={filterTestIds.fields.brandsTestId}
                                                            testId={filterTestIds.fields.brandsTestId}
                                                            key={`brand-${index}`}
                                                            component={FilterBrand}
                                                            index={index}
                                                            brand={brand}
                                                            selected={values.filterBrands}
                                                        />
                                                    ))}
                                                </ButtonGroup>
                                            </Layout.Item>
                                        </Fieldset.Row>
                                    </Accordion.Item>
                                    <Accordion.Item title={t('contracts:form-labels.date')}>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedDate
                                                        label={t('contracts:form-labels.start-date')}
                                                        language={language}
                                                        outputDateFormat="d.m.Y"
                                                        name={filterTestIds.fields.startDateTestId}
                                                        testId={filterTestIds.fields.startDateTestId}
                                                        type="text"
                                                        value={values.filterStartDate}
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedDate
                                                        label={t('contracts:form-labels.end-date')}
                                                        language={language}
                                                        outputDateFormat="d.m.Y"
                                                        name={filterTestIds.fields.endDateTestId}
                                                        testId={filterTestIds.fields.endDateTestId}
                                                        type="text"
                                                        value={values.filterEndDate}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    </Accordion.Item>
                                </Accordion>
                            </Fieldset>
                        </ProductSearchFilter>
                    </InteractionLayer>
                )}
            </Formik>
        </>
    );
};
