import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { IdentityChangeError } from '@cp-de/common';

export type ErrorModalProps = {
    error?: WithDefaultCpIntegrationErrors<IdentityChangeError>;
    onClose: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({ error, onClose }) => {
    const { t } = useTranslation('my-profile');
    const modalPrefix = 'change-name.error-modal';
    const getPrefix = () => {
        switch (error) {
            case IdentityChangeError.VIRUS_SCAN_ERROR:
                return `${modalPrefix}.virus-scan`;
            case IdentityChangeError.WRONG_OR_INVALID_DOCUMENT:
                return `${modalPrefix}.invalid-document`;
            default:
                return `${modalPrefix}.default`;
        }
    };

    return (
        <Modal
            shown={!!error}
            buttonConfirmText={t(`${modalPrefix}.close`)}
            onConfirm={onClose}
            onClose={onClose}
            title={t(`${getPrefix()}.title`)}
            status="error"
            testId="change-name-error-modal"
        >
            {t(`${getPrefix()}.text`)}
        </Modal>
    );
};
