import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { ReportDamageUi } from './ui';
import { useContract } from '../contracts/useContract';
import { isAccipiensContract } from '../../utils';

const ReportDamageWithHandlers = withLoadingAndNoConnectionHandler(ReportDamageUi);

type RepairApprovalProps = {
    contractId: string;
};

export const ReportDamage: React.FC<RepairApprovalProps> = ({ contractId }) => {
    const { data, isLoading, loadingError } = useContract(contractId);
    const { t } = useTranslation('request-document');

    useAnalyticsPageViewTracker('reportDamage');

    return (
        <ReportDamageWithHandlers
            isLoading={isLoading}
            errorText={t('error-message')}
            contract={data}
            hasError={!!loadingError}
            isAccipiens={data ? isAccipiensContract(data) : false}
        />
    );
};
