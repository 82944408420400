import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { LoginChoiceOfferUi } from './ui';
import { useLoginChoiceOffer } from './useLoginChoiceOffer';
import {
    useAnalyticsPageViewTracker,
    LoginChoiceLoadingPlaceholder,
    useAuthentication,
} from '@cp-shared-9/frontend-ui';
import { useHistory } from 'react-router-dom';
import { loginInProgressDigitalOfferPagePath } from '../navigation/paths';

const LoginChoiceOfferWithHandlers = withLoadingAndNoConnectionHandler(LoginChoiceOfferUi);

export const LoginChoiceOffer: React.FC = () => {
    const history = useHistory();
    const { isAuthenticated } = useAuthentication();
    const { cmsContent: loginChoiceOffer, isLoading, loadingError } = useLoginChoiceOffer();

    useAnalyticsPageViewTracker('landingPageOffer', !!loginChoiceOffer);

    React.useEffect(() => {
        if (isAuthenticated) {
            history.push(`${loginInProgressDigitalOfferPagePath()}${window.location.search}`);
        }
    }, [isAuthenticated]);

    return (
        <LoginChoiceOfferWithHandlers
            isLoading={isLoading}
            loginChoiceOffer={loginChoiceOffer}
            hasError={!!loadingError}
            loadingPlaceholder={<LoginChoiceLoadingPlaceholder />}
        />
    );
};
