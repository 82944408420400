import React from 'react';

import { UsageAgreement } from '@cp-de/common';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroImage } from '@cp-shared-9/frontend-ui';

export const UsageAgreementUi: React.FC<{ usageAgreement?: UsageAgreement }> = ({ usageAgreement }) => {
    if (!usageAgreement) {
        return null;
    }

    const { teaser, content } = usageAgreement;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={true} />
    ) : null;

    const contentComponent = content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium" className={'u-mt'}>
                {contentComponent}
            </ContentSection>
        </>
    );
};
