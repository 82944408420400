import moment from 'moment-timezone';
import { PostboxDocument } from '@cp-de/common';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import { TimeFrameFilterItem } from '@cp-shared-9/frontend-ui';

export const getTimeFrameFilterItems = (): TimeFrameFilterItem[] => {
    const currentDate = formatCpDate().toMoment();
    return [
        {
            key: 'last-3-months',
            from: formatCpDate()
                .subtract(3, 'month')
                .toMoment(),
            to: currentDate,
            value: 'last-3-months',
        },
        {
            key: 'last-6-months',
            from: formatCpDate()
                .subtract(6, 'month')
                .toMoment(),
            to: currentDate,
            value: 'last-6-months',
        },
        {
            key: 'last-12-months',
            from: formatCpDate()
                .subtract(1, 'year')
                .toMoment(),
            to: currentDate,
            value: 'last-12-months',
        },
        {
            key: 'last-2-years',
            from: formatCpDate()
                .subtract(2, 'year')
                .toMoment(),
            to: currentDate,
            value: 'last-2-years',
        },
        {
            key: 'last-3-years',
            from: formatCpDate()
                .subtract(3, 'year')
                .toMoment(),
            to: currentDate,
            value: 'last-3-years',
        },
        {
            key: 'last-4-years',
            from: formatCpDate()
                .subtract(4, 'year')
                .toMoment(),
            to: currentDate,
            value: 'last-4-years',
        },
    ];
};

function getOldestDocumentDate(documents: PostboxDocument[]) {
    const allDocumentDates = documents.map(document => formatCpDate(document.creationDate).toMoment());
    return moment.min(allDocumentDates);
}

export function getAvailableTimeFrames(documents: PostboxDocument[]): TimeFrameFilterItem[] {
    const oldestDocumentDate = getOldestDocumentDate(documents);
    const allTimeFrames = getTimeFrameFilterItems();

    const listOfAvailableTimeframes: TimeFrameFilterItem[] = [];

    for (let i = 0; allTimeFrames[i].from >= oldestDocumentDate && i < allTimeFrames.length - 1; i++) {
        const timeframe = allTimeFrames[i];

        listOfAvailableTimeframes.push({
            key: timeframe.key,
            from: timeframe.from,
            to: timeframe.to,
            value: 'timeframes.' + timeframe.value,
        });
    }

    return listOfAvailableTimeframes;
}
