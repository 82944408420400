import React, { useState } from 'react';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';
import { useTranslation } from 'react-i18next';
import { useContract } from '../contracts/useContract';
import { useFinancialDetails } from '../contracts/financial-details';
import { useMyProfile } from '../my-profile/overview';
import { BankData, SignedData } from '@cp-shared-9/apis';
import { IbanStateHandler } from './types';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import {
    Notification,
    NotificationStatus,
    useAnalyticsPageViewTracker,
    EarlySettlementLoadingPlaceholder,
} from '@cp-shared-9/frontend-ui';

const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

type TotalEarlySettlementProps = {
    contractId: string;
};

export const TotalEarlySettlement: React.FC<TotalEarlySettlementProps> = ({ contractId }) => {
    const { data: contractState, isLoading: isContractLoading, loadingError: contractLoadingError } = useContract(
        contractId,
    );

    const {
        data: financialDetails,
        isLoading: isFinancialDetailsLoading,
        loadingError: financialDetailsLoadingError,
    } = useFinancialDetails(contractState?.contractId || '');

    const { data: myProfile, isLoading: isLoadingMyProfile, loadingError: loadingErrorMyProfile } = useMyProfile();

    const { t } = useTranslation('total-early-settlement');

    useAnalyticsPageViewTracker('earlySettlement', !!myProfile && !!financialDetails);

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const isLoading = isContractLoading || isFinancialDetailsLoading || isLoadingMyProfile;
    const loadingError = contractLoadingError || financialDetailsLoadingError || loadingErrorMyProfile;

    return (
        <>
            {loadingError ? (
                <Notification status={NotificationStatus.error} text={t('error-notification')} />
            ) : (
                <TotalEarlySettlementWithHandlers
                    isLoading={isLoading}
                    contract={contractState}
                    hasError={false}
                    financialDetails={financialDetails}
                    myProfile={myProfile}
                    ibanStateHandler={ibanStateHandler}
                    loadingPlaceholder={
                        <EarlySettlementLoadingPlaceholder withDividedContractHeader summaryElements={1} />
                    }
                />
            )}
        </>
    );
};
