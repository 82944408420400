import { createDynamicTable, DynamicTableFormatOptions, TableHeaderEntry } from '@cp-shared-9/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import React from 'react';
import { PaymentOverview } from '@cp-de/common';

export type AmortizationTablePaymentOverviewProps = {
    paymentOverview?: PaymentOverview[];
};

const DynamicTable = createDynamicTable<PaymentOverview>();

export const AmortizationTablePaymentOverview: React.FC<AmortizationTablePaymentOverviewProps> = ({
    paymentOverview,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-table');

    if (!paymentOverview) {
        return null;
    }

    const columnHeadings: TableHeaderEntry[] = [
        t('table.installment-number'),
        t('table.installment-due-date'),
        t('table.installment-amount'),
        t('table.payment-status'),
        t('table.day-of-payment'),
        t('table.outstanding-payment'),
    ];

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: false,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    return (
        <DynamicTable
            rows={paymentOverview}
            columnHeadings={columnHeadings}
            toColumnValues={(row: PaymentOverview): string[] => {
                const fallbackValue = '';
                return [
                    f(row.paymentRateNumber, TranslationFormat.NUMBER),
                    f(row.paymentDate, TranslationFormat.DATE),
                    f(row.paymentAmount, TranslationFormat.CURRENCY),
                    row.paymentStatus || fallbackValue,
                    f(row.paymentReceiptDate, TranslationFormat.DATE) || fallbackValue,
                    f(row.outstandingPayment, TranslationFormat.CURRENCY) || fallbackValue,
                ];
            }}
            formatOptions={formatOptions}
        />
    );
};
