import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-9/frontend-ui';

import {
    connectionProblemPagePath,
    consentPagePath,
    dashboardPagePath,
    faqPagePath,
    featureAppLicensesPagePath,
    landingPagePath,
    legalTermsPagePath,
    loginChoicePagePath,
    loginChoiceOfferPagePath,
    loginInProgressPagePath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    postboxPagePath,
    privacyPolicyPagePath,
    registrationPagePath,
    thirdPartyLicensesPagePath,
    usageAgreementPagePath,
    digitalOfferPagePath,
    dashboardPagePathParams,
} from './paths';
import { useUserContractsDataAndFlags } from '../../Providers';
import { useRegistrationFlow } from '../../auth';
import { isAuthorizationResult } from '../../utils';

const pagesWithoutNavbar = [
    landingPagePath(),
    loginChoicePagePath(),
    loginChoiceOfferPagePath(),
    consentPagePath(),
    loginInProgressPagePath(),
    featureAppLicensesPagePath(),
];

const pagesWithoutStaticHeader = [
    loginChoicePagePath(),
    loginChoiceOfferPagePath(),
    faqPagePath(),
    legalTermsPagePath(),
    usageAgreementPagePath(),
    privacyPolicyPagePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    connectionProblemPagePath(),
    thirdPartyLicensesPagePath(),
    loginInProgressPagePath(),
    dashboardPagePath(),
    dashboardPagePathParams(),
];

export type NavigationBarProps = {
    isAuthenticated?: boolean;
};

function publicNavigationLinks(t: TFunction, login: () => Promise<void>, onLogin: () => void): SiteNavItemPropsList {
    return [
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.login'),
            onClick: (): void => {
                onLogin();
                login();
            },
        },
    ];
}

function privateContractNavigationLinks(
    t: TFunction,
    logout: () => Promise<void>,
    areReservationsOnly: boolean,
    isFourSalesContractPresent: boolean,
    hasAnyContract: boolean,
    hasDigitalOffer: boolean,
): SiteNavItemPropsList {
    const hiddenLinksForAoDAndRac = areReservationsOnly
        ? []
        : [
              {
                  url: myProfilePagePath(),
                  label: t('navigation.profile'),
              },
              {
                  url: myRequestPagePath(),
                  label: t('navigation.my-request'),
              },
          ];

    const showPostBoxOption =
        areReservationsOnly && !isFourSalesContractPresent
            ? []
            : [
                  {
                      url: postboxPagePath(),
                      label: t('navigation.postbox'),
                  },
              ];

    const showDigitalOffer = hasDigitalOffer
        ? [
              {
                  url: digitalOfferPagePath(),
                  label: t('navigation.digital-offer'),
              },
          ]
        : [];

    if (!hasAnyContract) {
        return [
            ...showDigitalOffer,
            {
                label: t('navigation.logout'),
                onClick: logout,
            },
        ];
    }

    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        ...hiddenLinksForAoDAndRac,
        ...showPostBoxOption,
        ...showDigitalOffer,
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

function privateKYCNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC<NavigationBarProps> = () => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const { onAction } = useAnalyticsActionTracker('login');

    const currentPathName = useLocation().pathname;

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const { isAuthenticated, logout } = useAuthentication();
    const { state: registrationState } = useRegistrationFlow();
    const {
        contracts: { data: contracts, isLoading: isContractsLoading },
        aboContracts: { data: autoAboContracts, isLoading: isAboContractsLoading },
        aodContracts: { data: aodContracts, isLoading: isAoDContractsLoading },
        racContracts: { data: racContracts, isLoading: isRaCContractsLoading },
        fourSalesContracts: { data: fourSalesContracts, isLoading: isFourSalesContractsLoading },
        digialOfferTransactions: { data: digialOfferTransactions, isLoading: isDigialOfferTransactionsLoading },
        isLoadingPermission,
    } = useUserContractsDataAndFlags();

    const hasAnyContract =
        (isAuthorizationResult(registrationState) && registrationState.standard.isRegistered) ||
        !!contracts?.length ||
        !!aodContracts?.length ||
        !!racContracts?.length ||
        !!fourSalesContracts?.length;
    const hasDigitalOffer = !!digialOfferTransactions?.length;

    const areReservationsOnly =
        !contracts?.length &&
        (!!aodContracts?.length ||
            !!racContracts?.length ||
            !!autoAboContracts?.length ||
            !!fourSalesContracts?.length);

    const isFourSalesContractPresent = !!fourSalesContracts?.length;

    const areLoading =
        isContractsLoading ||
        isAboContractsLoading ||
        isAoDContractsLoading ||
        isRaCContractsLoading ||
        isFourSalesContractsLoading ||
        isDigialOfferTransactionsLoading;

    const isRegistrationPage = history.location.pathname === registrationPagePath();

    const handleLogout = () => {
        const redirectUri = window.location.origin + landingPagePath();
        return logout({ redirectUri });
    };

    const getNavigationLinks = () => {
        if (isAuthenticated) {
            // User is authenticated
            if (isRegistrationPage) {
                // User is on the registration page
                return privateKYCNavigationLinks(t, handleLogout);
            } else {
                // User is on other private pages
                return privateContractNavigationLinks(
                    t,
                    handleLogout,
                    areReservationsOnly,
                    isFourSalesContractPresent,
                    hasAnyContract,
                    hasDigitalOffer,
                );
            }
        } else {
            // User is not authenticated
            return publicNavigationLinks(t, () => Promise.resolve(history.push(loginChoicePagePath())), onAction);
        }
    };

    const navigationItems = getNavigationLinks().map(navItem => {
        return {
            ...{ isActive: history.location.pathname === navItem.url },
            ...navItem,
        };
    });

    const navigation: SiteNavProps = {
        navigationItems: isLoadingPermission && !isRegistrationPage ? [] : navigationItems,
    };

    return (
        <NavigationBarShared
            logoAltText={isAuthenticated ? t('navigation.dashboard') : t('navigation.home')}
            onLogoClickPath={dashboardPagePath()}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden || areLoading}
        />
    );
};
