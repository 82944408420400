import React from 'react';
import { Form, Fieldset, Layout, FormField, Input, ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
    DataOverview,
    preventSubmit,
    ValidatedInput,
    InfoIcon,
    CleaveInput,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { AddressChange, AddressChangeError } from '@cp-de/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';

import { validationSchema } from './validation';

export type ChangeAddressFormProps = {
    initialValues: AddressChange;
    onCancel: (values: AddressChange) => void;
    onSubmit: (values: AddressChange) => void;
    error?: WithDefaultCpIntegrationErrors<AddressChangeError>;
    isContractView?: boolean;
};

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
    street: 'street',
    houseNumber: 'houseNumber',
    zipCode: 'zipCode',
    city: 'city',
};

export const ChangeAddressForm: React.FC<ChangeAddressFormProps> = ({
    initialValues,
    onCancel,
    onSubmit,
    error,
    isContractView = false,
}) => {
    const { t } = useTranslation('my-profile');

    return (
        <DataOverview title={t('online-banking-redirection.title')} withoutCardEffect={isContractView}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: AddressChange): void => {
                    onSubmit(values);
                }}
                validationSchema={validationSchema(t)}
            >
                {formik => (
                    <Form onSubmit={e => preventSubmit(e)} data-testid="editForm">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="5/6" l="1/1">
                                        <ValidatedInput
                                            label={t('change-address.street.label')}
                                            name="street"
                                            testId={testIds.street}
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/6" l="1/1">
                                        <ValidatedInput
                                            label={t('change-address.house-number.label')}
                                            name="houseNumber"
                                            testId={testIds.houseNumber}
                                            type="text"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/6" l="1/1">
                                        <CleaveInput
                                            cleaveOptions={{
                                                delimiter: '',
                                                blocks: [5],
                                                numericOnly: true,
                                            }}
                                            inputMode={'numeric'}
                                            label={t('change-address.zip-code.label')}
                                            name={'zipCode'}
                                            testId={testIds.zipCode}
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="4/6" l="1/1">
                                        <ValidatedInput
                                            label={t('change-address.city.label')}
                                            name="city"
                                            testId={testIds.city}
                                            type="text"
                                        />
                                    </Layout.Item>
                                    <Layout.Item default="1/6" l="1/1">
                                        <FormField
                                            type="input"
                                            id="country"
                                            labelText={t('change-address.country.label')}
                                            infoIcon={<InfoIcon text={t('change-address.country.tooltip')} />}
                                        >
                                            <Input
                                                type="text"
                                                id="country-text"
                                                testId="country-text"
                                                placeholder={t('change-address.country.value')}
                                                disabled
                                            />
                                        </FormField>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        {error && (
                            <Notification
                                testId="notification"
                                status={
                                    error === 'ADDRESS_NOT_FOUND'
                                        ? NotificationStatus.warning
                                        : NotificationStatus.error
                                }
                            >
                                {error === 'ADDRESS_NOT_FOUND'
                                    ? t('change-address.error.address-not-found')
                                    : t('change-address.error.connection-error')}
                            </Notification>
                        )}
                        <ButtonContainer nav className="u-mt">
                            <Button
                                type="reset"
                                secondary
                                onClick={() => onCancel(formik.values)}
                                testId={testIds.cancel}
                            >
                                {t('translation:editableSectionNav.cancel')}
                            </Button>
                            <Button type="submit" onClick={formik.submitForm} testId={testIds.submit}>
                                {t('change-address.continue')}
                            </Button>
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
