import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NotificationStatus, ValidatedCheckbox, Notification, ValidatedInput } from '@cp-shared-9/frontend-ui';
import { AuthorityAddress, Contract } from '@cp-de/common';
import { useMyProfile } from '../../../../my-profile/overview';
import { LicensingAuthorities } from './licensing-authorities';
import {
    RequestRegistrationCertificateCategory,
    RequestRegistrationCertificateFormFields,
} from './RequestRegistrationCertificateFormFields';
import { RequestDocumentFormFields } from '../utils/RequestDocumentFormFields';
import { ShippingReason } from '../utils/categoryFields';
import { myRequestPagePath } from '../../../../navigation/paths';

export const testIds = {
    cancelRequestCertificate: 'cancelRequestCertificate',
    submitRequestCertificate: 'submitRequestCertificate',
    feeAccepted: 'feeAccepted',
    searchWithCustomZipcode: 'searchWithCustomZipcode',
    customZipCode: 'customZipCode',
};

type RequestRegistrationCertificateProps = {
    onCancel: () => void;
    onSubmit: (response: RequestDocumentFormFields) => void;
    category: RequestRegistrationCertificateCategory;
    shippingReason: ShippingReason | '';
    contract: Contract;
};

export const RequestRegistrationCertificate: React.FC<RequestRegistrationCertificateProps> = ({
    onCancel,
    onSubmit,
    category,
    shippingReason,
    contract,
}) => {
    const { data: myProfileData, isLoading, loadingError } = useMyProfile();
    const { t } = useTranslation('my-profile');
    const { t: tRequestDocument } = useTranslation('request-document');
    const [selected, setSelected] = useState<{ legalAuthority?: AuthorityAddress }>({});
    const [showWarning, setShowWarning] = useState(false);
    const [zipCodeSelected, setZipCodeSelected] = useState(false);
    const [refreshKey, setRefreshKey] = useState(Math.random());

    const validationSchema = Yup.object().shape({
        feeAccepted: Yup.bool().oneOf([true], t('request-registration-certificate.fee-accepted.error-message')),
        customZipCode: Yup.string()
            .matches(/\b\d{5}\b/, t('request-registration-certificate.zip-code.error-message'))
            .notRequired(),
    });

    const handleSubmit = (values: RequestRegistrationCertificateFormFields): void => {
        let category = values.category;
        if (values.category === 'RequestForZBT2Finance' && values.allContracts) {
            category = 'RequestForZBT2FinanceAllContracts';
        } else if (values.category === 'RequestForZBT2Leasing' && values.allContracts) {
            category = 'RequestForZBT2LeasingAllContracts';
        }

        const { name, street, houseNumber, zipCode, locality } = selected.legalAuthority || {};
        const response: RequestDocumentFormFields = {
            category,
            name: name || '',
            streetName: street || '',
            houseNumber: houseNumber || '',
            zipCode: zipCode || '',
            locality: locality || '',
            shippingReason: shippingReason || '',
        };
        onSubmit(response);
    };

    const onSelect = useMemo(
        () => (legalAuthority?: AuthorityAddress, allowUndefined?: boolean): void => {
            setShowWarning(false);
            (allowUndefined || legalAuthority) && setSelected({ legalAuthority });
        },
        [],
    );

    return (
        <>
            {shippingReason && (
                <Formik
                    initialValues={{ feeAccepted: false, allContracts: false, category, customZipCode: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <>
                            <LicensingAuthorities
                                key={refreshKey}
                                onSelect={onSelect}
                                myProfileData={myProfileData}
                                isLoading={isLoading}
                                loadingError={!!loadingError}
                                showRoadTrafficOfficesInfo={true}
                            />
                            <div className="u-mt">
                                <b>{t('request-registration-certificate.custom-zip.info-bold')}</b>
                            </div>
                            <div className="u-mt">{t('request-registration-certificate.custom-zip.info')} </div>
                            <ValidatedInput
                                className="u-mv"
                                inputMode={'text'}
                                name={'customZipCode'}
                                onFocus={(): void => {
                                    formik.setFieldValue('feeAccepted', false);
                                    setShowWarning(false);
                                    setRefreshKey(Math.random());
                                    setZipCodeSelected(false);
                                }}
                                label={''}
                                placeholder="Postleitzahl"
                                testId={testIds.customZipCode}
                            />
                            {zipCodeSelected && showWarning ? (
                                <Notification status={NotificationStatus.warning} className={'c-notification--context'}>
                                    <p>
                                        {t('licensing-authorities.redirection-warning.text')}
                                        <b>
                                            <Link
                                                to={{
                                                    pathname: myRequestPagePath(),
                                                    search: `?id=${contract.contractNumber}&type=${contract.contractType}`,
                                                }}
                                            >
                                                {t('licensing-authorities.redirection-warning.link.text') as string}
                                            </Link>
                                        </b>
                                    </p>
                                </Notification>
                            ) : (
                                zipCodeSelected && (
                                    <LicensingAuthorities
                                        onSelect={onSelect}
                                        myProfileData={myProfileData}
                                        isLoading={isLoading}
                                        loadingError={!!loadingError}
                                        customZipCode={formik.values.customZipCode}
                                        setShowWarning={setShowWarning}
                                    />
                                )
                            )}
                            <ButtonContainer nav className="u-mt">
                                <Button
                                    disabled={!!formik.errors.customZipCode}
                                    onClick={(): void => {
                                        setZipCodeSelected(true);
                                    }}
                                    testId={testIds.searchWithCustomZipcode}
                                >
                                    {t('request-registration-certificate.custom-zip.submit')}
                                </Button>
                            </ButtonContainer>
                            {selected.legalAuthority && (
                                <Fieldset className={'u-mt'}>
                                    <Fieldset.Row>
                                        <ValidatedCheckbox
                                            name={'feeAccepted'}
                                            onFocus={() => formik.setFieldValue('customZipCode', '')}
                                            label={t('request-registration-certificate.fee-accepted.label')}
                                            testId={testIds.feeAccepted}
                                        />
                                    </Fieldset.Row>
                                </Fieldset>
                            )}
                            <ButtonContainer nav className="u-mt">
                                <Button secondary onClick={onCancel} testId={testIds.cancelRequestCertificate}>
                                    {tRequestDocument('cancel')}
                                </Button>
                                <Button
                                    disabled={!selected.legalAuthority}
                                    onClick={(): void => {
                                        formik.handleSubmit();
                                    }}
                                    testId={testIds.submitRequestCertificate}
                                >
                                    {t('request-registration-certificate.submit')}
                                </Button>
                            </ButtonContainer>
                        </>
                    )}
                </Formik>
            )}
        </>
    );
};
