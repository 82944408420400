import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';

import { useConnectionProblemError } from './useConnectionProblemError';
import { HeroImage, openInSameWindow, useAnalyticsErrorPageTracker } from '@cp-shared-9/frontend-ui';
import { landingPageUrl } from '../../../config';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const ConnectionProblemError: React.FC = () => {
    const { cmsContent: connectionProblemError, isLoading, loadingError } = useConnectionProblemError();

    useAnalyticsErrorPageTracker('connectionProblemError', !!connectionProblemError);
    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={connectionProblemError?.pretitle}
            title={connectionProblemError?.title || ''}
            subTitle={connectionProblemError?.subTitle}
            buttonText={connectionProblemError?.buttonText}
            clickHandler={openInSameWindow(landingPageUrl)}
            inverted
        />
    );
};
