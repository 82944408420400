import React from 'react';

import { LegalTerms } from '@cp-de/common';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroImage } from '@cp-shared-9/frontend-ui';

export const LegalTermsUi: React.FC<{ legalTerms?: LegalTerms }> = ({ legalTerms }) => {
    if (!legalTerms) {
        return null;
    }

    const { teaser, text } = legalTerms;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={true} />
    ) : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium" className={'u-mt'}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </ContentSection>
        </>
    );
};
