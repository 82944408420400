import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type ProfileSectionEditButtonProps = {
    label: string;
    onClick: () => void;
    testId?: string;
};

type ProfileSectionProps = {
    items: DefinitionListItem[];
    headline: string;
    successMessage?: string;
    successMessageTitle?: string;
    editButtonProps?: ProfileSectionEditButtonProps;
};

export const ProfileSection: React.FC<ProfileSectionProps> = ({
    items,
    headline,
    editButtonProps,
    successMessage,
    successMessageTitle,
}) => {
    return (
        <DataOverview
            title={headline}
            buttonLabel={editButtonProps?.label}
            buttonProps={editButtonProps && { onClick: editButtonProps.onClick, testId: editButtonProps.testId }}
        >
            <DefinitionListHorizontal list={items.filter(item => item.value)} />
            {successMessage && (
                <Notification
                    className={'u-mt'}
                    testId={'success-notification'}
                    status={NotificationStatus.success}
                    text={successMessage}
                    headline={successMessageTitle}
                />
            )}
        </DataOverview>
    );
};
