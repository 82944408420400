import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CombinedContractType } from 'utils';

export type UnpaidAlertNotificationProps = {
    contract: CombinedContractType;
    testId?: string;
};

export const UnpaidAlertNotification: React.FC<UnpaidAlertNotificationProps> = ({ contract, testId }) => {
    const { t } = useTranslation('contracts');

    const handleButtonClick = () => {
        window.open('https://www.vwfs.de/kontakt/kontaktformular-zahlungsvereinbarung.html', '_blank');
    };

    let unpaidsNotification = null;
    if (contract.outstandingPayment) {
        const notificationContent = (
            <>
                <span>{t('unpaid-alert.text')}</span>
                <br /> <br />
                <span>
                    <strong>{t('unpaid-alert.bold-text')}</strong>
                </span>
                <br />
                <Button
                    secondary
                    className="u-bg-white u-mt"
                    onClick={handleButtonClick}
                    testId={'unpaid-alert-button'}
                >
                    {t('unpaid-alert.button-text')}
                </Button>
            </>
        );
        unpaidsNotification = (
            <Notification
                className={'u-mb c-notification--context'}
                status={NotificationStatus.warning}
                headline={t('unpaid-alert.headline')}
                testId={testId || 'unpaid-alert'}
            >
                {notificationContent}
            </Notification>
        );
    }
    return unpaidsNotification;
};
