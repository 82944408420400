import { Base64File } from '@cp-shared-9/apis';

export interface Identity {
    fullName: string;
    firstName?: string;
    lastName?: string;
}

export interface IdentityChange {
    lastName: string;
    birthName: string;
    frontFile: Base64File;
    backFile: Base64File;
}

export enum IdentityChangeError {
    WRONG_OR_INVALID_DOCUMENT = 'WRONG_OR_INVALID_DOCUMENT',
    VIRUS_SCAN_ERROR = 'VIRUS_SCAN_ERROR',
}

export interface Address {
    street?: string;
    locality?: string;
    zipCode?: string;
}

export interface AddressChange {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
}

export interface AddressChangeResponse {
    // False if address change could not be performed because address could not be found but there a suggestions available
    addressChanged: boolean;
    addressSuggestions?: AddressChange[];
}

export type AddressChangeError = 'ADDRESS_NOT_FOUND';

export interface ContactDetails {
    email?: string;
    mobile?: Phone;
}

export type Phone = {
    countryCode?: string;
    number?: string;
    formattedNumber?: string;
};

export type ChangePermission = 'ALLOWED' | 'NOT_ALLOWED' | 'DIRECT_BANK_CUSTOMER';
export interface Profile {
    identity?: Identity;
    address?: Address;
    contactDetails?: ContactDetails;
    addressChangePermission?: ChangePermission;
    nameChangePermission?: ChangePermission;
}

export interface NameAndMail {
    email?: string;
    firstName?: string;
    lastName?: string;
}

export type CountryCode = {
    name: string;
    dialCode: string;
    code: string;
};

export const countryCodeMappings = {
    AF: 'Afghanistan',
    EG: 'Ägypten',
    AX: 'Åland',
    AL: 'Albanien',
    DZ: 'Algerien',
    AS: 'Amerikanisch-Samoa',
    VI: 'Amerikanische Jungferninseln',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktika',
    AG: 'Antigua und Barbuda',
    GQ: 'Äquatorialguinea',
    AR: 'Argentinien',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbaidschan',
    ET: 'Äthiopien',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesch',
    BB: 'Barbados',
    BY: 'Weißrussland',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivien',
    BQ: 'Bonaire Sint Eustatius und Saba',
    BA: 'Bosnien und Herzegowina',
    BW: 'Botswana',
    BV: 'Bouvetinsel',
    BR: 'Brasilien',
    VG: 'Britische Jungferninseln',
    IO: 'Britisches Territorium im Indischen Ozean',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CL: 'Chile',
    CN: 'China Volksrepublik',
    CK: 'Cookinseln',
    CR: 'Costa Rica',
    CI: 'Elfenbeinküste',
    CW: 'Curaçao',
    DK: 'Dänemark',
    DE: 'Deutschland',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DJ: 'Dschibuti',
    EC: 'Ecuador',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estland',
    FK: 'Falklandinseln',
    FO: 'Färöer',
    FJ: 'Fidschi',
    FI: 'Finnland',
    FR: 'Frankreich',
    GF: 'Französisch-Guayana',
    PF: 'Französisch-Polynesien',
    TF: 'Französische Süd- und Antarktisgebiete',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GR: 'Griechenland',
    GL: 'Grönland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey (Kanalinsel)',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard und McDonaldinseln',
    HN: 'Honduras',
    HK: 'Hongkong',
    IN: 'Indien',
    ID: 'Indonesien',
    IM: 'Insel Man',
    IQ: 'Irak',
    IR: 'Iran Islamische Republik',
    IE: 'Irland',
    IS: 'Island',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaika',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey (Kanalinsel)',
    JO: 'Jordanien',
    KY: 'Kaimaninseln',
    KH: 'Kambodscha',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Kap Verde',
    KZ: 'Kasachstan',
    QA: 'Katar',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    CC: 'Kokosinseln',
    CO: 'Kolumbien',
    KM: 'Komoren',
    CD: 'Kongo Demokratische Republik',
    CG: 'Kongo Republik',
    KP: 'Korea Demokratische Volksrepublik',
    KR: 'Korea Republik',
    XK: 'Kosovo',
    HR: 'Kroatien',
    CU: 'Kuba',
    KW: 'Kuwait',
    LA: 'Laos Demokratische Volksrepublik',
    LS: 'Lesotho',
    LV: 'Lettland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MO: 'Macau',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Malediven',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshallinseln',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MK: 'Mazedonien',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MD: 'Moldawien',
    MC: 'Monaco',
    MN: 'Mongolei',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NC: 'Neukaledonien',
    NZ: 'Neuseeland',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    AN: 'Niederländische Antillen',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    MP: 'Nördliche Marianen',
    NF: 'Norfolkinsel',
    NO: 'Norwegen',
    OM: 'Oman',
    AT: 'Österreich',
    TL: 'Osttimor',
    PK: 'Pakistan',
    PS: 'Palastina!Staat Palästina',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua-Neuguinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippinen',
    PN: 'Pitcairninseln',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    TW: 'Republik China (Taiwan)',
    RE: 'Réunion',
    RW: 'Ruanda',
    RO: 'Rumänien',
    RU: 'Russische Föderation',
    BL: 'Saint-Barthélemy',
    MF: 'Saint-Martin',
    SB: 'Salomonen',
    ZM: 'Sambia',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé und Príncipe',
    SA: 'Saudi-Arabien',
    SE: 'Schweden',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    ZW: 'Simbabwe',
    SG: 'Singapur',
    SX: 'Sint Maarten',
    SK: 'Slowakei',
    SI: 'Slowenien',
    SO: 'Somalia',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    SH: 'St. Helena',
    KN: 'St. Kitts und Nevis',
    LC: 'St. Lucia',
    PM: 'St. Pierre und Miquelon!Saint-Pierre und Miquelon',
    VC: 'St. Vincent und die Grenadinen',
    ZA: 'Südafrika',
    SD: 'Sudan',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    SS: 'Südsudan',
    SR: 'Suriname',
    SJ: 'Svalbard und Jan Mayen',
    SZ: 'Swasiland',
    SY: 'Syrien Arabische Republik',
    TJ: 'Tadschikistan',
    TZ: 'Tansania Vereinigte Republik',
    TH: 'Thailand',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad und Tobago',
    TD: 'Tschad',
    CZ: 'Tschechien',
    TN: 'Tunesien',
    TR: 'Türkei',
    TM: 'Turkmenistan',
    TC: 'Turks- und Caicosinseln',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstadt',
    VE: 'Venezuela',
    AE: 'Vereinigte Arabische Emirate',
    US: 'Vereinigte Staaten von Amerika',
    GB: 'Vereinigtes Königreich Großbritannien und Nordirland',
    VN: 'Vietnam',
    WF: 'Wallis und Futuna',
    CX: 'Weihnachtsinsel',
    EH: 'Westsahara',
    CF: 'Zentralafrikanische Republik',
    CY: 'Zypern',
};

export const countryCodes = [
    { name: 'Afghanistan', dialCode: '93', code: 'AF' },
    { name: 'Åland', dialCode: '358', code: 'AX' },
    { name: 'Albanien', dialCode: '355', code: 'AL' },
    { name: 'Algerien', dialCode: '213', code: 'DZ' },
    { name: 'Amerikanisch-Samoa', dialCode: '1684', code: 'AS' },
    { name: 'Andorra', dialCode: '376', code: 'AD' },
    { name: 'Angola', dialCode: '244', code: 'AO' },
    { name: 'Anguilla', dialCode: '1264', code: 'AI' },
    { name: 'Antarktika', dialCode: '672', code: 'AQ' },
    { name: 'Antigua und Barbuda', dialCode: '1268', code: 'AG' },
    { name: 'Argentinien', dialCode: '54', code: 'AR' },
    { name: 'Armenien', dialCode: '374', code: 'AM' },
    { name: 'Aruba', dialCode: '297', code: 'AW' },
    { name: 'Australien', dialCode: '61', code: 'AU' },
    { name: 'Österreich', dialCode: '43', code: 'AT' },
    { name: 'Aserbaidschan', dialCode: '994', code: 'AZ' },
    { name: 'Bahamas', dialCode: '1242', code: 'BS' },
    { name: 'Bahrain', dialCode: '973', code: 'BH' },
    { name: 'Bangladesch', dialCode: '880', code: 'BD' },
    { name: 'Barbados', dialCode: '1246', code: 'BB' },
    { name: 'Weißrussland', dialCode: '375', code: 'BY' },
    { name: 'Belgien', dialCode: '32', code: 'BE' },
    { name: 'Belize', dialCode: '501', code: 'BZ' },
    { name: 'Benin', dialCode: '229', code: 'BJ' },
    { name: 'Bermuda', dialCode: '1441', code: 'BM' },
    { name: 'Bhutan', dialCode: '975', code: 'BT' },
    { name: 'Bolivien', dialCode: '591', code: 'BO' },
    { name: 'Bosnien und Herzegowina', dialCode: '387', code: 'BA' },
    { name: 'Botswana', dialCode: '267', code: 'BW' },
    { name: 'Bouvetinsel', dialCode: '47', code: 'BV' },
    { name: 'Brasilien', dialCode: '55', code: 'BR' },
    { name: 'Britisches Territorium im Indischen Ozean', dialCode: '246', code: 'IO' },
    { name: 'Brunei Darussalam', dialCode: '673', code: 'BN' },
    { name: 'Bulgarien', dialCode: '359', code: 'BG' },
    { name: 'Burkina Faso', dialCode: '226', code: 'BF' },
    { name: 'Burundi', dialCode: '257', code: 'BI' },
    { name: 'Kambodscha', dialCode: '855', code: 'KH' },
    { name: 'Kamerun', dialCode: '237', code: 'CM' },
    { name: 'Kanada', dialCode: '1', code: 'CA' },
    { name: 'Kap Verde', dialCode: '238', code: 'CV' },
    { name: 'Kaimaninseln', dialCode: '1345', code: 'KY' },
    { name: 'Zentralafrikanische Republik', dialCode: '236', code: 'CF' },
    { name: 'Tschad', dialCode: '235', code: 'TD' },
    { name: 'Chile', dialCode: '56', code: 'CL' },
    { name: 'China Volksrepublik', dialCode: '86', code: 'CN' },
    { name: 'Weihnachtsinsel', dialCode: '61', code: 'CX' },
    { name: 'Kokosinseln', dialCode: '61', code: 'CC' },
    { name: 'Kolumbien', dialCode: '57', code: 'CO' },
    { name: 'Komoren', dialCode: '269', code: 'KM' },
    { name: 'Kongo Republik', dialCode: '242', code: 'CG' },
    { name: 'Kongo Demokratische Republik', dialCode: '243', code: 'CD' },
    { name: 'Cookinseln', dialCode: '682', code: 'CK' },
    { name: 'Costa Rica', dialCode: '506', code: 'CR' },
    { name: 'Elfenbeinküste', dialCode: '225', code: 'CI' },
    { name: 'Kroatien', dialCode: '385', code: 'HR' },
    { name: 'Kuba', dialCode: '53', code: 'CU' },
    { name: 'Zypern', dialCode: '357', code: 'CY' },
    { name: 'Tschechien', dialCode: '420', code: 'CZ' },
    { name: 'Dänemark', dialCode: '45', code: 'DK' },
    { name: 'Dschibuti', dialCode: '253', code: 'DJ' },
    { name: 'Dominica', dialCode: '1767', code: 'DM' },
    { name: 'Dominikanische Republik', dialCode: '1849', code: 'DO' },
    { name: 'Ecuador', dialCode: '593', code: 'EC' },
    { name: 'Ägypten', dialCode: '20', code: 'EG' },
    { name: 'El Salvador', dialCode: '503', code: 'SV' },
    { name: 'Äquatorialguinea', dialCode: '240', code: 'GQ' },
    { name: 'Eritrea', dialCode: '291', code: 'ER' },
    { name: 'Estland', dialCode: '372', code: 'EE' },
    { name: 'Äthiopien', dialCode: '251', code: 'ET' },
    { name: 'Falklandinseln', dialCode: '500', code: 'FK' },
    { name: 'Färöer', dialCode: '298', code: 'FO' },
    { name: 'Fidschi', dialCode: '679', code: 'FJ' },
    { name: 'Finnland', dialCode: '358', code: 'FI' },
    { name: 'Frankreich', dialCode: '33', code: 'FR' },
    { name: 'Französisch-Guayana', dialCode: '594', code: 'GF' },
    { name: 'Französisch-Polynesien', dialCode: '689', code: 'PF' },
    { name: 'Gabun', dialCode: '241', code: 'GA' },
    { name: 'Gambia', dialCode: '220', code: 'GM' },
    { name: 'Georgien', dialCode: '995', code: 'GE' },
    { name: 'Deutschland', dialCode: '49', code: 'DE' },
    { name: 'Ghana', dialCode: '233', code: 'GH' },
    { name: 'Gibraltar', dialCode: '350', code: 'GI' },
    { name: 'Griechenland', dialCode: '30', code: 'GR' },
    { name: 'Grönland', dialCode: '299', code: 'GL' },
    { name: 'Grenada', dialCode: '1473', code: 'GD' },
    { name: 'Guadeloupe', dialCode: '590', code: 'GP' },
    { name: 'Guam', dialCode: '1671', code: 'GU' },
    { name: 'Guatemala', dialCode: '502', code: 'GT' },
    { name: 'Guernsey (Kanalinsel)', dialCode: '44', code: 'GG' },
    { name: 'Guinea', dialCode: '224', code: 'GN' },
    { name: 'Guinea-Bissau', dialCode: '245', code: 'GW' },
    { name: 'Guyana', dialCode: '592', code: 'GY' },
    { name: 'Haiti', dialCode: '509', code: 'HT' },
    { name: 'Heard und McDonaldinseln', dialCode: '672', code: 'HM' },
    { name: 'Vatikanstadt', dialCode: '379', code: 'VA' },
    { name: 'Honduras', dialCode: '504', code: 'HN' },
    { name: 'Hongkong', dialCode: '852', code: 'HK' },
    { name: 'Ungarn', dialCode: '36', code: 'HU' },
    { name: 'Island', dialCode: '354', code: 'IS' },
    { name: 'Indien', dialCode: '91', code: 'IN' },
    { name: 'Indonesien', dialCode: '62', code: 'ID' },
    { name: 'Iran Islamische Republik', dialCode: '98', code: 'IR' },
    { name: 'Irak', dialCode: '964', code: 'IQ' },
    { name: 'Irland', dialCode: '353', code: 'IE' },
    { name: 'Insel Man', dialCode: '44', code: 'IM' },
    { name: 'Israel', dialCode: '972', code: 'IL' },
    { name: 'Italien', dialCode: '39', code: 'IT' },
    { name: 'Jamaika', dialCode: '1876', code: 'JM' },
    { name: 'Japan', dialCode: '81', code: 'JP' },
    { name: 'Jersey (Kanalinsel)', dialCode: '44', code: 'JE' },
    { name: 'Jordanien', dialCode: '962', code: 'JO' },
    { name: 'Kasachstan', dialCode: '77', code: 'KZ' },
    { name: 'Kenia', dialCode: '254', code: 'KE' },
    { name: 'Kiribati', dialCode: '686', code: 'KI' },
    { name: 'Korea Demokratische Volksrepublik', dialCode: '850', code: 'KP' },
    { name: 'Korea Republik', dialCode: '82', code: 'KR' },
    { name: 'Kosovo', dialCode: '383', code: 'XK' },
    { name: 'Kuwait', dialCode: '965', code: 'KW' },
    { name: 'Kirgisistan', dialCode: '996', code: 'KG' },
    { name: 'Laos Demokratische Volksrepublik', dialCode: '856', code: 'LA' },
    { name: 'Lettland', dialCode: '371', code: 'LV' },
    { name: 'Libanon', dialCode: '961', code: 'LB' },
    { name: 'Lesotho', dialCode: '266', code: 'LS' },
    { name: 'Liberia', dialCode: '231', code: 'LR' },
    { name: 'Libyen', dialCode: '218', code: 'LY' },
    { name: 'Liechtenstein', dialCode: '423', code: 'LI' },
    { name: 'Litauen', dialCode: '370', code: 'LT' },
    { name: 'Luxemburg', dialCode: '352', code: 'LU' },
    { name: 'Macau', dialCode: '853', code: 'MO' },
    { name: 'Mazedonien', dialCode: '389', code: 'MK' },
    { name: 'Madagaskar', dialCode: '261', code: 'MG' },
    { name: 'Malawi', dialCode: '265', code: 'MW' },
    { name: 'Malaysia', dialCode: '60', code: 'MY' },
    { name: 'Malediven', dialCode: '960', code: 'MV' },
    { name: 'Mali', dialCode: '223', code: 'ML' },
    { name: 'Malta', dialCode: '356', code: 'MT' },
    { name: 'Marshallinseln', dialCode: '692', code: 'MH' },
    { name: 'Martinique', dialCode: '596', code: 'MQ' },
    { name: 'Mauretanien', dialCode: '222', code: 'MR' },
    { name: 'Mauritius', dialCode: '230', code: 'MU' },
    { name: 'Mayotte', dialCode: '262', code: 'YT' },
    { name: 'Mexiko', dialCode: '52', code: 'MX' },
    { name: 'Mikronesien', dialCode: '691', code: 'FM' },
    { name: 'Moldawien', dialCode: '373', code: 'MD' },
    { name: 'Monaco', dialCode: '377', code: 'MC' },
    { name: 'Mongolei', dialCode: '976', code: 'MN' },
    { name: 'Montenegro', dialCode: '382', code: 'ME' },
    { name: 'Montserrat', dialCode: '1664', code: 'MS' },
    { name: 'Marokko', dialCode: '212', code: 'MA' },
    { name: 'Mosambik', dialCode: '258', code: 'MZ' },
    { name: 'Myanmar', dialCode: '95', code: 'MM' },
    { name: 'Namibia', dialCode: '264', code: 'NA' },
    { name: 'Nauru', dialCode: '674', code: 'NR' },
    { name: 'Nepal', dialCode: '977', code: 'NP' },
    { name: 'Niederlande', dialCode: '31', code: 'NL' },
    { name: 'Niederländische Antillen', dialCode: '599', code: 'AN' },
    { name: 'Neukaledonien', dialCode: '687', code: 'NC' },
    { name: 'Neuseeland', dialCode: '64', code: 'NZ' },
    { name: 'Nicaragua', dialCode: '505', code: 'NI' },
    { name: 'Niger', dialCode: '227', code: 'NE' },
    { name: 'Nigeria', dialCode: '234', code: 'NG' },
    { name: 'Niue', dialCode: '683', code: 'NU' },
    { name: 'Norfolkinsel', dialCode: '672', code: 'NF' },
    { name: 'Nördliche Marianen', dialCode: '1670', code: 'MP' },
    { name: 'Norwegen', dialCode: '47', code: 'NO' },
    { name: 'Oman', dialCode: '968', code: 'OM' },
    { name: 'Pakistan', dialCode: '92', code: 'PK' },
    { name: 'Palau', dialCode: '680', code: 'PW' },
    { name: 'Palastina!Staat Palästina', dialCode: '970', code: 'PS' },
    { name: 'Panama', dialCode: '507', code: 'PA' },
    { name: 'Papua-Neuguinea', dialCode: '675', code: 'PG' },
    { name: 'Paraguay', dialCode: '595', code: 'PY' },
    { name: 'Peru', dialCode: '51', code: 'PE' },
    { name: 'Philippinen', dialCode: '63', code: 'PH' },
    { name: 'Pitcairninseln', dialCode: '64', code: 'PN' },
    { name: 'Polen', dialCode: '48', code: 'PL' },
    { name: 'Portugal', dialCode: '351', code: 'PT' },
    { name: 'Puerto Rico', dialCode: '1939', code: 'PR' },
    { name: 'Katar', dialCode: '974', code: 'QA' },
    { name: 'Rumänien', dialCode: '40', code: 'RO' },
    { name: 'Russische Föderation', dialCode: '7', code: 'RU' },
    { name: 'Ruanda', dialCode: '250', code: 'RW' },
    { name: 'Réunion', dialCode: '262', code: 'RE' },
    { name: 'Saint-Barthélemy', dialCode: '590', code: 'BL' },
    { name: 'St. Helena', dialCode: '290', code: 'SH' },
    { name: 'St. Kitts und Nevis', dialCode: '1869', code: 'KN' },
    { name: 'St. Lucia', dialCode: '1758', code: 'LC' },
    { name: 'Saint-Martin', dialCode: '590', code: 'MF' },
    { name: 'St. Pierre und Miquelon!Saint-Pierre und Miquelon', dialCode: '508', code: 'PM' },
    { name: 'St. Vincent und die Grenadinen', dialCode: '1784', code: 'VC' },
    { name: 'Samoa', dialCode: '685', code: 'WS' },
    { name: 'San Marino', dialCode: '378', code: 'SM' },
    { name: 'São Tomé und Príncipe', dialCode: '239', code: 'ST' },
    { name: 'Saudi-Arabien', dialCode: '966', code: 'SA' },
    { name: 'Senegal', dialCode: '221', code: 'SN' },
    { name: 'Serbien', dialCode: '381', code: 'RS' },
    { name: 'Seychellen', dialCode: '248', code: 'SC' },
    { name: 'Sierra Leone', dialCode: '232', code: 'SL' },
    { name: 'Singapur', dialCode: '65', code: 'SG' },
    { name: 'Slowakei', dialCode: '421', code: 'SK' },
    { name: 'Slowenien', dialCode: '386', code: 'SI' },
    { name: 'Salomonen', dialCode: '677', code: 'SB' },
    { name: 'Somalia', dialCode: '252', code: 'SO' },
    { name: 'Südafrika', dialCode: '27', code: 'ZA' },
    { name: 'Südsudan', dialCode: '211', code: 'SS' },
    { name: 'Südgeorgien und die Südlichen Sandwichinseln', dialCode: '500', code: 'GS' },
    { name: 'Spanien', dialCode: '34', code: 'ES' },
    { name: 'Sri Lanka', dialCode: '94', code: 'LK' },
    { name: 'Sudan', dialCode: '249', code: 'SD' },
    { name: 'Suriname', dialCode: '597', code: 'SR' },
    { name: 'Svalbard und Jan Mayen', dialCode: '47', code: 'SJ' },
    { name: 'Swasiland', dialCode: '268', code: 'SZ' },
    { name: 'Schweden', dialCode: '46', code: 'SE' },
    { name: 'Schweiz', dialCode: '41', code: 'CH' },
    { name: 'Syrien Arabische Republik', dialCode: '963', code: 'SY' },
    { name: 'Republik China (Taiwan)', dialCode: '886', code: 'TW' },
    { name: 'Tadschikistan', dialCode: '992', code: 'TJ' },
    { name: 'Tansania Vereinigte Republik', dialCode: '255', code: 'TZ' },
    { name: 'Thailand', dialCode: '66', code: 'TH' },
    { name: 'Osttimor', dialCode: '670', code: 'TL' },
    { name: 'Togo', dialCode: '228', code: 'TG' },
    { name: 'Tokelau', dialCode: '690', code: 'TK' },
    { name: 'Tonga', dialCode: '676', code: 'TO' },
    { name: 'Trinidad und Tobago', dialCode: '1868', code: 'TT' },
    { name: 'Tunesien', dialCode: '216', code: 'TN' },
    { name: 'Türkei', dialCode: '90', code: 'TR' },
    { name: 'Turkmenistan', dialCode: '993', code: 'TM' },
    { name: 'Turks- und Caicosinseln', dialCode: '1649', code: 'TC' },
    { name: 'Tuvalu', dialCode: '688', code: 'TV' },
    { name: 'Uganda', dialCode: '256', code: 'UG' },
    { name: 'Ukraine', dialCode: '380', code: 'UA' },
    { name: 'Vereinigte Arabische Emirate', dialCode: '971', code: 'AE' },
    { name: 'Vereinigtes Königreich Großbritannien und Nordirland', dialCode: '44', code: 'GB' },
    { name: 'Vereinigte Staaten von Amerika', dialCode: '1', code: 'US' },
    { name: 'Uruguay', dialCode: '598', code: 'UY' },
    { name: 'Usbekistan', dialCode: '998', code: 'UZ' },
    { name: 'Vanuatu', dialCode: '678', code: 'VU' },
    { name: 'Venezuela', dialCode: '58', code: 'VE' },
    { name: 'Vietnam', dialCode: '84', code: 'VN' },
    { name: 'Britische Jungferninseln', dialCode: '1284', code: 'VG' },
    { name: 'Amerikanische Jungferninseln', dialCode: '1340', code: 'VI' },
    { name: 'Wallis und Futuna', dialCode: '681', code: 'WF' },
    { name: 'Jemen', dialCode: '967', code: 'YE' },
    { name: 'Sambia', dialCode: '260', code: 'ZM' },
    { name: 'Simbabwe', dialCode: '263', code: 'ZW' },
];
